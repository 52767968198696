import { isObject, isString } from 'lodash';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useGetPlayerQuery } from '../../api/teams';

import ErrorDisplay from '../../components/ErrorDisplay';

import { ProgressSpinner } from 'primereact/progressspinner';

import { Player } from '../../types/team';
import { ERROR_TYPES } from '../../types/common.d';

interface Props {
    player?: Player | string;
}

const PlayerPane = (props: Props) => {
    const { teamID } = useParams();

    const { data, isLoading, isError, isFetching } = useGetPlayerQuery(
        {
            teamID: teamID,
            playerID: props.player,
        },
        { skip: !props.player || !isString(props.player) || !teamID }
    );

    const player = isObject(props.player) ? props.player : data;

    if (isFetching) {
        return <ProgressSpinner id="isFetching" className="loading-spinner" />;
    }

    if (isLoading) {
        return <ProgressSpinner id="isLoading" className="loading-spinner" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                title="An error has occurred"
                errorType={ERROR_TYPES.somethingsWrong}
            />
        );
    }

    if (!player) {
        return (
            <ErrorDisplay
                title="No data returned"
                errorType={ERROR_TYPES.notFound}
            />
        );
    }

    const paneItem = (title: string, value: string, jsx?: JSX.Element) => {
        return (
            <div key={title} className="member-pane_item">
                <h5 className="member-pane_item-title title">{title}</h5>
                {jsx}
                <h4 className="member-pane_item-value">{value}</h4>
            </div>
        );
    };

    return (
        <div className="member-pane">
            <div className="member-pane_section">
                <h6 className="member-pane_section-title title">Personal</h6>
                <div className="list">
                    {[
                        paneItem(
                            'Player name',
                            player.firstName + ' ' + player.lastName
                        ),
                        paneItem('Display Name', player.displayName),
                        player.dateOfBirth &&
                            paneItem(
                                'Date of Birth',

                                format(
                                    new Date(player.dateOfBirth),
                                    'dd/MM/yyyy'
                                )
                            ),
                        paneItem('Gender', player.gender),
                        paneItem(
                            'Colour',
                            '',
                            <span
                                className="member-colour"
                                style={{ background: player.colour }}
                            />
                        ),
                    ]}
                </div>
            </div>
            <div className="member-pane_section">
                <h6 className="member-pane_section-title title">Team</h6>
                <div className="list">
                    {[
                        paneItem(
                            `Joined ${player.teamName}`,
                            `on ${format(
                                new Date(player.createdAt),
                                'dd/MM/yyyy'
                            )}`
                        ),
                        paneItem('Playing Number', player.uniformNumber),
                        paneItem('Player Status', player.status),
                    ]}
                </div>
            </div>
        </div>
    );
};

export default PlayerPane;
