import { useMemo } from 'react';

import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { TabPanel, TabView } from 'primereact/tabview';
import Modal from '../../components/Modal';

import EventDetails from './EventDetails';
import EventTeams from './EventTeams';
import EventPlayers from './EventPlayers';
import EventForm from './EventForm';

import { ACTION_TYPES, EntityType, SEVERITY } from '../../types/common.d';
import { UseQueryResult } from '../../types/api';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { StatResponse, TimeStatResponse } from '../../types/game';
import { EventResponse } from '../../types/event';
import { PlayerResponse, TeamResponse } from '../../types/team';
import { StatConfigResponse } from '../../types/sports';
import { Avatar } from 'primereact/avatar';
import { Skeleton } from 'primereact/skeleton';
import EntityAvatar from '../../components/EntityAvatar';
import { getFirstChars } from '../../util/helper';

interface Props {
    roles: Roles;
    route: Route;
    eventData: UseQueryResult<EventResponse>;
    timeStatsData: UseQueryResult<TimeStatResponse>;
    gameStatsData: UseQueryResult<StatResponse>;
    playerData: UseQueryResult<PlayerResponse>;
    teamData: UseQueryResult<TeamResponse>;
    statsConfigData: UseQueryResult<StatConfigResponse>;
    onCloseEventDialog: () => void;
    onEditEvent: () => void;
    showEventDialog: boolean;
}

const EDIT_FORM_ID = 'edit-event-form';

const tabSchema = {
    Generic: [
        {
            id: 'details',
            title: 'Details',
            component: EventDetails,
        },
    ],
    Game: [
        {
            id: 'details',
            title: 'Details',
            component: EventDetails,
        },
        {
            id: 'team',
            title: 'Team',
            component: EventTeams,
        },
        {
            id: 'players',
            title: 'Players',
            component: EventPlayers,
        },
    ],
    Testing: [
        {
            id: 'details',
            title: 'Details',
            component: EventDetails,
        },
    ],
    Training: [
        {
            id: 'details',
            title: 'Details',
            component: EventDetails,
        },
    ],
};

const EventsSingleView = (props: Props) => {
    const params = useParams();
    const navigate = useNavigate();

    const { eventData, teamData, timeStatsData } = props;

    const event = eventData?.data?.data;
    const team = teamData?.data?.data;
    const opponentName = event?.game?.opponentTeam?.teamName;
    const gameSummary = timeStatsData?.data?.value['summaryReport'][0];

    const tabs = useMemo(() => {
        return event && event.eventType
            ? tabSchema[event.eventType as keyof typeof tabSchema]
            : [];
    }, [event]);

    const activeTabIndex = tabs.findIndex((tab) => params.tab === tab.id);

    const formFooterActions = [
        {
            form: EDIT_FORM_ID,
            label: 'Updated',
            severity: SEVERITY.primary,
            type: ACTION_TYPES.submit,
        },
    ];

    const renderTeamAvatar = () => {
        return team && !teamData.isLoading ? (
            <EntityAvatar
                entityID={team && team.teamID ? team.teamID : ''}
                entityType={EntityType.teams}
                entityName={team && team.teamName ? team.teamName : ''}
                avatarProps={{ size: 'xlarge' }}
            />
        ) : (
            <Skeleton size="4rem" />
        );
    };

    const renderOppositionAvatar = () => {
        return opponentName ? (
            <Avatar
                label={getFirstChars(opponentName, 2).toUpperCase()}
                size="xlarge"
            />
        ) : (
            <Skeleton size="4rem" />
        );
    };

    const renderPageHeader = () => {
        return (
            <div className="game-header">
                <div className="game-header_team">
                    <div className="game-header_team-details">
                        {eventData.isLoading || !event ? (
                            <Skeleton size="4rem" />
                        ) : event.isHomeTeam ? (
                            renderTeamAvatar()
                        ) : (
                            renderOppositionAvatar()
                        )}

                        <div className="game-header_team-name">
                            {eventData.isLoading || !event ? (
                                <Skeleton width="10rem" />
                            ) : event.isHomeTeam ? (
                                team?.teamName
                            ) : (
                                opponentName
                            )}
                        </div>
                    </div>
                    <div className="game-header_score">
                        {!timeStatsData.isError && (
                            <span>
                                {timeStatsData.isLoading ? (
                                    <Skeleton width="3rem" />
                                ) : (
                                    gameSummary.homeTeamScore
                                )}
                            </span>
                        )}
                    </div>
                </div>
                <div className="game-header_details">
                    {eventData.isLoading || !event ? (
                        <Skeleton width="3rem" />
                    ) : (
                        event.eventName
                    )}
                </div>
                <div className="game-header_team game-header_team--opponent">
                    <div className="game-header_score">
                        {!timeStatsData.isError && (
                            <span>
                                {timeStatsData.isLoading ? (
                                    <Skeleton width="3rem" />
                                ) : (
                                    gameSummary.awayTeamScore
                                )}
                            </span>
                        )}
                    </div>
                    <div className="game-header_team-details">
                        <div className="game-header_team-name">
                            {eventData.isLoading || !event ? (
                                <Skeleton width="10rem" />
                            ) : event.isHomeTeam ? (
                                opponentName
                            ) : (
                                team?.teamName
                            )}
                        </div>
                        {eventData.isLoading || !event ? (
                            <Skeleton size="4rem" />
                        ) : event.isHomeTeam ? (
                            renderOppositionAvatar()
                        ) : (
                            renderTeamAvatar()
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={`page view member-view`}>
            <div className="view-inner">
                {renderPageHeader()}

                {tabs.length > 0 ? (
                    <TabView
                        activeIndex={activeTabIndex}
                        onTabChange={(e) =>
                            navigate(
                                generatePath(props.route.path, {
                                    ...params,
                                    tab: tabs[e.index].id,
                                })
                            )
                        }
                    >
                        {tabs.map(({ id, title, component: TabContent }) => (
                            <TabPanel key={id} header={title}>
                                <TabContent {...props} />
                            </TabPanel>
                        ))}
                    </TabView>
                ) : (
                    <TabView activeIndex={0}>
                        <TabPanel header={<Skeleton width="3rem" />}>
                            <Skeleton width={'12rem'} />
                        </TabPanel>
                    </TabView>
                )}
            </div>
            <Modal
                title="Edit Event"
                actions={formFooterActions}
                content={
                    <EventForm
                        formID={EDIT_FORM_ID}
                        eventID={eventData?.data?.data?.eventID}
                        hasSubmitUI={false}
                        onCancel={props.onCloseEventDialog}
                        onSubmit={() => console.log('SUBMIT')}
                    />
                }
                onHide={props.onCloseEventDialog}
                visible={props.showEventDialog}
                maximizable={false}
            />
        </div>
    );
};
export default EventsSingleView;
