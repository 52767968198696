import { useEffect, useRef } from 'react';
import {
    generatePath,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { isEmpty, startCase, groupBy, sortBy } from 'lodash';

import { entityMap } from '../../routes/routes';

import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';

import ButtonIcon from '../../components/ButtonIcon';
import EntityAvatar from '../../components/EntityAvatar';
import ErrorDisplay from '../../components/ErrorDisplay';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import PageHeader from '../../components/page/PageHeader';

import OrganisationForm from '../organisations/OrganisationForm';
import TeamForm from '../teams/TeamForm';

import { ERROR_TYPES, SEVERITY } from '../../types/common.d';

const EntityListingView = (props) => {
    const { isError, isFetching, isLoading } = props;

    const formResponseToast = useRef();

    const navigate = useNavigate();
    const params = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const openCreationPortal = () => {
        if (!searchParams.get('form')) {
            setSearchParams((params) => {
                params.set('form', 'create');
                return params;
            });
        }
    };
    const closeCreationPortal = () => {
        if (searchParams.get('form')) {
            setSearchParams((params) => {
                params.delete('form');
                return params;
            });
        }
    };

    const { userID } = params;
    const entityType = params['*'];
    const parsedEntityType = startCase(entityType).slice(0, -1);

    const groupedData = groupBy(props.data, 'entityType');

    useEffect(() => {
        if (!entityType) {
            if (!isEmpty(groupedData)) {
                navigate(
                    generatePath('/u/:userID/entities/:entityType', {
                        userID,
                        entityType: Object.keys(groupedData)[0],
                    }),
                    { replace: true }
                );
            } else {
                navigate(
                    generatePath('/u/:userID/entities/teams', {
                        userID,
                    }),
                    { replace: true }
                );
            }
        }
    }, [userID, navigate, groupedData, entityType]);

    if (props.loading) {
        return <Loader size="fullscreen" />;
    }

    if (props.error) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        command: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                        severity: SEVERITY.primary,
                    },
                ]}
                desc={`We were unable to load your ${startCase(
                    entityType
                )}, try again or contact your account manager.`}
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Roles Returned"
            />
        );
    }

    // empty array to populate rows for skeleton loading components
    const blankRows = Array.from({ length: 5 });

    const nameTemplate = (entityType, data) => {
        return (
            <div className="list-item link-item detail-cell">
                <EntityAvatar
                    entityID={data.entityID}
                    entityName={data.entityPreview.entityName}
                    entityType={entityType}
                />
                <span>{data.entityPreview.entityName}</span>
            </div>
        );
    };

    const allColumns = {
        associations: [
            {
                field: 'entityPreview.entityName',
                header: 'Name',
                sortable: true,
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (data, row) => nameTemplate('associations', data, row)
                ),
            },
        ],
        organisations: [
            {
                field: 'entityPreview.entityName',
                header: 'Name',
                sortable: true,
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (data, row) => nameTemplate('organisations', data, row)
                ),
            },
        ],
        teams: [
            {
                field: 'entityPreview.entityName',
                header: 'Name',
                sortable: true,
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (data, row) => nameTemplate('teams', data, row)
                ),
            },
        ],
    };

    if (
        !groupedData[entityType] &&
        !['organisations', 'teams'].includes(entityType)
    ) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        command: () => navigate(`/u/${userID}/entities/teams`),
                        label: 'Go To Teams',
                        severity: SEVERITY.primary,
                    },
                ]}
                desc={`We are unable to find any entities matching the type "${entityType}".`}
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Entities Found"
            />
        );
    }

    const data = groupedData[entityType];
    const columns = allColumns[entityType];

    const handleSubmitResult = (submitResult) => {
        const hasSubmitted = submitResult === 'success';
        const hasUpdated = submitResult === 'updated';

        formResponseToast.current?.show({
            severity: hasSubmitted || hasUpdated ? 'success' : 'warn',
            summary: hasUpdated
                ? `${parsedEntityType} Updated`
                : hasSubmitted
                ? `${parsedEntityType} Added`
                : `${parsedEntityType} Addition Unsuccessful`,
            detail: hasUpdated
                ? `The ${entityType} details have been amended.`
                : hasSubmitted
                ? `The new ${parsedEntityType} has been successfully added.`
                : `${parsedEntityType} creation could not be completed, try again later.`,
        });
    };

    const createAction = [
        {
            command: () => navigate(`/u/${userID}/${entityType}/import`),
            icon: 'upload',
            key: 'import',
            label: `Import ${entityType}`,
            severity: SEVERITY.secondary,
        },
        {
            command: () => openCreationPortal(),
            icon: 'add_circle',
            key: 'create',
            label: `Create ${parsedEntityType}`,
            severity: SEVERITY.primary,
        },
    ];

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? {
                          command: () => navigate(0), // refresh
                          icon: 'refresh',
                          label: 'Retry',
                          severity: SEVERITY.primary,
                      }
                    : createAction
            }
            alignment="middle"
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : `To get started create your first ${parsedEntityType}`
            }
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={
                isError
                    ? 'No data returned'
                    : `No ${startCase(entityType)} found`
            }
        />
    );

    const footerTemplate = () => {
        return (
            <Button
                onClick={props.onLoadMore}
                className={`p-button-${SEVERITY.secondary} p-button-sm`}
                label="Load more"
            >
                <ButtonIcon iconName="pending" placement="right" />
            </Button>
        );
    };

    return (
        <div className={`page view`}>
            <div className="view-inner">
                <PageHeader
                    showBreadcrumbs={false}
                    title={startCase(entityType)}
                    actions={createAction}
                />
                <DataTable
                    selectionMode="single"
                    onRowClick={(row) => {
                        const urlSegment = entityMap[entityType].urlSegment;
                        navigate(`/${urlSegment}/${row.data.entityID}`);
                    }}
                    emptyMessage={tableEmptyMessage}
                    footer={
                        !isFetching && props.showPagination
                            ? footerTemplate
                            : null
                    }
                    loading={isFetching && !isLoading}
                    responsiveLayout="scroll"
                    value={
                        isLoading
                            ? blankRows
                            : sortBy(
                                  data,
                                  (ent) => ent.entityPreview.entityName
                              )
                    }
                >
                    {props.data.length > 0 &&
                        columns.map((col, i) => (
                            <Column key={`col-${i}`} {...col} />
                        ))}
                </DataTable>
            </div>

            {searchParams.get('form') && (
                <Modal
                    content={
                        entityType === 'organisations' ? (
                            <OrganisationForm
                                onSubmit={handleSubmitResult}
                                onCancel={closeCreationPortal}
                            />
                        ) : (
                            <TeamForm
                                onSubmit={handleSubmitResult}
                                onCancel={closeCreationPortal}
                            />
                        )
                    }
                    hasFooter={false}
                    maximizable={false}
                    onHide={closeCreationPortal}
                    title={`Create ${entityType.slice(0, -1)}`}
                    visible={true}
                />
            )}
            <Toast ref={formResponseToast} />
        </div>
    );
};

export default EntityListingView;
