import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    ASSOCIATION_ROUTE,
    ORGANISATION_ROUTE,
    TEAM_ROUTE,
    entityMap,
} from './routes';

import ErrorDisplay from '../components/ErrorDisplay';

import { ERROR_TYPES } from '../types/common.d';
import { Roles } from '../types/roles';
import { Route } from '../types/route';

interface Props {
    children: React.ReactNode;
    roles: Roles;
    route: Route;
}

const ProtectedRoute = (props: Props) => {
    const { children, roles } = props;

    const navigate = useNavigate();
    const resetRoute = () => navigate(0);
    const location = useLocation();
    const pathSplit = location.pathname.split('/');
    const entityRoute = pathSplit[1];
    const entityID = pathSplit[2];
    const requiresRole = [
        ASSOCIATION_ROUTE,
        ORGANISATION_ROUTE,
        TEAM_ROUTE,
    ].includes(entityRoute);

    // @todo check against users permissions
    let hasPermission = true;
    let hasRole = false;
    let isOrgGod = true; // @todo - actually loop through // roles.forEach((staffRole) => staffRole.entityType === 'organisations' && !!staffRole.permissions.find( permission => permission === "MANAGE_ORGANISATIONS_*" || permission === "MANAGE_ORGANISATIONS_TEAMS_*")

    Object.keys(entityMap).every((key) => {
        const entity = entityMap[key];
        if (entityRoute === entity.urlSegment && entityID) {
            hasRole = !!roles.find((ent) => ent.entityID === entityID);
            return false;
        }
        return true;
    });

    if (requiresRole && !hasRole && !isOrgGod) {
        return (
            <ErrorDisplay
                errorType={ERROR_TYPES.pageNotFound}
                title="You don't have a role within this entity"
                desc="Contact the entity admin if you believe this is in error."
                proportion="enlarged"
                handleReset={() => resetRoute()}
            />
        );
    }

    if (!hasPermission) {
        return (
            <ErrorDisplay
                errorType={ERROR_TYPES.somethingsWrong}
                title="You lack permissions to see this page"
                desc="Contact the page admin if you believe this is in error."
                proportion="enlarged"
                handleReset={() => resetRoute()}
            />
        );
    }

    return children;
};

export default ProtectedRoute;
