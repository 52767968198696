import { useParams } from 'react-router-dom';
import { useGetRolesQuery } from '../../api/roles';
import EntityListingView from './EntityListingView';
import DocumentHead from '../../components/DocumentHead';

const EntityListingContainer = (props) => {
    const { userID } = useParams();

    const { data, isLoading, isError, isFetching } = useGetRolesQuery(
        { userID },
        { skip: !userID }
    );

    return (
        <>
            <DocumentHead
                title="Entities - Rookie Me Hub"
                description="Entity listing page"
            />
            <EntityListingView
                data={data ? data.data : {}}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                {...props}
            />
        </>
    );
};

export default EntityListingContainer;
