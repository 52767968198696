import { useGetUserEventQuery } from '../../api/events';
import { format, isSameDay } from 'date-fns';

import ButtonIcon from '../../components/ButtonIcon';
import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import ListItem from '../../components/ListItem';

import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import { ERROR_TYPES } from '../../types/common.d';
import { EventType } from '../../types/event';
import { useParams } from 'react-router-dom';

interface Props {
    eventID: string;
    onDelete: () => void;
    onClose: () => void;
    onEdit: () => void;
}

const CalendarPane = (props: Props) => {
    const { eventID } = props;
    const { userID } = useParams();

    const { data, isError, isFetching, isLoading } = useGetUserEventQuery(
        // @ts-expect-error
        { eventID, userID },
        {
            skip: !eventID || !userID,
        }
    );

    if (isFetching) {
        return <ProgressSpinner id="isFetching" className="loading-spinner" />;
    }

    if (isLoading) {
        return <ProgressSpinner id="isLoading" className="loading-spinner" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                title="An error has occurred"
                desc="Reload the page to try again."
                errorType={ERROR_TYPES.somethingsWrong}
            />
        );
    }

    if (!data) {
        return (
            <ErrorDisplay
                title="No data was returned"
                desc="We couldn't fulfil the request for you, try again or contact account manager."
                errorType={ERROR_TYPES.notFound}
            />
        );
    }

    const {
        arrivalDateTime,
        createdAt,
        eventName,
        eventType,
        eventVisibility,
        lastEdited,
        startDateTime,
        endDateTime,
        description,
        locationInfo,
        city,
        state,
    } = data.data;

    const eventPaneActions = [
        {
            icon: 'delete',
            command: () => props.onDelete,
        },
        {
            icon: 'edit',
            command: () => props.onEdit,
        },
        {
            icon: 'close',
            command: () => props.onClose,
        },
    ];

    /**
     * @desc map over actions to be passed into eventPane toolbar
     * @returns actions[]
     */
    const renderActions = eventPaneActions?.map((action, i) => {
        return (
            <Button
                className="p-button-icon-only p-button-secondary p-button-text toolbar-action"
                onClick={action.command && action.command()}
                type="button"
            >
                <ButtonIcon
                    iconName={action.icon ? action.icon : ''}
                    size="small"
                />
            </Button>
        );
    });

    /**
     * @desc event date-time helper checks
     */
    const checkSameDay =
        endDateTime &&
        isSameDay(new Date(startDateTime), new Date(endDateTime));
    const dayCount = checkSameDay ? 'Single-day' : 'Multi-day';

    const renderEventIcon = (type: EventType) => {
        switch (type) {
            case 'Game':
                return 'stadium';
            default:
                return 'event_available';
        }
    };

    const eventAvatarTemplate = (icon?: string) => (
        <Icon name={icon ? icon : renderEventIcon(eventType)} />
    );

    return (
        <div className={`event-pane ${eventType.toLowerCase()}-event`}>
            <Toolbar
                left={
                    <ListItem
                        title={eventName}
                        caption={dayCount}
                        proportion="compact"
                        avatar={{ template: eventAvatarTemplate() }}
                    />
                }
                right={renderActions}
            />
            <div className="event-pane_content">
                <div className="event-info">
                    <h5 className="title is-small is-all-caps event-info_title">
                        Details
                    </h5>
                    <div className="event-info_container bordered-container">
                        {checkSameDay && (
                            <>
                                <ListItem
                                    avatar={{
                                        template:
                                            eventAvatarTemplate(
                                                'calendar_month'
                                            ),
                                    }}
                                    caption="Date"
                                    className="is-reversed"
                                    proportion="compact"
                                    title={format(
                                        new Date(startDateTime),
                                        'd MMMM, yyyy'
                                    )}
                                />
                                <ListItem
                                    avatar={{
                                        template: eventAvatarTemplate('alarm'),
                                    }}
                                    caption="Time"
                                    className="is-reversed"
                                    proportion="compact"
                                    title={`${format(
                                        new Date(startDateTime),
                                        'h:mmaaa'
                                    )}${
                                        endDateTime
                                            ? ' - ' +
                                              format(
                                                  new Date(endDateTime),
                                                  'h:mmaaa'
                                              )
                                            : ''
                                    }
                                    `}
                                />
                            </>
                        )}
                        {!checkSameDay && (
                            <>
                                <ListItem
                                    avatar={{
                                        template: eventAvatarTemplate('today'),
                                    }}
                                    caption="Event Start"
                                    className="is-reversed"
                                    proportion="compact"
                                    title={format(
                                        new Date(startDateTime),
                                        'd MMMM, yyyy, h:mmaaa'
                                    )}
                                />
                                {endDateTime && (
                                    <ListItem
                                        avatar={{
                                            template:
                                                eventAvatarTemplate('event'),
                                        }}
                                        caption="Event End"
                                        className="is-reversed"
                                        proportion="compact"
                                        title={format(
                                            new Date(endDateTime),
                                            'd MMMM, yyyy, h:mmaaa'
                                        )}
                                    />
                                )}
                            </>
                        )}
                        {arrivalDateTime && (
                            <ListItem
                                avatar={{
                                    template:
                                        eventAvatarTemplate(
                                            'person_pin_circle'
                                        ),
                                }}
                                caption="Arrival Time"
                                className="is-reversed"
                                proportion="compact"
                                title={format(
                                    new Date(arrivalDateTime),
                                    'h:mmaaa'
                                )}
                            />
                        )}
                    </div>
                    <h5 className="title is-small is-all-caps event-info_title">
                        Location
                    </h5>
                    <div className="event-info_container bordered-container">
                        {locationInfo && (
                            <ListItem
                                avatar={{
                                    template:
                                        eventAvatarTemplate('location_on'),
                                }}
                                caption={`${city}, ${state}`}
                                className="is-reversed"
                                proportion="compact"
                                title={locationInfo.formattedAddress}
                            />
                        )}
                    </div>
                    {description && (
                        <>
                            <h5 className="title is-small is-all-caps event-info_title">
                                Description
                            </h5>
                            <div className="event-info_container bordered-container">
                                <small>{description}</small>
                            </div>
                        </>
                    )}
                    {/* TODO: clean up conditional check to reference values actually within */}
                    <h5 className="title is-small is-all-caps event-info_title">
                        Manage
                    </h5>
                    <div className="p-datatable p-component p-datatable-sm">
                        <div className="p-datatable-wrapper">
                            <table className="p-datatable-table" role="table">
                                <tbody className="p-datatable-tbody">
                                    {createdAt && (
                                        <tr role="row">
                                            <td
                                                className="title-cell"
                                                role="cell"
                                            >
                                                Date Created
                                            </td>
                                            <td
                                                className="value-cell"
                                                role="cell"
                                            >
                                                {format(
                                                    new Date(createdAt),
                                                    'd MMMM, h:mmaaa'
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    {lastEdited && (
                                        <tr role="row">
                                            <td
                                                className="title-cell"
                                                role="cell"
                                            >
                                                Last Edited
                                            </td>
                                            <td
                                                className="value-cell"
                                                role="cell"
                                            >
                                                {format(
                                                    new Date(lastEdited),
                                                    'd MMMM, h:mmaaa'
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    {eventVisibility && (
                                        <tr role="row">
                                            <td
                                                className="title-cell"
                                                role="cell"
                                            >
                                                User Visibility
                                            </td>
                                            <td
                                                className="value-cell"
                                                role="cell"
                                            >
                                                {eventVisibility}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Tooltip
                target=".event-pane > .p-toolbar .list-item_content-title"
                content={eventName}
                position="bottom"
                showDelay={150}
                mouseTrack
            />
        </div>
    );
};

export default CalendarPane;
