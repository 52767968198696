import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { uniqueId } from 'lodash';

import Icon from './Icon';
import ButtonIcon from './ButtonIcon';

import { Dialog, DialogProps } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { TabMenu } from 'primereact/tabmenu';
import { MenuItem } from 'primereact/menuitem';

import { Action } from '../types/common.d';

type TabItems = {
    content: JSX.Element;
    icon?: JSX.Element;
    label: string;
};

interface Props extends DialogProps {
    actions?: Action[];
    className?: string;
    closable?: boolean;
    hasFooter?: boolean;
    hasTabs?: boolean;
    isBasic?: boolean;
    launchFullscreen?: boolean;
    modalID?: string;
    options?: MenuItem[];
    title: string;
    topic?: string;
    uiCancelAlt?: Action[];
    visible: boolean;
}

interface TabContent extends Props {
    content?: never;
    tabItems: TabItems[];
}

interface ModalContent extends Props {
    content: JSX.Element;
    tabItems?: never;
}

type ModalProps = ModalContent | TabContent;

const Modal = (props: ModalProps) => {
    const {
        actions,
        appendTo,
        className,
        closable,
        content,
        hasFooter,
        isBasic,
        launchFullscreen,
        maximized,
        maximizable,
        modalID,
        onHide,
        onShow,
        options,
        tabItems,
        title,
        topic,
        uiCancelAlt,
        ...otherProps
    } = props;

    const navigate = useNavigate();
    const optionsMenuRef = useRef<Menu>(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [toggleMaximise, setToggleMaximise] = useState(maximized);

    const actionsMap =
        actions &&
        actions.map((action, i) => {
            return (
                <Button
                    key={`action-${action.label.trim()}`} // TODO: supply lodash uniqueID
                    className={`p-button-sm${
                        action.severity ? ' p-button-' + action.severity : ''
                    } ${action.className ? action.className : ''}`}
                    onClick={() =>
                        action.command
                            ? action.command()
                            : action.url
                            ? navigate(action.url)
                            : ''
                    }
                    type={action.type && action.type}
                    form={action.form && action.form}
                    disabled={action.disabled && action.disabled}
                >
                    {action.icon && (
                        <ButtonIcon
                            iconName={action.icon}
                            placement={action.iconRight ? 'right' : 'left'}
                        />
                    )}
                    <span className="p-button-label">{action.label}</span>
                </Button>
            );
        });

    const modalHeader = (
        <>
            {topic && <h4 className="title is-bold">{topic}</h4>}
            <h4 className={`title${!topic ? ' is-bold' : ''}`}>{title}</h4>
        </>
    );

    const modalFooter = (
        <>
            {closable !== false ? (
                <>
                    {uiCancelAlt ? (
                        /** TODO: clean up */
                        <Button
                            className={`p-button-sm ${
                                (uiCancelAlt[0].severity &&
                                    'p-button-' + uiCancelAlt[0].severity) ||
                                ''
                            } ${
                                (uiCancelAlt[0].className &&
                                    uiCancelAlt[0].className) ||
                                ''
                            }`}
                            onClick={() =>
                                uiCancelAlt[0].command
                                    ? uiCancelAlt[0].command()
                                    : uiCancelAlt[0].url
                                    ? navigate(uiCancelAlt[0].url)
                                    : ''
                            }
                            type={uiCancelAlt[0].type && uiCancelAlt[0].type}
                            form={uiCancelAlt[0].form && uiCancelAlt[0].form}
                        >
                            {uiCancelAlt[0].icon && (
                                <ButtonIcon
                                    iconName={uiCancelAlt[0].icon}
                                    placement="left"
                                />
                            )}
                            <span className="p-button-label">
                                {uiCancelAlt[0].label}
                            </span>
                        </Button>
                    ) : (
                        <Button
                            className="p-button-text p-button-sm p-button-secondary"
                            onClick={() => onHide()}
                            type="button"
                        >
                            <ButtonIcon iconName="cancel" placement="left" />
                            <span className="p-button-label">Cancel</span>
                        </Button>
                    )}
                    <div className="modal-footer_actions">{actionsMap}</div>
                </>
            ) : actions && actions.length > 1 ? (
                <div className="modal-footer_actions">{actionsMap}</div>
            ) : (
                <>{actionsMap}</>
            )}
        </>
    );

    // additional header icons/buttons
    const modalHeaderUiClasses =
        'p-button-sm p-button-text p-button-secondary ui-button';
    const headerIconBar = (
        <>
            {/*extended options dropdown */}
            {options && (
                <>
                    <Button
                        className={modalHeaderUiClasses}
                        onClick={(e) =>
                            optionsMenuRef.current &&
                            optionsMenuRef.current.toggle(e)
                        }
                        type="button"
                    >
                        <Icon name="more_horiz" size="small" />
                    </Button>
                    <Menu model={options} popup ref={optionsMenuRef} />
                </>
            )}
            {/* maximise button */}
            {props.maximizable && !maximized && !launchFullscreen && (
                <Button
                    className={modalHeaderUiClasses}
                    onClick={() => setToggleMaximise(!toggleMaximise)}
                    type="button"
                >
                    {toggleMaximise ? (
                        <Icon name="close_fullscreen" size="small" />
                    ) : (
                        <Icon name="open_in_full" size="small" />
                    )}
                </Button>
            )}
            {/* close button */}
            {closable && (
                <Button
                    className={modalHeaderUiClasses}
                    onClick={() => onHide()}
                    type="button"
                >
                    <Icon name="close" size="small" />
                </Button>
            )}
        </>
    );

    /**
     * @desc conditional modifier class vars. applied to Dialog className prop.
     */
    const maximisedClass = toggleMaximise ? ' p-dialog-maximized' : '';
    const tabsClass = tabItems ? ' has-tabs' : '';

    /**
     * @desc apply closable props group when modal closable prop is set false.
     */
    let modalProps = {};
    if (closable === false) {
        modalProps = {
            dismissableMask: false,
            closeOnEscape: false,
            closable: false,
        };
    }

    return (
        <>
            <Dialog
                onHide={() => onHide()}
                appendTo={appendTo}
                blockScroll
                className={`modal${maximisedClass}${tabsClass} ${
                    className ? className : ''
                }${isBasic ? ' is-basic' : ''}`}
                closeOnEscape
                dismissableMask
                footer={!isBasic && hasFooter && modalFooter}
                header={modalHeader}
                icons={headerIconBar}
                id={uniqueId(modalID ? modalID + '-modal-' : 'modal-')}
                maximized={launchFullscreen && launchFullscreen}
                {...modalProps}
                {...otherProps}
            >
                {tabItems ? (
                    <>
                        <TabMenu
                            model={tabItems}
                            activeIndex={activeIndex}
                            onTabChange={(e) => setActiveIndex(e.index)}
                        />
                        <div className="tab-content">
                            {tabItems[activeIndex].content}
                        </div>
                    </>
                ) : (
                    <div className="modal-content">{content}</div>
                )}
            </Dialog>
        </>
    );
};

Modal.defaultProps = {
    appendTo: 'self',
    closable: true,
    draggable: false,
    hasFooter: true,
    isBasic: false,
    maximizable: true,
};

export default Modal;
