import { PaginatedResponse, Response } from './api';
import { Game } from './game';

export enum EventType {
    Generic = 'Generic',
    Game = 'Game',
    //Testing = 'Testing',
    //Training = 'Training',
}

export enum EventStatus {
    Draft = 'Draft',
    Published = 'Published',
    Live = 'Live',
    Complete = 'Complete',
    Postponed = 'Postponed',
    Archived = 'Archived',
}

export enum EventVisibility {
    Public = 'Public',
    Host = 'Host',
    Participants = 'Participants',
}

// Location Types

export enum LocationCategory {
    Used = 'Used',
    Saved = 'Saved',
}

export interface LocationInfo {
    category: LocationCategory;
    locationExtraInfo: string;
    streetNumber: string;
    route: string;
    formattedAddress: string;
    postCode: number;
}

// RSVP Types

export enum UserResponse {
    Yes = 'Yes',
    No = 'No',
    Maybe = 'Maybe',
    NoReply = 'NoReply',
}

export enum UserAttendance {
    Attended = 'Attended',
    Absent = 'Absent',
}

export enum AttendeeRole {
    Owner = 'Owner',
    Admin = 'Admin',
    Staff = 'Staff',
    Attendee = 'Attendee',
}

export interface Attendee {
    attendeeID: string;
    eventID: string;
    roles: string[];
    userID: string;
}

export interface InvitedUser {
    userID: string;
    name: string;
    role: string;
    response: string;
    createdAt: string;
    createdBy: string;
}

export interface InvitedExternals {
    email: string;
    token: string;
    name: string;
    role: string;
    createdAt: string;
    createdBy: string;
}

export interface Event {
    attendees: Attendee[];
    calendarNodeID: string;
    calendarNodeType: EntityType;
    city: string;
    country: string;
    createdAt: string;
    description: string;
    eventID: string;
    eventName: string;
    eventOwnerID: string;
    eventStatus: EventStatus;
    eventType: EventType;
    eventVisibility: EventVisibility;
    invitedExternals: InvitedExternals[];
    invitedUsers: InvitedUser[];
    lastEdited: string;
    lastEditedBy: string;
    latitude: number;
    locationInfo: LocationInfo;
    locationName: string;
    longitude: number;
    parentID: string;
    placeID: string;
    postCode: number;
    rootID: string;
    startDateTime: string;
    state: string;
    suburb: string;
    ticketRequired: boolean;
    // Generic event properties
    arrivalDateTime?: string;
    endDateTime?: string;
    // Game event properties
    game?: Game;
    gameDetails?: Game;
    isHomeTeam?: boolean;
    seasonID?: string;
    // For grouping events
    dateGroup?: string;
}

export interface EventFormData {
    eventID?: string;
    eventName: string;
    eventType: EventType;
    startDateTime: number;
    arrivalDateTime: number;
    endDateTime: number;
    description?: string;
    address?: object;
}

export type EventDates = Pick<
    Event,
    'startDateTime' | 'endDateTime' | 'arrivalDateTime'
>;

export type MaybeEvent = XOR<Event, EventDates>;

export type EventResponse = Response<Event>;
export type EventsResponse = PaginatedResponse<Event[]>;
