import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isArray } from 'lodash';

import { Button } from 'primereact/button';

import ButtonIcon from '../components/ButtonIcon';

import { Action, RMButtonProps } from '../types/common';

interface Props {
    children: React.ReactNode;
    closable?: boolean;
    closeOnEscape?: boolean;
    fixedFooter?: boolean;
    footer?: Action[] | React.ReactNode;
    header?: string | React.ReactNode;
    icons?: RMButtonProps[];
    onClose?: Function;
    onMaximize?: Function;
    maximizable?: boolean;
    maximized?: boolean;
    showHeader?: boolean;
}

/**
 * @desc Inline drawer component, primary usage as preview panel for content.
 * @param props
 * @constructor
 */
const InlineDrawer = (props: Props) => {
    const { footer, onClose } = props;

    const closeRef = useRef(null);

    const [maximizedState, setMaximizedState] = useState<boolean>(false);

    const maximized = props.onMaximize ? props.maximized : maximizedState;

    const closeDrawer = useCallback(
        (e: React.MouseEvent<HTMLElement> | KeyboardEvent) => {
            if (onClose) {
                onClose();
            }
            e.preventDefault();
        },
        [onClose]
    );

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (!e.currentTarget) {
                return;
            }

            if (props.closable && props.closeOnEscape && e.key === 'Escape') {
                closeDrawer(e);
                e.stopImmediatePropagation();
            }
        },
        [closeDrawer, props.closable, props.closeOnEscape]
    );

    // listen for 'esc' keydown
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);

    const toggleMaximize = (e: React.MouseEvent<HTMLElement>) => {
        if (props.onMaximize) {
            props.onMaximize({
                originalEvent: e,
                maximized: !maximized,
            });
        } else {
            setMaximizedState((prevMaximized) => !prevMaximized);
        }
        e.preventDefault();
    };

    const renderCustomIcons = () => {
        if (props.icons && props.icons.length > 0) {
            props.icons.map(({ className, icon, ...otherProps }) => (
                <Button
                    type="button"
                    className={`p-dialog-header-icon p-dialog-header-custom p-link ${className}`}
                    {...otherProps}
                >
                    <ButtonIcon iconName={icon} />
                </Button>
            ));
        }
        return null;
    };

    const renderCloseIcon = () => {
        if (props.closable) {
            return (
                <Button
                    ref={closeRef}
                    type="button"
                    onClick={closeDrawer}
                    className="p-button-text p-button-icon-only p-drawer-header-icon p-dialog-drawer-close"
                >
                    <ButtonIcon iconName="close" />
                </Button>
            );
        }
        return null;
    };

    const renderMaximizeIcon = () => {
        if (props.maximizable) {
            return (
                <Button
                    type="button"
                    className="p-button-text p-button-icon-only p-drawer-header-icon p-drawer-header-maximize"
                    onClick={toggleMaximize}
                >
                    <ButtonIcon
                        iconName={maximized ? 'fullscreen_exit' : 'fullscreen'}
                    />
                </Button>
            );
        }
        return null;
    };

    const renderHeader = () => {
        if (props.showHeader) {
            return (
                <div className="inline-drawer_header">
                    <div className="inline-drawer_header-title">
                        {props.header}
                    </div>
                    <div className="inline-drawer_header-actions">
                        {renderCustomIcons()}
                        {renderMaximizeIcon()}
                        {renderCloseIcon()}
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderFooter = () => {
        if (footer) {
            return (
                <div className="inline-drawer_footer">
                    {isArray(footer) ? (
                        <div className="p-buttonset set-is-spaced">
                            {footer.map(
                                (
                                    {
                                        icon,
                                        command,
                                        label,
                                        className,
                                        ...otherProps
                                    },
                                    i
                                ) => (
                                    <Button
                                        key={`footer-action-${i}`}
                                        className={`p-button-secondary p-button-outlined ${className}`}
                                        onClick={() => command && command()}
                                        {...otherProps}
                                    >
                                        {icon && (
                                            <ButtonIcon
                                                iconName={icon}
                                                placement="left"
                                            />
                                        )}
                                        <span className="p-button-label">
                                            {label}
                                        </span>
                                    </Button>
                                )
                            )}
                        </div>
                    ) : (
                        footer
                    )}
                </div>
            );
        }

        return null;
    };

    return (
        <>
            {maximized && <div className="inline-drawer_overlay" />}
            <div
                className={`inline-drawer${
                    props.fixedFooter ? ' has-fixed-footer' : ''
                }${maximized ? ' is-maximized' : ''}`}
            >
                {renderHeader()}
                <div className="inline-drawer_content">{props.children}</div>
                {renderFooter()}
            </div>
        </>
    );
};

InlineDrawer.defaultProps = {
    closable: true,
    fixedFooter: false,
    maximizable: true,
    showHeader: true,
};

export default InlineDrawer;
