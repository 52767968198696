import { Response } from './api';

export enum ColourOptions {
    Black = '#000000',
    White = '#ffffff',
    Brown = '#561702',
    Charcoal = '#44444E',
    Grey = '#6C6D70',
    LightGrey = '#B6B9BC',
    DarkGreen = '#015837',
    Green = '#008B4C',
    Teal = '#008D99',
    Navy = '#15324E',
    Blue = '#1B4F9D',
    SeaBlue = '#016FAF',
    LightBlue = '#5899CF',
    Aqua = '#00BFD2',
    Purple = '#3F0085',
    HotPink = '#C52B82',
    LightPink = '#E97EB2',
    Burgundy = '#602732',
    Maroon = '#7E1737',
    Crimson = '#A91736',
    Scarlet = '#D12140',
    BurntOrange = '#B74024',
    Orange = '#D5592B',
    LightOrange = '#DF7B27',
    Mustard = '#DEAC27',
    Yellow = '#FCDA00',
    BrightGold = '#FEBF56',
    VegasGold = '#DCCB96',
}

export interface Colour {
    colourID: number;
    colourSlug: string;
    colourName: string;
    colourHex: string;
    enabled: boolean;
}

export interface Template {
    enabled: boolean;
    requiredColours: number;
    templateContent: string;
    templateGroup: string;
    templateName: string;
    templateSlug: string;
}

export interface DesignOptions {
    template?: Template;
    primary?: Colour;
    secondary?: Colour;
    tertiary?: Colour;
}

export interface Design {
    template: string;
    primary: string;
    secondary: string;
    tertiary: string;
}

export interface Designs {
    colours: Colour[];
    templates: Template[];
}

export type DesignResponse = Response<Designs>;
