import { Navigate } from 'react-router-dom';
import DocumentHead from '../../components/DocumentHead';

interface UserHomeProps {
    user: {
        userID: string;
    };
}

const UserHome = ({ user }: UserHomeProps): JSX.Element => {
    return (
        <>
            <DocumentHead 
                title="Home - Rookie Me Hub"
                description="User home page"
            />
            <Navigate to={`/u/${user.userID}/entities`} replace />
        </>
    );
};

export default UserHome;
