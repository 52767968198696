import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { RolesResponse } from '../types/roles';
import { generatePath } from 'react-router-dom';
import { MeResponse } from '../types/user';

export const rolesApi = createApi({
    reducerPath: 'rolesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getMe: builder.query<MeResponse, void>({
            query: () => ({
                url: apiEndpoints.getMe.url,
                method: apiEndpoints.getMe.method,
            }),
        }),
        getRoles: builder.query<RolesResponse, { userID: string }>({
            query: ({ userID }) => ({
                url: generatePath(apiEndpoints.getRoles.url, { userID }),
                method: apiEndpoints.getRoles.method,
            }),
        }),
    }),
});

export const { useGetMeQuery, useGetRolesQuery, useLazyGetRolesQuery } =
    rolesApi;
