import { useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PlayerPane from './PlayerPane';
import PlayerForm from './PlayerForm';

import ButtonIcon from '../../components/ButtonIcon';
import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import InlineDrawer from '../../components/InlineDrawer';
import ListItem from '../../components/ListItem';
import Modal from '../../components/Modal';
import PageHeader from '../../components/page/PageHeader';

import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import {
    DataTable,
    DataTableFooterTemplateOptions,
} from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { ACTION_TYPES, ERROR_TYPES, SEVERITY } from '../../types/common.d';
import { Player } from '../../types/team';
import { Roles } from '../../types/roles';
import { Toolbar } from 'primereact/toolbar';
import { Avatar } from 'primereact/avatar';
import { getFirstChars } from '../../util/helper';
import { sortBy } from 'lodash';

const STATUS_SUCCESS = 'success';
const STATUS_UPDATED = 'updated';

interface Props {
    data: Player[];
    focusedUser: Player | null;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onClickUser: (playerID: string) => void;
    onCloseDeleteDialog: () => void;
    onCloseDrawer: () => void;
    onCloseInviteDialog: () => void;
    onDeleteUser: (playerID: string) => void;
    onFocusUser: (player: Player) => void;
    onLoadMore?: () => void;
    onShowDeleteDialog: () => void;
    onViewUser: (playerID: string) => void;
    onShowInviteDialog: Function;
    roles: Roles;
    showDeleteDialog: boolean;
    showPagination: boolean;
    viewMedia?: string;
}

const PlayersView = (props: Props) => {
    const { focusedUser, isError, isFetching, isLoading } = props;
    const navigate = useNavigate();

    const dataTable = useRef<DataTable>(null);
    const rowMenu = useRef<Menu>(null);
    const rowContextMenu = useRef<ContextMenu>(null);
    const playerFormToast = useRef<Toast>(null);

    const [searchParams] = useSearchParams();

    const focusedUserID = focusedUser?.playerID;

    const handleSubmitResult = (submitResult: string) => {
        const hasSubmitted = submitResult === STATUS_SUCCESS;
        const hasUpdated = submitResult === STATUS_UPDATED;

        // TODO: missing API stuff?

        playerFormToast.current?.show({
            severity: hasSubmitted || hasUpdated ? 'success' : 'warn',
            summary: hasUpdated
                ? 'Player Updated'
                : hasSubmitted
                ? 'Player Added'
                : 'Player Addition Unsuccessful',
            detail: hasUpdated
                ? 'The players details have been amended.'
                : hasSubmitted
                ? 'The player has been added to your team and is ready to manage.'
                : 'Your player addition could not be fulfilled, try again later.',
        });
    };

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();
    };

    const titleTemplate = (row: Player) => {
        return (
            <div className="detail-cell">
                <Avatar
                    label={getFirstChars(row.displayName, 2).toUpperCase()}
                    image={row.playerImageURL}
                    style={{ background: '#4A4A4D', color: '#ffffff' }}
                    shape="circle"
                />
                <span>
                    {row.firstName} {row.lastName}
                </span>
            </div>
        );
    };

    const actionTemplate = (row: Player) => {
        return (
            <div className="p-buttonset">
                <Button
                    className="p-button p-button-secondary p-button-text td-action"
                    aria-label="More"
                    onClick={(e) => {
                        if (rowMenu.current) {
                            rowMenu.current.toggle(e);
                        }
                        props.onFocusUser(row);
                    }}
                >
                    <ButtonIcon
                        iconName="more_horiz"
                        placement="center"
                        size="small"
                    />
                </Button>
            </div>
        );
    };

    const renderGroup = (row: Player) => {
        return (
            <Avatar
                style={{
                    background: row && row.colour ? row.colour : 'transparent',
                    width: '24px',
                    height: '16px',
                    border: '1px solid #e5e5e5',
                }}
            />
        );
    };

    const columnSchema: ColumnProps[] = [
        {
            body: isLoading ? <Skeleton /> : null,
            field: 'uniformNumber',
            header: '#',
            style: { width: '60px' },
            align: 'right',
            sortable: true,
            alignHeader: 'right',
        },
        {
            body: isLoading ? <Skeleton /> : titleTemplate,
            field: 'firstName',
            header: 'Name',
            sortable: true,
            style: { width: '400px' },
        },
        {
            field: 'player.colour',
            header: 'Group',
            sortable: true,
            body: renderGroup,
            style: { width: '100px' },
            align: 'center',
            alignHeader: 'center',
        },
        {
            body: isLoading ? <Skeleton /> : actionTemplate,
            className: 'actions-td',
            field: 'action',
            header: '',
        },
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    // empty array to populate rows for skeleton loading components
    const blankRows = Array.from({ length: 5 });

    const pageHeaderActions = [
        {
            icon: 'add_circle',
            key: 'import',
            label: 'Import Players',
            severity: SEVERITY.secondary,
            type: ACTION_TYPES.button,
            command: () => navigate('import'),
        },
        {
            icon: 'add_circle',
            key: 'create',
            label: 'Add Player',
            severity: SEVERITY.primary,
            type: ACTION_TYPES.button,
            command: () => props.onShowInviteDialog(true),
        },
    ];

    const tdMenuItems = [
        {
            label: 'Edit',
            icon: <Icon name="edit" className="p-menuitem-icon" />,
            command: () => focusedUserID && props.onShowInviteDialog(false),
        },
        {
            label: 'Delete',
            icon: <Icon name="delete" className="p-menuitem-icon" />,
            command: () => props.onShowDeleteDialog(),
        },
    ];

    const playerPaneActions = [
        {
            label: 'Edit Player',
            icon: 'manage_accounts',
            command: () => focusedUserID && props.onShowInviteDialog(false),
        },
        {
            label: 'Remove Player',
            icon: 'person_remove',
            command: () => props.onShowDeleteDialog(),
        },
    ];

    const deleteModalContent = focusedUser && (
        <>
            <p className="delete-msg">
                Are you sure you want to remove this staff member?
            </p>
            <div className="list">
                <ListItem
                    avatar={{
                        label:
                            focusedUser.firstName + ' ' + focusedUser.lastName,
                        image: focusedUser.playerImageURL,
                    }}
                    caption={focusedUser.displayName}
                    title={focusedUser.firstName}
                />
            </div>
            <div className="form-actions">
                <Button
                    className="p-button-text p-button-sm p-button-secondary"
                    onClick={() => props.onCloseDeleteDialog()}
                    type="button"
                >
                    <ButtonIcon iconName="cancel" placement="left" />
                    <span className="p-button-label">Cancel</span>
                </Button>
                <Button
                    className={`p-button-${SEVERITY.danger}`}
                    type="submit"
                    onClick={() =>
                        focusedUserID && props.onDeleteUser(focusedUserID)
                    }
                >
                    <ButtonIcon iconName="delete" placement="left" />
                    <span className="p-button-label">Remove Staff</span>
                </Button>
            </div>
        </>
    );

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              command: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                              severity: SEVERITY.primary,
                          },
                      ]
                    : pageHeaderActions
            }
            alignment="middle"
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No players found`}
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : "To get started select 'Add Player' in the top right area."
            }
        />
    );

    const footerTemplate = (data: DataTableFooterTemplateOptions) => {
        return (
            <Button
                onClick={props.onLoadMore}
                className={`p-button-${SEVERITY.secondary} p-button-sm`}
                label="Load more"
            >
                <ButtonIcon iconName="pending" placement="right" />
            </Button>
        );
    };

    return (
        <div className={`page view member-view`}>
            <div className="view-inner">
                <PageHeader
                    title="Players"
                    actions={pageHeaderActions}
                    roles={props.roles}
                />
                <DataTable
                    ref={dataTable}
                    header={() => (
                        <Toolbar
                            right={
                                <Button
                                    type="button"
                                    onClick={() => exportCSV()}
                                    label="Export CSV"
                                    className="p-button-secondary"
                                />
                            }
                        />
                    )}
                    value={
                        isLoading
                            ? blankRows
                            : sortBy(
                                  props.data,
                                  (player) =>
                                      player.uniformNumber &&
                                      Number(player.uniformNumber)
                              )
                    }
                    loading={isFetching && !isLoading}
                    footer={
                        !isFetching && props.showPagination
                            ? footerTemplate
                            : null
                    }
                    emptyMessage={tableEmptyMessage}
                    responsiveLayout="scroll"
                    selectionMode="single"
                    onSelectionChange={(e) => {
                        props.onClickUser(e.value.playerID);
                        props.onFocusUser(e.value);
                    }}
                    onContextMenuSelectionChange={(e) =>
                        props.onFocusUser(e.value)
                    }
                    onContextMenu={(e) => {
                        if (rowContextMenu.current) {
                            rowContextMenu.current.show(e.originalEvent);
                        }
                        if (rowMenu.current) {
                            rowMenu.current.hide(e.originalEvent);
                        }
                    }}
                >
                    {isLoading
                        ? columns
                        : props.data.length > 0
                        ? columns
                        : null}
                </DataTable>
            </div>

            <Modal
                className="delete-modal"
                // @ts-ignore
                content={focusedUser && deleteModalContent}
                hasFooter={false}
                maximizable={false}
                onHide={props.onCloseDeleteDialog}
                title="Delete Player"
                visible={props.showDeleteDialog}
            />

            <Menu model={tdMenuItems} popup ref={rowMenu} />
            <ContextMenu model={tdMenuItems} ref={rowContextMenu} />

            {searchParams.get('create') ? (
                <InlineDrawer
                    fixedFooter
                    header={
                        focusedUser?.playerID ? 'Edit Player' : 'Add Player'
                    }
                    onClose={props.onCloseDrawer}
                >
                    <PlayerForm
                        onCancel={props.onCloseInviteDialog}
                        onSubmit={handleSubmitResult}
                        playerID={focusedUser?.playerID}
                        initialValues={focusedUser ? focusedUser : undefined}
                    />
                </InlineDrawer>
            ) : searchParams.get('id') && focusedUser ? (
                <InlineDrawer
                    footer={playerPaneActions}
                    fixedFooter
                    header={
                        focusedUser?.firstName + ' ' + focusedUser?.lastName
                    }
                    onClose={props.onCloseDrawer}
                >
                    <PlayerPane
                        player={
                            props.data &&
                            props.data.find(
                                (player) =>
                                    player.playerID === searchParams.get('id')
                            )
                        }
                    />
                </InlineDrawer>
            ) : null}

            <Toast ref={playerFormToast} />
        </div>
    );
};

export default PlayersView;
