import { toUpper } from 'lodash';
import { useGetOrganisationKitQuery } from '../api/organisations';
import { useGetTeamKitQuery } from '../api/teams';
import { Avatar, AvatarProps } from 'primereact/avatar';
import { EntityType } from '../types/common';

interface Props {
    entityType: EntityType;
    entityID: string;
    entityName: string;
    avatarProps?: AvatarProps;
}

const EntityAvatar = (props: Props) => {
    const organisationData = useGetOrganisationKitQuery(
        { organisationID: props.entityID },
        { skip: props.entityType !== 'organisations' }
    );
    const teamData = useGetTeamKitQuery(
        { teamID: props.entityID },
        { skip: props.entityType !== 'teams' }
    );

    const svg =
        props.entityType === 'organisations'
            ? organisationData?.data
            : teamData?.data;

    return (
        <Avatar
            template={
                svg && (
                    <span
                        className="svg-image"
                        dangerouslySetInnerHTML={{ __html: svg }}
                    />
                )
            }
            label={toUpper(props.entityName.slice(0, 2))}
            {...props.avatarProps}
        />
    );
};

export default EntityAvatar;
