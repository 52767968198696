import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    Formik,
    FormikErrors,
    FormikHelpers,
    FormikProps,
    FormikValues,
} from 'formik';
import { intersection, pickBy } from 'lodash';
import { toISOString, toISOStringWithTimezone } from '../../util/helper';

// Hooks
import useGooglePlaceAutoComplete from '../../hooks/googlePlaces';
import {
    useGetOrganisationEventQuery,
    useUpsertOrganisationEventMutation,
} from '../../api/organisations';
import {
    useGetTeamEventQuery,
    useUpsertTeamEventMutation,
} from '../../api/teams';
import {
    useGetUserEventQuery,
    useUpsertUserEventMutation,
} from '../../api/events';

// Components
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import ButtonIcon from '../../components/ButtonIcon';
import Icon from '../../components/Icon';

// Types
import { EventType, MaybeEvent } from '../../types/event.d';

interface EventFormValues
    extends Omit<
        MaybeEvent,
        'startDateTime' | 'endDateTime' | 'arrivalDateTime'
    > {
    startDateTime?: string;
    endDateTime?: string;
    arrivalDateTime?: string;
}

interface Props {
    eventID?: string;
    formID?: string;
    initialValues?: EventFormValues;
    hasSubmitUI: boolean;
    onCancel: () => void;
    onSubmit?: Function;
}

// Constants
const availableEventTypes = Object.keys(EventType).map((type) => ({
    label: type,
    value: type,
}));

const today = new Date();

// set default start date to tomorrow 12pm
const defaultStartDateTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
    12,
    0,
    0
);

// set default end date to tomorrow 2pm
const defaultEndDateTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
    14,
    0,
    0
);

const availableFields = {
    Generic: [
        'eventType',
        'eventName',
        'eventStatus',
        'startDateTime',
        'endDateTime',
        'arrivalDateTime',
        'description',
        'placeID',
        'latitude',
        'longitude',
        'location',
        'locationName',
        'suburb',
        'state',
        'postCode',
        'city',
        'country',
        'locationInfo',
    ],
    Game: [
        'eventType',
        'eventName',
        'eventStatus',
        'seasonID',
        'startDateTime',
        'isHomeTeam',
        'opponentTeam',
        'description',
        'location',
        'placeID',
        'latitude',
        'longitude',
        'locationName',
        'suburb',
        'state',
        'postCode',
        'city',
        'country',
        'locationInfo',
    ],
};

const requiredFields = {
    Generic: [
        'eventType',
        'eventName',
        'startDateTime',
        'endDateTime',
        'locationInfo',
    ],
    Game: [
        'eventType',
        'eventName',
        'startDateTime',
        'endDateTime',
        'locationInfo',
    ],
};

const defaultEventValues = {
    eventType: 'Generic',
    isHomeTeam: true,
    startDateTime: toISOString(defaultStartDateTime),
    endDateTime: toISOString(defaultEndDateTime),
};

const EventForm = ({
    eventID,
    initialValues,
    hasSubmitUI,
    onCancel,
    onSubmit,
    formID,
}: Props) => {
    // Route Hooks
    const { organisationID, userID, teamID } = useParams();

    // Ref Hooks
    const formikRef = useRef<FormikProps<EventFormValues>>(null);
    const locationRef = useRef<HTMLInputElement>(null);

    // API Hooks
    const useGetEventsQuery = organisationID
        ? useGetOrganisationEventQuery
        : teamID
        ? useGetTeamEventQuery
        : useGetUserEventQuery;

    const useUpsertEventMutation = organisationID
        ? useUpsertOrganisationEventMutation
        : teamID
        ? useUpsertTeamEventMutation
        : useUpsertUserEventMutation;

    const [upsertEvent] = useUpsertEventMutation();

    const { data, isLoading, isError, isFetching } = useGetEventsQuery(
        { eventID, organisationID, teamID, userID },
        { skip: !eventID || (!userID && !teamID && !organisationID) }
    );

    // Google Places
    const googleAutoComplete = useGooglePlaceAutoComplete();
    let autoComplete = '';

    useEffect(() => {
        async function loadGoogleMaps() {
            // initialize the Google Place Autocomplete widget and bind it to an input element.
            // eslint-disable-next-line
            autoComplete = await googleAutoComplete.initAutoComplete(
                locationRef.current,
                handleAddressSelect
            );
        }
        loadGoogleMaps();
    }, []);

    const isCreate = !eventID || eventID === 'new';

    const getInitialValues = () => {
        let initVals: EventFormValues = initialValues
            ? {
                  ...defaultEventValues,
                  ...initialValues,
              }
            : defaultEventValues;

        if (data && data.data) {
            initVals = {
                ...initVals,
                ...data.data,
            };

            if (initVals.game) {
                initVals.opponentTeam =
                    initVals.game.opponentTeam?.teamName || 'Opponent';
            }
        }

        if (initVals.startDateTime) {
            initVals.startDateTime = toISOString(initVals.startDateTime);
        }
        if (initVals.endDateTime) {
            initVals.endDateTime = toISOString(initVals.endDateTime);
        }
        if (initVals.arrivalDateTime) {
            initVals.arrivalDateTime = toISOString(initVals.arrivalDateTime);
        }

        return initVals;
    };

    const handleAddressSelect = async () => {
        const address = await googleAutoComplete.getFullAddress(autoComplete);

        if (formikRef.current) {
            formikRef.current.setValues({
                ...formikRef.current.values,
                placeID: address.placeID,
                latitude: address.latitude,
                longitude: address.longitude,
                locationName: address.name,
                suburb: address.locality,
                state: address.adminArea1Short,
                postCode: Number(address.postalCode),
                city: address.adminArea1Long,
                country: address.countryLong,
                locationInfo: {
                    category: 'Used',
                    streetNumber: address.streetNumberShort,
                    route: address.routeShort,
                    formattedAddress: address.formattedAddress,
                },
            });
        }
    };

    const handleSubmit = (
        values: EventFormValues,
        helpers: FormikHelpers<EventFormValues>
    ) => {
        const eventType = values.eventType;
        let formData = values;

        if (values.startDateTime) {
            formData.startDateTime = toISOStringWithTimezone(
                values.startDateTime
            );
        }

        if (values.endDateTime) {
            formData.endDateTime = toISOStringWithTimezone(values.endDateTime);
        }

        if (values.arrivalDateTime) {
            formData.arrivalDateTime = toISOStringWithTimezone(
                values.arrivalDateTime
            );
        }

        if (values.opponentTeam) {
            formData.opponentTeam = {
                teamName: values.opponentTeam,
            };
        }

        if (!isCreate) {
            const {
                eventType,
                placeID,
                latitude,
                longitude,
                locationName,
                suburb,
                state,
                postCode,
                city,
                country,
                locationInfo,
                ...editProps
            } = formData;

            formData = editProps;
        }

        const filtered = pickBy(formData, (value, key) =>
            availableFields[eventType as keyof typeof availableFields].includes(
                key
            )
        );

        helpers.setSubmitting(true);

        upsertEvent({ eventID, ...filtered, organisationID, userID, teamID })
            .then((response) => {
                console.log('create success', response);
            })
            .catch((err) => {
                console.log('create error', err);
            })
            .finally(() => {
                helpers.setSubmitting(false);
                onCancel();
            });
    };

    const validate = (values: FormikValues) => {
        const eventType = values.eventType;
        let errors: FormikErrors<EventFormValues> = {};

        const required = intersection(
            requiredFields[eventType as keyof typeof requiredFields],
            availableFields[eventType as keyof typeof availableFields]
        );

        required.forEach((field) => {
            if (!values[field]) {
                errors[field as keyof EventFormValues] =
                    'Field cannot be blank';
            }
        });

        if (
            required.includes('endDateTime') &&
            values.endDateTime < values.startDateTime
        ) {
            errors['endDateTime'] = 'End date must be after the start date';
        }

        return errors;
    };

    const renderErrorMessage = (errorMsg: string) => {
        return (
            <Message
                className="form-message form-message--error message-floated"
                severity="error"
                text={errorMsg}
                icon={<Icon name="error" size="small" />}
            />
        );
    };

    if (eventID && (isLoading || isFetching || isError)) {
        return <div>Loading</div>;
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={getInitialValues()}
            onSubmit={handleSubmit}
            validate={validate}
        >
            {({
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setValues,
                values,
            }) => {
                return (
                    <form
                        id={formID}
                        className={'p-fluid form'}
                        onSubmit={handleSubmit}
                    >
                        {/* EventType */}
                        {isCreate && teamID && (
                            <div className="form-segment">
                                <label htmlFor="eventType">Event Type</label>
                                <SelectButton
                                    id="eventType"
                                    name="eventType"
                                    value={values.eventType}
                                    options={availableEventTypes}
                                    onChange={handleChange}
                                    unselectable={false}
                                />
                            </div>
                        )}

                        {/* EventName */}
                        <div className="form-segment">
                            <label htmlFor="eventName">Event Title</label>
                            <InputText
                                id="eventName"
                                name="eventName"
                                className={
                                    errors.eventName && touched.eventName
                                        ? 'p-invalid'
                                        : ''
                                }
                                placeholder="Event Title"
                                required
                                onInput={handleChange}
                                onBlur={handleBlur}
                                value={values.eventName}
                            />
                            {errors.eventName &&
                                touched.eventName &&
                                renderErrorMessage(errors.eventName)}
                        </div>

                        {/* OpponentTeam */}
                        {values.eventType === 'Game' && (
                            <div className="form-segment">
                                <label htmlFor="opponentTeam">Opponent</label>
                                <InputText
                                    id="opponentTeam"
                                    name="opponentTeam"
                                    onInput={handleChange}
                                    onBlur={handleBlur}
                                    value={values.opponentTeam}
                                    required
                                />
                                {errors.opponentTeam &&
                                    touched.opponentTeam &&
                                    renderErrorMessage(errors.opponentTeam)}
                            </div>
                        )}

                        {/* IsHomeTeam */}
                        {values.eventType === 'Game' && (
                            <div className="form-segment">
                                <label htmlFor="isHomeTeam">Home/Away</label>
                                <SelectButton
                                    id="isHomeTeam"
                                    name="isHomeTeam"
                                    value={values.isHomeTeam}
                                    options={[
                                        { label: 'Home', value: true },
                                        { label: 'Away', value: false },
                                    ]}
                                    onChange={handleChange}
                                    unselectable={false}
                                    required
                                />
                            </div>
                        )}

                        {/* StartDateTime */}
                        <div className="form-segment">
                            <label htmlFor="startDateTime">
                                Start Date/Time
                            </label>
                            <InputText
                                id="startDateTime"
                                name="startDateTime"
                                className={
                                    errors.startDateTime &&
                                    touched.startDateTime
                                        ? 'p-invalid'
                                        : ''
                                }
                                type="datetime-local"
                                onInput={handleChange}
                                onBlur={handleBlur}
                                value={values.startDateTime}
                                required
                            />
                            {errors.startDateTime &&
                                touched.startDateTime &&
                                renderErrorMessage(errors.startDateTime)}
                        </div>

                        {/* EndDateTime */}
                        {values.eventType === 'Generic' && (
                            <div className="form-segment">
                                <label htmlFor="endDateTime">
                                    End Date/Time
                                </label>
                                <InputText
                                    id="endDateTime"
                                    name="endDateTime"
                                    className={
                                        errors.endDateTime &&
                                        touched.endDateTime
                                            ? 'p-invalid'
                                            : ''
                                    }
                                    type="datetime-local"
                                    onInput={handleChange}
                                    onBlur={handleBlur}
                                    value={values.endDateTime}
                                    required
                                />
                                {errors.endDateTime &&
                                    touched.endDateTime &&
                                    renderErrorMessage(errors.endDateTime)}
                            </div>
                        )}

                        {/* Location */}
                        <div className="form-segment">
                            <label htmlFor="location">Location</label>
                            <div className="p-inputgroup">
                                <InputText
                                    ref={locationRef}
                                    id="location"
                                    name="location"
                                    type="text"
                                    value={
                                        values.locationInfo
                                            ? values.locationInfo
                                                  .formattedAddress
                                            : values.tempLocation
                                    }
                                    className={
                                        errors.locationInfo &&
                                        touched.locationInfo
                                            ? 'p-invalid'
                                            : ''
                                    }
                                    onInput={(e) => {
                                        setFieldValue(
                                            'tempLocation',
                                            e.currentTarget.value
                                        );
                                    }}
                                    onBlur={(e) => {
                                        setFieldTouched('locationInfo');
                                        setFieldValue('tempLocation', '');
                                    }}
                                    placeholder="Search address or venue"
                                    readOnly={!!values.placeID}
                                    required
                                />
                                {!!values.placeID && (
                                    <Button
                                        onClick={() => {
                                            setValues({
                                                ...values,
                                                placeID: '',
                                                latitude: '',
                                                longitude: '',
                                                locationName: '',
                                                locationInfo: '',
                                                suburb: '',
                                                state: '',
                                                postCode: '',
                                                city: '',
                                                country: '',
                                                tempLocation: '',
                                            });
                                        }}
                                        className="p-button-secondary p-button-icon-only"
                                    >
                                        <ButtonIcon iconName="close" />
                                    </Button>
                                )}
                            </div>
                            {errors.locationInfo &&
                                touched.locationInfo &&
                                renderErrorMessage(errors.locationInfo)}
                        </div>

                        {/* Description */}
                        <div className="form-segment">
                            <label htmlFor="description">Description</label>
                            <InputTextarea
                                id="description"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Add some notes"
                                rows={3}
                                value={values.description}
                            />
                        </div>

                        <div>
                            {hasSubmitUI && (
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    label={
                                        isSubmitting
                                            ? 'Loading...'
                                            : isCreate
                                            ? 'Create'
                                            : 'Update'
                                    }
                                />
                            )}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

export default EventForm;
