import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';

export const licensesApi = createApi({
    reducerPath: 'licensesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        createBillingSession: builder.mutation<any, void>({
            query: (data) => ({
                url: apiEndpoints.createBillingSession.url,
                method: apiEndpoints.createBillingSession.method,
                body: data,
            }),
        }),
    }),
});

export const { useCreateBillingSessionMutation } = licensesApi;
