import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { Note, NoteFormData } from '../types/note';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';

export const notesApi = createApi({
    reducerPath: 'notesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getNotes: builder.query<Note[], void>({
            query: () => ({
                url: apiEndpoints.getNotes.url,
                method: apiEndpoints.getNotes.method,
            }),
        }),
        getTeamNotes: builder.query<Note[], string>({
            query: (teamID) => ({
                url: generatePath(apiEndpoints.getTeamNotes.url, { teamID }),
                method: apiEndpoints.getTeamNotes.method,
            }),
        }),
        createNote: builder.mutation<Note, NoteFormData>({
            query: (data) => ({
                url: apiEndpoints.createNote.url,
                method: apiEndpoints.createNote.method,
                body: data,
            }),
        }),
    }),
});

export const { useGetNotesQuery, useGetTeamNotesQuery, useCreateNoteMutation } =
    notesApi;
