import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { Organisation, OrganisationFormData } from '../types/organisation';
import { Team, TeamForm } from '../types/team';
import { isEqual } from 'lodash';

import {
    UserDetailsResponse,
    UserResponse,
    UserSingleDetailsResponse,
    UsersResponse,
} from '../types/user';

import { PaginatedResponse } from '../types/api';
import { TicketResponse } from '../types/ticket';

export const organisationsApi = createApi({
    reducerPath: 'organisationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getOrganisation: builder.query<Organisation, OrganisationFormData>({
            query: ({ organisationID }) => ({
                url: generatePath(apiEndpoints.getOrganisation.url, {
                    organisationID,
                }),
                method: apiEndpoints.getOrganisation.method,
            }),
        }),
        getOrganisationTeams: builder.query<
            PaginatedResponse<Array<Team>>,
            OrganisationFormData
        >({
            query: ({ organisationID, cursor }) => ({
                url: generatePath(
                    `${apiEndpoints.getOrganisationTeams.url}${
                        cursor ? '?cursor=' + cursor : ''
                    }`,
                    { organisationID }
                ),
                method: apiEndpoints.getOrganisationTeams.method,
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ teamID }) => ({
                              type: 'Team' as const,
                              teamID,
                          })),
                          'Team',
                      ]
                    : ['Team'];
            },
        }),
        createOrganisation: builder.mutation<
            Organisation,
            OrganisationFormData
        >({
            query: ({ userID, ...data }) => ({
                url: generatePath(apiEndpoints.createOrganisation.url, {
                    userID,
                }),
                method: apiEndpoints.createOrganisation.method,
                body: data,
            }),
        }),
        createOrganisationTeam: builder.mutation<Team, TeamForm>({
            query: ({ organisationID, ...data }) => ({
                url: generatePath(apiEndpoints.createOrganisationTeam.url, {
                    organisationID,
                }),
                method: apiEndpoints.createOrganisationTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),
        updateOrganisation: builder.mutation<
            Organisation,
            OrganisationFormData
        >({
            query: ({ organisationID, ...data }) => ({
                url: generatePath(apiEndpoints.updateOrganisation.url, {
                    organisationID,
                }),
                method: apiEndpoints.updateOrganisation.method,
                body: data,
            }),
        }),
        updateOrganisationTeam: builder.mutation<Team, TeamForm>({
            query: ({ organisationID, teamID, ...data }) => ({
                url: generatePath(apiEndpoints.updateOrganisationTeam.url, {
                    organisationID,
                    teamID,
                }),
                method: apiEndpoints.updateOrganisationTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),
        deleteOrganisation: builder.mutation<
            Organisation,
            OrganisationFormData
        >({
            query: ({ organisationID }) => ({
                url: generatePath(apiEndpoints.deleteOrganisation.url, {
                    organisationID,
                }),
                method: apiEndpoints.deleteTeam.method,
            }),
        }),
        deleteOrganisationTeam: builder.mutation<
            Team,
            { organisationID: string; teamID: string }
        >({
            query: (params) => ({
                url: generatePath(
                    apiEndpoints.deleteOrganisationTeam.url,
                    params
                ),
                method: apiEndpoints.deleteOrganisationTeam.method,
            }),
            invalidatesTags: ['Team'],
        }),
        upsertOrganisation: builder.mutation({
            query: ({ organisationID, ...data }) => ({
                url: !organisationID
                    ? apiEndpoints.createOrganisation.url
                    : generatePath(apiEndpoints.updateOrganisation.url, {
                          organisationID,
                      }),
                method: !organisationID
                    ? apiEndpoints.createOrganisation.method
                    : apiEndpoints.updateOrganisation.method,
                body: data,
            }),
        }),
        upsertOrganisationTeam: builder.mutation({
            query: ({ teamID, organisationID, ...data }) => ({
                url: generatePath(
                    teamID
                        ? apiEndpoints.updateOrganisationTeam.url
                        : apiEndpoints.createOrganisationTeam.url,
                    {
                        teamID,
                        organisationID,
                    }
                ),
                method: teamID
                    ? apiEndpoints.updateOrganisationTeam.method
                    : apiEndpoints.createOrganisationTeam.method,
                body: data,
            }),
        }),

        // Organisation Staff
        getOrganisationStaff: builder.query<
            UsersResponse,
            { organisationID: string; cursor: string }
        >({
            query: ({ organisationID, cursor }) => ({
                url: generatePath(
                    `${apiEndpoints.getOrganisationStaff.url}${
                        cursor ? '?cursor=' + cursor : ''
                    }`,
                    { organisationID }
                ),
                method: apiEndpoints.getOrganisationStaff.method,
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ userID }) => ({
                              type: 'Staff' as const,
                              userID,
                          })),
                          'Staff',
                      ]
                    : ['Staff'];
            },
        }),

        getOrganisationStaffDetails: builder.query<
            UserDetailsResponse,
            { organisationID: string; cursor: string }
        >({
            query: ({ organisationID, cursor }) => ({
                url: generatePath(
                    `${apiEndpoints.getOrganisationStaffDetails.url}${
                        cursor ? '?cursor=' + cursor : ''
                    }`,
                    { organisationID }
                ),
                method: apiEndpoints.getOrganisationStaffDetails.method,
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ userID }) => ({
                              type: 'Staff' as const,
                              userID,
                          })),
                          'Staff',
                      ]
                    : ['Staff'];
            },
        }),
        resendOrganizationStaffInvite: builder.mutation<
            TicketResponse,
            { entityID: string }
        >({
            query: ({ entityID }) => ({
                url: generatePath(apiEndpoints.resendTicket.url, {
                    entityID,
                }),
                method: apiEndpoints.resendTicket.method,
            }),
            invalidatesTags: ['Staff'],
        }),
        revokeOrganizationStaffInvite: builder.mutation<
            TicketResponse,
            { entityID: string; entityType: string }
        >({
            query: ({ entityID, entityType }) => ({
                url: generatePath(apiEndpoints.revokeTicket.url, {
                    entityID,
                    entityType,
                }),
                method: apiEndpoints.revokeTicket.method,
            }),
            invalidatesTags: ['Staff'],
        }),
        getOrganisationStaffSingle: builder.query<
            UserResponse,
            { organisationID: string; userID: string }
        >({
            query: ({ organisationID, userID }) => ({
                url: generatePath(apiEndpoints.getOrganisationStaffSingle.url, {
                    organisationID,
                    userID,
                }),
                method: apiEndpoints.getOrganisationStaffSingle.method,
            }),
            providesTags: ['Staff'],
        }),
        getOrganisationStaffDetailsSingle: builder.query<
            UserSingleDetailsResponse,
            { organisationID: string; userID: string }
        >({
            query: ({ organisationID, userID }) => ({
                url: generatePath(
                    apiEndpoints.getOrganisationStaffDetailsSingle.url,
                    {
                        organisationID,
                        userID,
                    }
                ),
                method: apiEndpoints.getOrganisationStaffDetailsSingle.method,
            }),
            providesTags: ['Staff'],
        }),
        getOrganisationStaffInvites: builder.query({
            query: ({ organisationID }) => ({
                url: generatePath(
                    apiEndpoints.getOrganisationStaffInvites.url,
                    {
                        organisationID,
                    }
                ),
                method: apiEndpoints.getOrganisationStaffInvites.method,
            }),
        }),
        createOrganisationStaffInvite: builder.mutation({
            query: ({ organisationID, ...data }) => ({
                url: generatePath(
                    apiEndpoints.createOrganisationStaffInvite.url,
                    {
                        organisationID,
                    }
                ),
                method: apiEndpoints.createOrganisationStaffInvite.method,
                body: data,
            }),
            invalidatesTags: ['Staff'],
        }),
        deleteOrganisationStaff: builder.mutation({
            query: ({ organisationID, userID }) => ({
                url: generatePath(apiEndpoints.deleteOrganisationStaff.url, {
                    organisationID,
                    userID,
                }),
                method: apiEndpoints.deleteOrganisationStaff.method,
            }),
            invalidatesTags: ['Staff'],
        }),

        // Organisation Events
        getOrganisationEvents: builder.query({
            query: ({ organisationID }) => ({
                url: generatePath(apiEndpoints.getOrganisationEvents.url, {
                    organisationID,
                }),
                method: apiEndpoints.getOrganisationEvents.method,
            }),
        }),
        getOrganisationEvent: builder.query({
            query: ({ eventID, organisationID }) => ({
                url: generatePath(apiEndpoints.getOrganisationEvent.url, {
                    eventID,
                    organisationID,
                }),
                method: apiEndpoints.getOrganisationEvent.method,
            }),
        }),
        createOrganisationEvent: builder.mutation({
            query: ({ organisationID, data }) => ({
                url: generatePath(apiEndpoints.createOrganisationEvent.url, {
                    organisationID,
                }),
                method: apiEndpoints.createOrganisationEvent.method,
                body: data,
            }),
        }),
        updateOrganisationEvent: builder.mutation({
            query: ({ eventID, organisationID, ...data }) => ({
                url: generatePath(apiEndpoints.updateOrganisation.url, {
                    eventID,
                    organisationID,
                }),
                method: apiEndpoints.updateOrganisationEvent.method,
                body: data,
            }),
        }),
        deleteOrganisationEvent: builder.mutation({
            query: ({ eventID, organisationID }) => ({
                url: generatePath(apiEndpoints.deleteOrganisationEvent.url, {
                    eventID,
                    organisationID,
                }),
                method: apiEndpoints.deleteOrganisationEvent.method,
            }),
        }),
        upsertOrganisationEvent: builder.mutation({
            query: ({ eventID, organisationID, ...data }) => ({
                url: generatePath(
                    eventID
                        ? apiEndpoints.updateOrganisationEvent.url
                        : apiEndpoints.createOrganisationEvent.url,
                    { eventID, organisationID }
                ),
                method: eventID
                    ? apiEndpoints.updateOrganisationEvent.method
                    : apiEndpoints.createOrganisationEvent.method,
                body: data,
            }),
        }),
        getOrganisationKit: builder.query({
            query: ({ organisationID }) => ({
                url: generatePath(apiEndpoints.getOrganisationKit.url, {
                    organisationID,
                }),
                method: apiEndpoints.getOrganisationKit.method,
                responseHandler: 'text',
            }),
        }),
    }),
    tagTypes: ['Team', 'Staff'],
});

export const {
    useGetOrganisationQuery,
    useCreateOrganisationMutation,
    useUpdateOrganisationMutation,
    useDeleteOrganisationMutation,
    useUpsertOrganisationMutation,

    useGetOrganisationTeamsQuery,
    useLazyGetOrganisationTeamsQuery,
    useCreateOrganisationTeamMutation,
    useUpdateOrganisationTeamMutation,
    useDeleteOrganisationTeamMutation,
    useUpsertOrganisationTeamMutation,
    useGetOrganisationStaffQuery,
    useGetOrganisationStaffDetailsQuery,
    useLazyGetOrganisationStaffDetailsQuery,
    useGetOrganisationStaffSingleQuery,
    useGetOrganisationStaffDetailsSingleQuery,
    useGetOrganisationStaffInvitesQuery,
    useCreateOrganisationStaffInviteMutation,
    useResendOrganizationStaffInviteMutation,
    useRevokeOrganizationStaffInviteMutation,
    useDeleteOrganisationStaffMutation,

    useGetOrganisationEventsQuery,
    useLazyGetOrganisationEventsQuery,
    useGetOrganisationEventQuery,
    useCreateOrganisationEventMutation,
    useUpdateOrganisationEventMutation,
    useDeleteOrganisationEventMutation,
    useUpsertOrganisationEventMutation,

    useGetOrganisationKitQuery,
} = organisationsApi;
