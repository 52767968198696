import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { useParams } from 'react-router-dom';

import { UserForm } from '../../types/user';

import { useCreateTeamStaffInviteMutation } from '../../api/teams';
import { useCreateOrganisationStaffInviteMutation } from '../../api/organisations';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { ProgressBar } from 'primereact/progressbar';

import ButtonIcon from '../../components/ButtonIcon';
import Icon from '../../components/Icon';

import { INPUT_TYPES } from '../../types/common.d';

interface Props {
    formHasActions?: boolean;
    initialValues: UserForm;
    onCancel?: Function;
    onSubmit?: Function;
}

const requiredFields = ['email'];

const StaffForm = (props: Props) => {
    const { teamID, organisationID } = useParams();
    const { formHasActions, initialValues, onSubmit } = props;

    const useCreateStaffMutation = organisationID
        ? useCreateOrganisationStaffInviteMutation
        : useCreateTeamStaffInviteMutation;

    const [createStaff] = useCreateStaffMutation();

    const handleSubmit = (data: UserForm, helpers: FormikHelpers<UserForm>) => {
        const { setSubmitting } = helpers;

        setSubmitting(true);

        createStaff({ teamID, organisationID, ...data })
            .then((response: any) => {
                console.log('create success', response);
            })
            .catch((err: any) => {
                console.log('create error', err);
                onSubmit && onSubmit('error');
            })
            .finally(() => {
                setSubmitting(false);
                onSubmit && onSubmit('success');
            });
    };

    // TODO: this seemingly still applies email to check to any field in form ?
    const staffFormValidation = (values: FormikValues) => {
        const errors: FormikErrors<UserForm> = {};

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field as keyof UserForm] = 'Field cannot be blank';
            } else if (
                values['email'] &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values['email']
                )
            ) {
                errors[field as keyof UserForm] = 'Invalid email address';
            }
        });

        return errors;
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={staffFormValidation}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => {
                return (
                    <form className={'p-fluid form'} onSubmit={handleSubmit}>
                        <div className="form-segment">
                            <label className="form-label" htmlFor="email">
                                Email
                            </label>
                            <div
                                className={`input-wrap ${errors.email && touched.email && 'has-error'
                                    }`}
                            >
                                <InputText
                                    className="form-field"
                                    id="email"
                                    name="email"
                                    type={INPUT_TYPES.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.email || ''}
                                    required
                                    placeholder="Enter invitees email address"
                                    disabled={isSubmitting}
                                />
                                {errors.email && touched.email && (
                                    <Icon name="warning" size="small" />
                                )}
                            </div>
                            {errors.email && touched.email && (
                                <Message
                                    className="form-message form-message--error message-floated"
                                    severity="error"
                                    text={errors.email}
                                    icon={<Icon name="error" size="small" />}
                                />
                            )}
                        </div>

                        {formHasActions && (
                            <div className="form-actions">
                                <Button
                                    className="p-button-text p-button-sm p-button-secondary"
                                    disabled={isSubmitting}
                                    onClick={() =>
                                        props.onCancel && props.onCancel()
                                    }
                                    type="button"
                                >
                                    <ButtonIcon
                                        iconName="cancel"
                                        placement="left"
                                    />
                                    <span className="p-button-label">
                                        Cancel
                                    </span>
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    <ButtonIcon
                                        className={
                                            isSubmitting
                                                ? 'is-submitting'
                                                : undefined
                                        }
                                        iconName={
                                            isSubmitting ? 'autorenew' : 'send'
                                        }
                                        placement="left"
                                    />
                                    <span className="p-button-label">
                                        {isSubmitting
                                            ? 'Sending...'
                                            : 'Send Invite'}
                                    </span>
                                </Button>
                            </div>
                        )}

                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

StaffForm.defaultProps = {
    formHasActions: true,
    initialValues: {
        email: '',
    },
};

export default StaffForm;
