import { useState, useEffect, useRef, useMemo } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import {
    generatePath,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    TeamMenuItems,
    AccountMenuItems,
    OrganisationMenuItems,
    routes,
    ASSOCIATION_PARAM,
    ORGANISATION_PARAM,
    TEAM_PARAM,
    TEAM_ROUTE,
    ORGANISATION_ROUTE,
    ASSOCIATION_ROUTE,
} from '../../routes/routes';

import { useGetTeamQuery } from '../../api/teams';
import { useGetOrganisationQuery } from '../../api/organisations';

import { findMatchedRoutes } from '../../util/helper';

import { PathMatchWithRoute, Route } from '../../types/route';
import { Me } from '../../types/user';

import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';

import ButtonIcon from '../../components/ButtonIcon';
import EntitySwitcher from '../EntitySwitcher';
import Icon from '../Icon';
import { Image } from 'primereact/image';
import hubLogo from '../../assets/images/logos/rm-hub-logo.png';

// local storage keys
const LS_COLLAPSED = 'sidebarCollapsed';

const getLastEntityRouteType = (pathname: string) => {
    const entities = pathname
        .split('/')
        .filter((segment) =>
            [ASSOCIATION_ROUTE, ORGANISATION_ROUTE, TEAM_ROUTE].includes(
                segment
            )
        );

    return entities[entities.length - 1];
};

interface Props {
    user: Me;
}

const SideNav = ({ user }: Props) => {
    // Route Hooks
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    // Ref Hooks
    const nodeRef = useRef(null);
    const prevMatches = useRef<PathMatchWithRoute[]>();

    // Helpers
    const matches = useMemo<PathMatchWithRoute[]>(
        () => findMatchedRoutes(routes, location.pathname),
        [location]
    );
    let entityMatches = useMemo(
        () =>
            matches.filter((match) => {
                const paths = match.pattern.path.split('/');
                const lastPath = paths[paths.length - 1];
                const param = lastPath.startsWith(':')
                    ? lastPath.slice(1)
                    : null;

                return (
                    param &&
                    [
                        ASSOCIATION_PARAM,
                        ORGANISATION_PARAM,
                        TEAM_PARAM,
                    ].includes(param)
                );
            }),
        [matches]
    );

    entityMatches = entityMatches.sort(
        (a: PathMatchWithRoute, b: PathMatchWithRoute) =>
            b.pathname.length - a.pathname.length
    );

    const currEntity = entityMatches[0];
    const prevEntity = entityMatches[entityMatches.length - 1];

    const currEntityRoute =
        currEntity && getLastEntityRouteType(currEntity.pathname);

    // State Hooks
    const [mode, setMode] = useState('push');
    const [isCollapsed, setIsCollapsed] = useState(
        !!localStorage.getItem(LS_COLLAPSED)
    );

    // Effect Hooks
    useEffect(() => {
        if (
            prevMatches.current &&
            prevMatches.current.length !== matches.length
        ) {
            setMode(
                prevMatches.current.length < matches.length ? 'push' : 'pop'
            );
        }
    }, [matches, prevMatches]);

    useEffect(() => {
        prevMatches.current = matches;
    }, [matches]);

    // API Calls
    const {
        data: teamData,
        isLoading: isLoadingTeam,
        isError: isErrorTeam,
        error: errorTeam,
        // @ts-expect-error entity param may not exist in url
    } = useGetTeamQuery(params.teamID, {
        skip: !params.teamID || currEntityRoute !== TEAM_ROUTE,
    });

    // @ts-ignore entity param may not exist in url
    const {
        data: organisationData,
        isLoading: isLoadingOrganisation,
        isError: isErrorOrganisation,
        error: errorOrganisation,
    } = useGetOrganisationQuery(
        // @ts-expect-error entity param may not exist in url
        { organisationID: params.organisationID },
        {
            skip:
                !params.organisationID ||
                currEntityRoute !== ORGANISATION_ROUTE,
        }
    );

    const isLoading = isLoadingTeam || isLoadingOrganisation;
    const isError = isErrorTeam || isErrorOrganisation;
    const error = errorTeam || errorOrganisation;

    /**
     * @desc Stores collapse state of SideNav, storing in localStorage + state item: isCollapsed.
     * @returns UpdatedState/Storage
     */
    const handleCollapse = () => {
        setIsCollapsed((prev) => !prev);

        if (localStorage.getItem(LS_COLLAPSED) === null) {
            localStorage.setItem(LS_COLLAPSED, 'true');
        } else {
            localStorage.removeItem(LS_COLLAPSED);
        }
    };

    const getEntityName = (entityRoute: string) => {
        switch (entityRoute) {
            case ASSOCIATION_ROUTE:
                return 'Placeholder';
            case ORGANISATION_ROUTE:
                if (organisationData?.data?.organisationName) {
                    return organisationData.data.organisationName;
                }
                break;
            case TEAM_ROUTE:
                if (teamData?.data?.teamName) {
                    return teamData.data.teamName;
                }
                break;
        }
    };

    const getMenuItems = (entityRoute: string) => {
        switch (entityRoute) {
            case ASSOCIATION_ROUTE:
                return OrganisationMenuItems.map(renderMenuItem);
            case ORGANISATION_ROUTE:
                return OrganisationMenuItems.map(renderMenuItem);
            case TEAM_ROUTE:
                return TeamMenuItems.map(renderMenuItem);
            default:
                return AccountMenuItems.map(renderMenuItem);
        }
    };

    /**
     * @todo check if the user has the permission to see this menu item, if not, hide the menu item
     * @desc takes routeMap array from renderContextMenu, loops over each route and renders as menu items.
     * @param menuItem
     * @returns array
     */
    const renderMenuItem = (menuItem: Route) => {
        const { id, icon, path, title } = menuItem;

        let fullPath = path;

        if (params.organisationID && params.teamID) {
            fullPath = `/o/:organisationID${path}`;
        }

        const toPath = generatePath(fullPath, params);
        const activeClasses =
            toPath === location.pathname ? 'p-menuitem-active' : '';

        return (
            <li key={id} className={`p-menuitem ${activeClasses}`}>
                <Link className="p-menuitem-link" to={toPath}>
                    {icon && <Icon name={icon} className="p-menuitem-icon" />}
                    <span className="p-menuitem-text">{title}</span>
                </Link>
            </li>
        );
    };

    /**
     * @desc Renders SideNav menu items, dependant on entity context
     * @returns JSX
     */
    const renderMenu = () => {
        if (isLoading) {
            return (
                <>
                    {Array.from({ length: 5 }).map((value, i) => {
                        return (
                            <div
                                key={`skel-loader-${i}`}
                                className="list-item is-loading"
                            >
                                <Skeleton className="square" />
                                <Skeleton className="rectangle" />
                            </div>
                        );
                    })}
                </>
            );
        }

        if (isError) {
            if (error) {
                if ('status' in error) {
                    const errMsg =
                        'error' in error
                            ? error.error
                            : JSON.stringify(error.data);

                    return (
                        <div>
                            <div>An error has occurred:</div>
                            <div>{errMsg}</div>
                        </div>
                    );
                } else {
                    return <div>{error.message}</div>;
                }
            } else {
                return <div>An error has occurred.</div>;
            }
        }

        const menu = getMenuItems(currEntityRoute);
        const currEntName = currEntity && getEntityName(currEntityRoute);
        const isNested = entityMatches.length > 1;

        return (
            <div ref={nodeRef}>
                {isNested && (
                    <div className="side-nav_sub-head">
                        <Button
                            className="p-button-text"
                            icon={<ButtonIcon iconName="chevron_left" />}
                            onClick={() =>
                                navigate(
                                    generatePath(prevEntity.pathname, {
                                        userID: user.userID,
                                        ...params,
                                    })
                                )
                            }
                        />
                        <span>
                            {currEntName ? currEntName : currEntity.value.title}
                        </span>
                        <span />
                    </div>
                )}
                {menu && (
                    <ul className="p-tieredmenu p-component side-nav_body-items">
                        {menu}
                    </ul>
                )}
            </div>
        );
    };

    return (
        <div
            className={`side-nav${
                isCollapsed ? ' is-collapsed' : ''
            } side-nav-${mode}`}
        >
            <div className="side-nav_head">
                <EntitySwitcher />
                <Button
                    className="p-button-secondary side-nav-toggle"
                    onClick={() => handleCollapse()}
                >
                    <Icon name="chevron_left" />
                </Button>
            </div>
            <div className="side-nav_body">
                <SwitchTransition>
                    <CSSTransition
                        key={currEntity?.pathname || 'account'}
                        classNames={`panel-stack`}
                        timeout={400}
                        nodeRef={nodeRef}
                    >
                        {renderMenu()}
                    </CSSTransition>
                </SwitchTransition>
            </div>
            <div className="side-nav_foot">
                <Image src={hubLogo} height="40px" alt="Rookie Me Hub" />
            </div>
        </div>
    );
};

export default SideNav;
