import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import Icon from '../../components/Icon';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import ButtonIcon from '../../components/ButtonIcon';
import { Skeleton } from 'primereact/skeleton';
import { format } from 'date-fns';
import Modal from '../../components/Modal';

import ErrorDisplay from '../../components/ErrorDisplay';
import { ERROR_TYPES, SEVERITY } from '../../types/common.d';
import { Column, ColumnProps } from 'primereact/column';
import { Ticket } from '../../types/ticket';
import { Toast } from 'primereact/toast';

export interface Props {
    data: Ticket[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onShowRevokeDialog: () => void;
    onCloseRevokeDialog: () => void;
    showRevokeDialog: boolean;
    onRevokeUser: (userID: string) => void;
    onShowResendDialog: () => void;
    onCloseResendDialog: () => void;
    showResendDialog: boolean;
    onResendInvite: (userID: string) => void;
}

const StaffPendingView = (props: Props) => {
    const { isError, isFetching, isLoading, data, onShowResendDialog, onCloseResendDialog, showResendDialog, onResendInvite } = props;
    const navigate = useNavigate();
    const rowMenu = useRef<Menu>(null);
    const staffToast = useRef<Toast>(null);
    const [showRevokeDialog, setShowRevokeDialog] = useState(false);
    const [focusedTicket, setFocusedTicket] = useState<Ticket | null>(null);
    const [menuItems, setMenuItems] = useState<any[]>([]);

    const blankRows = Array.from({ length: 5 });

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                        {
                            command: () => navigate(0), // refresh
                            icon: 'refresh',
                            label: 'Retry',
                            severity: SEVERITY.primary,
                        },
                    ]
                    : []
            }
            alignment="middle"
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No staff found`}
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : "To get started select 'Add Staff' in the top right area."
            }
        />
    );

    
    const getTdMenuItems = (rowData: Ticket) => [
        {
            label: 'Resend Invite',
            icon: <Icon name="mail" className="p-menuitem-icon" />,
            command: () => {
                onShowResendDialog();
                setFocusedTicket(rowData);
            },
        },
        {
            label: 'Revoke Invite',
            icon: <Icon name="cancel" className="p-menuitem-icon" />,
            command: () => {
                setShowRevokeDialog(true);
                setFocusedTicket(rowData);
            },
        },
    ];
    
    const actionTemplate = (rowData: Ticket) => {
        return (
            <div className="p-buttonset">
                <Button
                    className="p-button p-button-secondary p-button-text td-action"
                    aria-label="More"
                    onClick={(e) => {
                        if (rowMenu.current) {
                            rowMenu.current.toggle(e);
                            setMenuItems(getTdMenuItems(rowData)); // Update the menu items when the button is clicked
                        }
                    }}
                >
                    <ButtonIcon
                        iconName="more_horiz"
                        placement="center"
                        size="small"
                    />
                </Button>
            </div>
        );
    };

    const columnSchema: ColumnProps[] = [
        {
            field: 'metadata.target',
            header: 'Email',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row) => {
                    // console.log(row)
                    return (
                        format(new Date(row.createdAt), 'dd/MM/yyyy')
                    )
                }
            ),
            field: 'createdAt',
            header: 'Created At',
        },
        {
            field: 'actions',
            header: '',
            body: actionTemplate,
        },
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    const resendModalContent = focusedTicket ? (
        <>
            <p className="resend-msg">
                Are you sure you want to resend this invite?
            </p>
            <div className="form-actions">
                <Button
                    className="p-button-text p-button-sm p-button-secondary"
                    onClick={onCloseResendDialog}
                    type="button"
                >
                    <ButtonIcon iconName="cancel" placement="left" />
                    <span className="p-button-label">Cancel</span>
                </Button>
                <Button
                    className={`p-button-${SEVERITY.primary}`}
                    type="submit"
                    onClick={() => {
                        // Add your resend function here
                        if (focusedTicket) {
                            console.log(`Resending to ${focusedTicket.metadata.target}`);
                            onResendInvite(focusedTicket.metadata.target);
                        }
                        onCloseResendDialog();
                    }}
                >
                    <ButtonIcon iconName="mail" placement="left" />
                    <span className="p-button-label">Resend Invite</span>
                </Button>
            </div>
        </>
    ) : <></>; 

    const revokeModalContent = focusedTicket ? (
        <>
            <p className="delete-msg">
                Are you sure you want to revoke this invite?
            </p>
            <div className="form-actions">
                <Button
                    className="p-button-text p-button-sm p-button-secondary"
                    onClick={() => setShowRevokeDialog(false)}
                    type="button"
                >
                    <ButtonIcon iconName="cancel" placement="left" />
                    <span className="p-button-label">Cancel</span>
                </Button>
                <Button
                    className={`p-button-${SEVERITY.danger}`}
                    type="submit"
                    onClick={() => {
                        // Add your revoke function here
                        console.log(focusedTicket)
                        if (focusedTicket) {
                            console.log(`Deleting ${focusedTicket.metadata.target}`);
                        }
                        setShowRevokeDialog(false);
                    }}
                >
                    <ButtonIcon iconName="delete" placement="left" />
                    <span className="p-button-label">Revoke Invite</span>
                </Button>
            </div>
        </>
    ) : <></>; // Default value when focusedTicket is null
    

    return (
        <>
            <DataTable
                value={isLoading ? blankRows : data}
                loading={isFetching && !isLoading}
                emptyMessage={tableEmptyMessage}
                responsiveLayout="scroll"
            >
                {isLoading ? columns : data.length > 0 ? columns : null}
            </DataTable>

            <Modal
                className="member-form-modal delete-modal"
                content={revokeModalContent}
                hasFooter={false}
                maximizable={false}
                onHide={() => setShowRevokeDialog(false)}
                title="Revoke Invite"
                visible={showRevokeDialog}
            />

            <Modal
                className="member-form-modal resend-modal"
                content={resendModalContent}
                hasFooter={false}
                maximizable={false}
                onHide={onCloseResendDialog}
                title="Resend Invite"
                visible={showResendDialog}
            />

            <Menu model={menuItems} popup ref={rowMenu} />
            <Toast ref={staffToast} />
        </>
    );
};

export default StaffPendingView;
