import { matchPath } from 'react-router-dom';
import { Route, PathMatchWithRoute } from '../types/route';
import { Roles } from '../types/roles';
import { addLocale } from 'primereact/api';

/**
 * Return a base url for the notes either personal notes (/notes) or team notes (/t/team_id/notes).
 * Use this to navigate back from create or edit note component.
 * @param team
 * @returns {string}
 */
export const getNotesBaseUrl = (team: string) => {
    let url = '/notes';
    if (team && team !== '') {
        url = '/t/' + team + url;
    }

    return url;
};

/**
 * @desc Get entity title from first matching entity key
 * @param roles {object}
 * @param pathname {string}
 * @returns role {object || null}
 */
export const getActiveEntity = (roles: Roles, pathname: string) => {
    const urlSegment = pathname.split('/');
    const entityID = urlSegment[2];
    return roles.find((ent) => ent.entityID === entityID);
};

/**
 * A function to join two specified paths and replace superfluous "/" symbols.
 * @param paths
 */
function joinPaths(paths: string[]): string {
    return paths.join('/').replace(/\/\/+/g, '/');
}

/**
 * A function to map through the passed in route object and return matching parent and child routes.
 * @param definitions
 * @param locationPathname
 * @param parentPath
 */
export function findMatchedRoutes(
    definitions: Route[],
    locationPathname: string,
    parentPath: string = ''
): PathMatchWithRoute[] {
    const matches: PathMatchWithRoute[] = [];

    definitions.forEach((definition, index) => {
        const value = definition;
        const definedPath = value['path'];
        const ignoredPaths = ['/', '*'];

        if (Array.isArray(definedPath)) {
            definedPath.forEach((defPath) => {
                const pathPatternWithParent = joinPaths([parentPath, defPath]);

                const match = matchPath(
                    { path: pathPatternWithParent, end: false },
                    locationPathname
                );

                if (match && !ignoredPaths.includes(defPath)) {
                    matches.push({
                        ...match,
                        parentPath,
                        value,
                    });

                    if (value['children']) {
                        const nestedMatches = findMatchedRoutes(
                            value['children'],
                            locationPathname,
                            pathPatternWithParent
                        );

                        matches.push(...nestedMatches);
                    }
                }
            });
        } else {
            const pathPatternWithParent = joinPaths([parentPath, definedPath]);

            const match = matchPath(
                { path: pathPatternWithParent, end: false },
                locationPathname
            );

            if (match && !ignoredPaths.includes(definedPath)) {
                matches.push({
                    ...match,
                    value,
                    parentPath,
                });

                if (value['children']) {
                    const nestedMatches = findMatchedRoutes(
                        value['children'],
                        locationPathname,
                        pathPatternWithParent
                    );

                    matches.push(...nestedMatches);
                }
            }
        }
    });

    return matches;
}

export const auDateLocale = () =>
    addLocale('en-au', {
        firstDayOfWeek: 1,
        dayNames: [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ],
        dayNamesShort: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
        dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        monthNamesShort: [
            'jan',
            'feb',
            'mar',
            'apr',
            'may',
            'jun',
            'jul',
            'aug',
            'sep',
            'oct',
            'nov',
            'dec',
        ],
    });

export const toISOStringWithTimezone = (date: Date | string) => {
    date = date instanceof Date ? date : new Date(date);

    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
    return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds()) +
        diff +
        pad(tzOffset / 60) +
        ':' +
        pad(tzOffset % 60)
    );
};

export const toISOString = (date: Date | string) => {
    date = date instanceof Date ? date : new Date(date);

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16);
};

export const permissionChecker = (
    pathname: string,
    roles: Roles = [],
    permission: string
) => {
    const urlSegment = pathname.split('/');
    const entityID = urlSegment[2];
    const role = roles.find((ent) => ent.entityID === entityID);

    return role && role.permissions.includes(permission);
};

export const periodSuffix = function (period: number) {
    if (period > 3 && period < 21) return 'th';
    switch (period % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

export const getFirstChars = (str: string, limit: number) => {
    const chars = str
        .split(' ')
        .map((word) => word.charAt(0))
        .join('');

    return limit ? chars.slice(0, limit) : chars;
};

export const calculateEqualGameTime = (
    periods: number,
    periodLength: number,
    maxPlayers: number,
    maxPlayersOnField: number
) => {
    const totalGameTime = periods * periodLength;

    return (
        Math.floor((totalGameTime * maxPlayersOnField) / maxPlayers) /
        totalGameTime
    );
};

export const calculatePercentageGameTime = (stats: any) => {
    if (!stats.total__time_period) {
        return null;
    }

    if (
        stats.Field &&
        stats.Bench &&
        stats.Injury &&
        stats.Assessment &&
        stats.Disciplinary &&
        stats.Opposition
    ) {
        const ttf = Number(stats.Field) + Number(stats.Bench);
        const ttp =
            Number(stats.total__time_period) -
            (Number(stats.Injury) +
                Number(stats.Assessment) +
                Number(stats.Opposition));
        return ttf / ttp;
    } else if (stats.total__time_on_field) {
        return (
            Number(stats.total__time_on_field) /
            Number(stats.total__time_period)
        );
    }
};
