import { useRef } from 'react';
import {
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { groupBy, startCase } from 'lodash';

import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
//import { OverlayPanel } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';

//import ButtonIcon from '../ButtonIcon';
import Icon from '../Icon';
import ListItem from '../ListItem';
import EntitySwitcher from '../EntitySwitcher';

import { TopMenuItems, UserMenuItems } from '../../routes/routes';

import { Me } from '../../types/user';
import { Roles } from '../../types/roles';
//import ButtonIcon from '../ButtonIcon';

//import academyIcon from '../../assets/images/logos/rm-academy-icon.png';
//import centralIcon from '../../assets/images/logos/rm-central-icon.png';
//import coachIcon from '../../assets/images/logos/rm-coach-icon.png';
//import connectIcon from '../../assets/images/logos/rm-connect-icon.png';
//import playIcon from '../../assets/images/logos/rm-play-icon.png';
//import testingIcon from '../../assets/images/logos/rm-testing-icon.png';

const ACTIVE_MENU_ITEM_CLASS = ' p-menuitem-active';

interface Props {
    user: Me;
    roles: Roles;
}

const TopNav = ({ user, roles }: Props) => {
    const params = useParams();

    // Refs
    const menuRef = useRef<Menu>(null);
    //const appsRef = useRef<OverlayPanel>(null);
    //const notificationsRef = useRef<OverlayPanel>(null);

    // Route Hooks
    const location = useLocation();
    const navigate = useNavigate();

    /**
     * @desc renders profile menu items; user chip, profile switcher and profile menu items
     * @returns Object
     */
    const renderMenu = () => {
        let userMenu: MenuItem[] = [
            {
                label: 'Profile',
                template: () => {
                    return (
                        <div
                            className="flex p-menuitem-link"
                            style={{
                                backgroundColor: 'transparent',
                                cursor: 'default',
                            }}
                        >
                            <ListItem
                                avatar={{
                                    image: user.picture,
                                    label: user.firstName
                                        ? user.firstName
                                        : user.email,
                                }}
                                proportion="compact"
                                title={
                                    user.firstName && user.lastName
                                        ? user.firstName + ' ' + user.lastName
                                        : user.email
                                }
                                caption={
                                    user.firstName && user.lastName
                                        ? user.email
                                        : ''
                                }
                            />
                        </div>
                    );
                },
            },
        ];

        UserMenuItems.forEach((item) => {
            if (item.id === 'logout') {
                userMenu.push({
                    separator: true,
                });
            }

            userMenu.push({
                label: item.title,
                icon: item.icon && (
                    <Icon name={item.icon} className="p-menuitem-icon" />
                ),
                command: () =>
                    navigate(
                        generatePath(item.path, {
                            userID: user.userID,
                            ...params,
                        })
                    ),
                className:
                    item.path === location.pathname
                        ? ACTIVE_MENU_ITEM_CLASS
                        : '',
            });
        });

        return userMenu;
    };

    /**
     * @desc Maps TopMenu route items into an array prime menu understands
     * @returns []
     */
    let menu: MenuItem[] = [];
    let entityTypes = Object.keys(groupBy(roles, 'entityType'));
    entityTypes = entityTypes.length > 0 ? entityTypes : ['teams'];

    entityTypes.forEach((entityType) => {
        menu.push({
            label: startCase(entityType),
            command: () => navigate(`/u/${user.userID}/entities/${entityType}`),
            className:
                `/u/${user.userID}/entities/${entityType}` === location.pathname
                    ? ACTIVE_MENU_ITEM_CLASS
                    : '',
        });
    });

    TopMenuItems.forEach((item) => {
        menu.push({
            label: item.title,
            command: () =>
                navigate(
                    generatePath(item.path, {
                        userID: user.userID,
                        ...params,
                    })
                ),
            className:
                item.path === location.pathname ? ACTIVE_MENU_ITEM_CLASS : '',
        });
    });

    menu.push({
        label: 'Help',
        command: () => {
            window.open('https://hub.help.rookieme.com', '_blank');
            return null;
        },
    });

    /**
     * @desc sets objects to be displayed in right side area of TopNav
     * @returns JSX
     */

    const menuEnd = (
        <ul className="p-menubar-root-list">
            {/*
            <li className="p-menuitem">
                <Button
                    className="p-button-text p-button-icon-only p-button-plain"
                    icon={<ButtonIcon iconName="apps" placement="left" />}
                    onClick={(e) =>
                        appsRef.current && appsRef.current.toggle(e)
                    }
                    aria-controls="apps-menu"
                    aria-haspopup
                />
                <OverlayPanel id="apps-menu" ref={appsRef}>
                    <div className="product-grid">
                        <ul className="product-grid_list">
                            <li className="product-grid_item">
                                <a
                                    href="/"
                                    target="_blank"
                                    className="product-grid_link"
                                >
                                    <Avatar
                                        image={coachIcon}
                                        imageAlt="Rookie Me Coach"
                                        size="large"
                                        label="C"
                                    />
                                    <span className="product-grid_title">
                                        Coach
                                    </span>
                                </a>
                            </li>
                            <li className="product-grid_item">
                                <a
                                    href="/"
                                    target="_blank"
                                    className="product-grid_link"
                                >
                                    <Avatar
                                        image={playIcon}
                                        imageAlt="Rookie Me Play"
                                        size="large"
                                        label="P"
                                    />
                                    <span className="product-grid_title">
                                        Play
                                    </span>
                                </a>
                            </li>
                            <li className="product-grid_item">
                                <a
                                    href="/"
                                    target="_blank"
                                    className="product-grid_link"
                                >
                                    <Avatar
                                        image={connectIcon}
                                        size="large"
                                        imageAlt="Rookie Me Connect"
                                        label="C"
                                    />
                                    <span className="product-grid_title">
                                        Connect
                                    </span>
                                </a>
                            </li>
                            <li className="product-grid_item">
                                <a
                                    href="/"
                                    target="_blank"
                                    className="product-grid_link"
                                >
                                    <Avatar
                                        image={academyIcon}
                                        imageAlt="Rookie Me Academy"
                                        size="large"
                                        label="A"
                                    />
                                    <span className="product-grid_title">
                                        Academy
                                    </span>
                                </a>
                            </li>
                            <li className="product-grid_item">
                                <a
                                    href="/"
                                    target="_blank"
                                    className="product-grid_link"
                                >
                                    <Avatar
                                        image={testingIcon}
                                        imageAlt="Rookie Me Connect"
                                        size="large"
                                        label="T"
                                    />
                                    <span className="product-grid_title">
                                        Testing
                                    </span>
                                </a>
                            </li>

                            <li className="product-grid_item">
                                <a
                                    href="/"
                                    target="_blank"
                                    className="product-grid_link"
                                >
                                    <Avatar
                                        image={centralIcon}
                                        imageAlt="Rookie Me Central"
                                        size="large"
                                        label="C"
                                    />
                                    <span className="product-grid_title">
                                        Central
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </OverlayPanel>
            </li>
            <li className="p-menuitem">
                <Button
                    className="p-button-text p-button-icon-only p-button-plain"
                    icon={
                        <ButtonIcon iconName="notifications" placement="left" />
                    }
                    onClick={(e) =>
                        notificationsRef.current &&
                        notificationsRef.current.toggle(e)
                    }
                    aria-controls="notifications-menu"
                    aria-haspopup
                />
                <OverlayPanel id="notifications-menu" ref={notificationsRef}>
                    <div>Notifications</div>
                </OverlayPanel>
            </li>
            */}
            <li className="p-menuitem">
                <Button
                    className="p-button-text p-button-plain"
                    onClick={(e) =>
                        menuRef.current && menuRef.current.toggle(e)
                    }
                    aria-controls="user-menu"
                    aria-haspopup
                >
                    <Avatar
                        label={
                            user.firstName
                                ? user.firstName?.substring(0, 1)
                                : user.email.substring(0, 1)
                        }
                        shape="circle"
                        image={user.picture}
                    />
                </Button>
                <TieredMenu
                    id="user-menu"
                    ref={menuRef}
                    model={renderMenu()}
                    popup
                    style={{ width: 'unset', minWidth: '250px' }}
                />
            </li>
        </ul>
    );

    return (
        <Menubar
            className="top-nav"
            model={menu}
            start={
                !params.teamID && !params.organisationID && <EntitySwitcher />
            }
            end={menuEnd}
        />
    );
};

export default TopNav;
