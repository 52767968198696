import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import {
    Sports,
    Sport,
    StatConfig,
    EntityCategory,
    StatConfigResponse,
} from '../types/sports';
import { generatePath } from 'react-router-dom';

export const sportsApi = createApi({
    reducerPath: 'sportsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getSports: builder.query<Sports, void>({
            query: () => ({
                url: apiEndpoints.getSports.url,
                method: apiEndpoints.getSports.method,
            }),
        }),
        getSport: builder.query<Sport, { sportID: string }>({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getSport.url, { sportID }),
                method: apiEndpoints.getSport.method,
            }),
        }),
        getStats: builder.query<StatConfigResponse, { sportID: string }>({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getStats.url, { sportID }),
                method: apiEndpoints.getStats.method,
            }),
        }),
        getStat: builder.query<StatConfig, { sportID: string; statID: string }>(
            {
                query: ({ sportID, statID }) => ({
                    url: generatePath(apiEndpoints.getStat.url, {
                        sportID,
                        statID,
                    }),
                    method: apiEndpoints.getStat.method,
                }),
            }
        ),
        getEntityCategories: builder.query<
            EntityCategory[],
            { sportID: string }
        >({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getEntityCategories.url, {
                    sportID,
                }),
                method: apiEndpoints.getEntityCategories.method,
            }),
        }),
        getEntityCategory: builder.query<
            EntityCategory,
            { sportID: string; entityCategoryID: string }
        >({
            query: ({ sportID, entityCategoryID }) => ({
                url: generatePath(apiEndpoints.getEntityCategory.url, {
                    sportID,
                    entityCategoryID,
                }),
                method: apiEndpoints.getEntityCategory.method,
            }),
        }),
    }),
});

export const {
    useGetSportsQuery,
    useGetSportQuery,
    useGetStatsQuery,
    useGetStatQuery,
    useGetEntityCategoriesQuery,
    useGetEntityCategoryQuery,
} = sportsApi;
