import { sec } from '../util/security';

export const apiEndpoints = {
    // Billing
    createBillingSession: {
        url: '/profile/billing',
        method: 'POST',
    },

    // Account
    getMe: {
        url: '/me',
        method: 'GET',
    },
    updateProfile: {
        url: '/profile',
        method: 'POST',
    },
    verifyEmail: {
        url: '/profile/verifyEmail',
        method: 'POST',
    },
    getRoles: {
        url: '/users/:userID/roles',
        method: 'GET',
    },

    // Sports
    getSports: {
        url: '/sports',
        method: 'GET',
    },
    getSport: {
        url: '/sports/:sportID',
        method: 'GET',
    },

    // Stats
    getStats: {
        url: '/sports/:sportID/stats',
        method: 'GET',
    },
    getStat: {
        url: '/sports/:sportID/stats/:statID',
        method: 'GET',
    },

    // Entity Categories
    getEntityCategories: {
        url: '/sports/:sportID/entityCategories',
        method: 'GET',
    },
    getEntityCategory: {
        url: '/sports/:sportID/entityCategories/:entityCategoryID',
        method: 'GET',
    },

    // Notes
    getTeamNotes: {
        url: '/notes/teams/:teamID',
        method: 'GET',
    },
    getNotes: {
        url: '/notes',
        method: 'GET',
    },
    createNote: {
        url: '/notes',
        method: 'POST',
    },
    updateNote: {
        url: '/notes/:noteID',
        method: 'PUT',
    },
    deleteNote: {
        url: '/notes/:noteID',
        method: 'DELETE',
    },
    getNoteLabels: {
        url: '/notes/:noteID/labels',
        method: 'GET',
    },
    getNoteNodes: {
        url: '/notes/:noteID/nodes',
        method: 'GET',
    },

    // Teams
    getTeams: {
        url: '/users/:userID/teams',
        method: 'GET',
    },
    getTeam: {
        url: '/teams/:teamID',
        method: 'GET',
    },
    createTeam: {
        url: '/users/:userID/teams',
        method: 'POST',
    },
    updateTeam: {
        url: '/teams/:teamID',
        method: 'PUT',
    },
    deleteTeam: {
        url: '/teams/:teamID',
        method: 'DELETE',
    },

    // Team Staff
    getTeamStaff: {
        url: '/teams/:teamID/staff',
        method: 'GET',
    },
    getTeamStaffDetails: {
        url: '/teams/:teamID/staffDetails',
        method: 'GET',
    },
    getTeamStaffSingle: {
        url: '/teams/:teamID/staff/:userID',
        method: 'GET',
    },
    getTeamStaffDetailsSingle: {
        url: '/teams/:teamID/staff/:userID/details',
        method: 'GET',
    },
    getTeamStaffInvites: {
        url: '/teams/:teamID/staffInvites',
        method: 'GET',
    },
    createTeamStaffInvite: {
        url: '/teams/:teamID/staffInvites',
        method: 'POST',
    },
    createTeamStaff: {
        url: '/teams/:teamID/staff',
        method: 'POST',
    },
    deleteTeamStaff: {
        url: '/teams/:teamID/staff/:userID',
        method: 'DELETE',
    },

    // Team Players
    getPlayers: {
        url: '/teams/:teamID/players',
        method: 'GET',
    },
    getPlayer: {
        url: '/teams/:teamID/players/:playerID',
        method: 'GET',
    },
    getPlayersByUser: {
        url: '/users/:userID/players',
        method: 'GET',
    },
    createPlayer: {
        url: '/teams/:teamID/players',
        method: 'POST',
    },
    updatePlayer: {
        url: '/teams/:teamID/players/:playerID',
        method: 'PUT',
    },
    deletePlayer: {
        url: '/teams/:teamID/players/:playerID',
        method: 'DELETE',
    },
    linkPlayerToUser: {
        url: '/teams/:teamID/players/:playerID/user',
        method: 'POST',
    },
    unlinkPlayerFromUser: {
        url: '/teams/:teamID/players/:playerID/user',
        method: 'DELETE',
    },

    // Team Events
    getTeamEvents: {
        url: '/teams/:teamID/events',
        method: 'GET',
    },
    getTeamEvent: {
        url: '/teams/:teamID/events/:eventID',
        method: 'GET',
    },
    createTeamEvent: {
        url: '/teams/:teamID/events',
        method: 'POST',
    },
    updateTeamEvent: {
        url: '/teams/:teamID/events/:eventID',
        method: 'PUT',
    },
    deleteTeamEvent: {
        url: '/teams/:teamID/events/:eventID',
        method: 'DELETE',
    },

    // Team Game Stats
    getTeamGameStats: {
        url: '/teams/:teamID/games/:eventID/stats',
        method: 'GET',
    },
    getTeamGameTimeStats: {
        url: '/teams/:teamID/games/:eventID/timeStats',
        method: 'GET',
    },

    // Team Kit
    getTeamKit: {
        url: '/teams/:teamID/kit',
        method: 'GET',
    },
    updateTeamKit: {
        url: '/teams/:teamID/kit',
        method: 'PUT',
    },
    getDesignOptions: {
        url: '/design',
        method: 'GET',
    },

    // Organisations
    getOrganisation: {
        url: '/organisations/:organisationID',
        method: 'GET',
    },
    getOrganisationTeams: {
        url: '/organisations/:organisationID/teams',
        method: 'GET',
    },
    createOrganisation: {
        url: '/users/:userID/organisations',
        method: 'POST',
    },
    createOrganisationTeam: {
        url: '/organisations/:organisationID/teams',
        method: 'POST',
    },
    updateOrganisation: {
        url: '/organisations/:organisationID',
        method: 'PUT',
    },
    updateOrganisationTeam: {
        url: '/organisations/:organisationID/teams/:teamID',
        method: 'PUT',
    },
    deleteOrganisation: {
        url: '/organisations/:organisationID',
        method: 'DELETE',
    },
    deleteOrganisationTeam: {
        url: '/organisations/:organisationID/teams/:teamID',
        method: 'DELETE',
    },
    getOrganisationKit: {
        url: '/organisations/:organisationID/kit',
        method: 'GET',
    },

    // Organisation Staff
    getOrganisationStaff: {
        url: '/organisations/:organisationID/staff',
        method: 'GET',
    },
    getOrganisationStaffDetails: {
        url: '/organisations/:organisationID/staffDetails',
        method: 'GET',
    },
    getOrganisationStaffSingle: {
        url: '/organisations/:organisationID/staff/:userID',
        method: 'GET',
    },
    getOrganisationStaffDetailsSingle: {
        url: '/organisations/:organisationID/staff/:userID/details',
        method: 'GET',
    },
    getOrganisationStaffInvites: {
        url: '/organisations/:organisationID/staffInvites',
        method: 'GET',
    },
    createOrganisationStaffInvite: {
        url: '/organisations/:organisationID/staffInvites',
        method: 'POST',
    },
    deleteOrganisationStaff: {
        url: '/organisations/:organisationID/staff/:userID',
        method: 'DELETE',
    },

    // Organisation Events
    getOrganisationEvents: {
        url: '/organisations/:organisationID/events',
        method: 'GET',
    },
    getOrganisationEvent: {
        url: '/organisations/:organisationID/events/:eventID',
        method: 'GET',
    },
    createOrganisationEvent: {
        url: '/organisations/:organisationID/events',
        method: 'POST',
    },
    updateOrganisationEvent: {
        url: '/organisations/:organisationID/events/:eventID',
        method: 'PUT',
    },
    deleteOrganisationEvent: {
        url: '/organisations/:organisationID/events/:eventID',
        method: 'DELETE',
    },

    // Events
    getEvent: {
        url: '/users/:userID/events/:eventID',
        method: 'GET',
    },
    getEvents: {
        url: '/users/:userID/events',
        method: 'GET',
    },
    createEvent: {
        url: '/users/:userID/events',
        method: 'POST',
    },
    updateEvent: {
        url: '/users/:userID/events/:eventID',
        method: 'PUT',
    },
    deleteEvent: {
        url: '/users/:userID/events/:eventID',
        method: 'DELETE',
    },

    // Tickets
    revokeTicket: {
        url: '/tickets/:entityID/revoke',
        method: 'PUT',
    },
    resendTicket: {
        url: '/tickets/:entityID/revoke',
        method: 'PUT',
    },
};

export const prepareHeaders = async (headers: Headers, api: any) => {
    const { id_token } = await sec.getAccessTokenSilently()({
        detailedResponse: true,
    });

    if (id_token && !api.getTeamGameStats) {
        headers.set('Authorization', `Bearer ${id_token}`);
    }

    return headers;
};
