import { useMemo, useRef, useState } from 'react';
import { sortBy } from 'lodash';

import { DataTable } from 'primereact/datatable';
import { Column, ColumnProps } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { ToggleButton } from 'primereact/togglebutton';

import { periodSuffix } from '../../util/helper';

import { UseQueryResult } from '../../types/api';
import { EventResponse } from '../../types/event';
import {
    Stat,
    StatResponse,
    TimeStat,
    TimeStatResponse,
} from '../../types/game';
import { PlayerResponse, TeamResponse } from '../../types/team';
import { StatConfig, StatConfigResponse } from '../../types/sports';
import EntityAvatar from '../../components/EntityAvatar';
import { ERROR_TYPES, EntityType } from '../../types/common.d';
import ErrorDisplay from '../../components/ErrorDisplay';
import { Image } from 'primereact/image';

import playLogo from '../../assets/images/logos/rm-play-logo.png';

interface Props {
    eventData: UseQueryResult<EventResponse>;
    timeStatsData: UseQueryResult<TimeStatResponse>;
    gameStatsData: UseQueryResult<StatResponse>;
    playerData: UseQueryResult<PlayerResponse>;
    teamData: UseQueryResult<TeamResponse>;
    statsConfigData: UseQueryResult<StatConfigResponse>;
}

interface CalculatedStat {
    statID: string;
    team: number;
    opponent: number;
    stat?: StatConfig;
}

interface CalculatedStats {
    [key: string]: CalculatedStat;
}

const EventTeams = (props: Props) => {
    const {
        eventData,
        gameStatsData,
        teamData,
        timeStatsData,
        statsConfigData,
    } = props;

    const [activePeriod, setPeriodFilter] = useState<number>(0);
    const dataTable = useRef<DataTable>(null);

    const event = eventData?.data?.data;
    const team = teamData?.data?.data;
    const timeStats = timeStatsData?.data?.value;
    const statsConfig = statsConfigData?.data?.data;
    const gameSettings = event?.game?.settings;
    const totalPeriods =
        gameSettings && gameSettings.periods && Number(gameSettings.periods) > 0
            ? gameSettings.periods
            : timeStats &&
              Math.max(...timeStats.map((o: TimeStat) => o.period));

    let gameStats = gameStatsData?.data?.data;

    // filter by period
    if (activePeriod) {
        gameStats =
            gameStats &&
            gameStats.map((stat) =>
                Number(stat.period) === activePeriod
                    ? stat
                    : { ...stat, value: 0 }
            );
    }

    const calculatedStats = useMemo(
        () =>
            gameStats?.reduce(
                (acc: CalculatedStats, { statID, targetID, value }: Stat) => {
                    const teamKey = targetID.includes('opponent')
                        ? 'opponent'
                        : 'team';

                    acc[statID] = acc[statID] || { statID };
                    acc[statID].stat = statsConfig?.find(
                        (stat) => stat.statID === statID
                    );

                    acc[statID][teamKey] =
                        acc[statID][teamKey] + value || value;

                    return acc;
                },
                {}
            ),
        [gameStats, statsConfig]
    );

    if (gameStatsData.error || statsConfigData.error) {
        return (
            <ErrorDisplay
                title="No data was returned"
                desc="We couldn't fulfil the request for you, try again or contact account manager."
                errorType={ERROR_TYPES.notFound}
            />
        );
    }

    /*
    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();
    };
    */

    const renderTeamHeader = (isOpponent: boolean = false) => {
        if (event && event.game && team) {
            if (isOpponent) {
                return (
                    <div className="detail-cell">
                        <EntityAvatar
                            entityType={EntityType.teams}
                            entityID={event.game.opponentTeamID}
                            entityName={
                                event.game.opponentTeam?.teamName || 'Opponent'
                            }
                        />
                        <span>
                            {event.game.opponentTeam?.shortName || 'OPP'}
                        </span>
                    </div>
                );
            } else {
                return (
                    team && (
                        <div className="detail-cell">
                            <EntityAvatar
                                entityType={EntityType.teams}
                                entityID={team.teamID}
                                entityName={team.shortName}
                            />
                            <span>{team.teamName}</span>
                        </div>
                    )
                );
            }
        }
        return null;
    };

    const columns: ColumnProps[] = [
        {
            field: 'stat.statName',
            header: 'Stat',
            sortable: true,
            style: { width: '300px' },
            body: (row: CalculatedStat) =>
                row.stat ? row.stat.statName : `Unknown (${row.statID})`,
        },
        {
            field: 'team',
            sortable: true,
            style: { width: '120px' },
            align: 'right',
            alignHeader: 'right',
            body: (row: CalculatedStat) => row.team || 0,
            header: () => renderTeamHeader(),
            exportField: 'team',
        },
        {
            field: 'opponent',
            sortable: true,
            style: { width: '120px' },
            align: 'right',
            alignHeader: 'right',
            body: (row: CalculatedStat) => row.opponent || 0,
            header: () => renderTeamHeader(true),
            exportField: 'opponent',
        },
    ];

    const tableHeader = (
        <Toolbar
            left={
                <div className="p-buttonset">
                    <ToggleButton
                        checked={!activePeriod}
                        onChange={() => setPeriodFilter(0)}
                        onLabel="All"
                        offLabel="All"
                    />

                    {[...Array(totalPeriods)].map((u, index) => {
                        const period = index + 1;
                        const label = `${period}${periodSuffix(
                            Number(period)
                        )}`;
                        return (
                            <ToggleButton
                                key={label}
                                checked={activePeriod === period}
                                onChange={() => setPeriodFilter(period)}
                                onLabel={label}
                                offLabel={label}
                            />
                        );
                    })}
                </div>
            }
            /*
            right={
                <Button
                    type="button"
                    onClick={() => exportCSV()}
                    label="Export CSV"
                    className="p-button-secondary"
                />
            }
            */
        />
    );

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    return (
        <DataTable
            ref={dataTable}
            header={tableHeader}
            footer={calculatedStats && tableFooter}
            value={
                calculatedStats &&
                sortBy(
                    Object.values(calculatedStats),
                    (stat) => stat.stat?.statSortOrder
                )
            }
        >
            {columns.map((col) => (
                <Column key={col.field} {...col} />
            ))}
        </DataTable>
    );
};

export default EventTeams;
