import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
    useGetPlayersQuery,
    useLazyGetPlayersQuery,
    useUpdatePlayerMutation,
} from '../../api/teams';
import PlayersView from './PlayersView';
import DocumentHead from '../../components/DocumentHead';

import { Route } from '../../types/route';
import { Roles } from '../../types/roles';
import { Player } from '../../types/team';

interface Props {
    roles: Roles;
    route: Route;
}

const PlayersContainer = (props: Props) => {
    const { teamID } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [focusedUser, setFocusedUser] = useState<Player | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const [refetch] = useLazyGetPlayersQuery();

    const { data, isLoading, isError, isFetching } = useGetPlayersQuery(
        {
            teamID: teamID ? teamID : '',
            cursor: '',
        },
        {
            skip: !teamID,
        }
    );

    const [updatePlayer] = useUpdatePlayerMutation();

    const handleShowInvite = (isCreate: boolean) => {
        if (searchParams.get('id')) {
            setSearchParams((params) => {
                params.delete('id');
                return params;
            });
        }
        if (isCreate) {
            setFocusedUser(null);
        }
        setSearchParams((params) => {
            params.set('create', 'add');
            return params;
        });
    };

    const handleCloseInvite = () => {
        if (searchParams.get('create')) {
            setSearchParams((params) => {
                params.delete('create');
                return params;
            });
        }
    };

    const handleShowDelete = () => {
        setShowDeleteDialog(true);
    };

    const handleCloseDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteUser = (playerID: string) => {
        console.log('DELETE', playerID);

        updatePlayer({ playerID, teamID, status: 'Archived' })
            .then((response) => {
                console.log('DELETE SUCCESS', response);
            })
            .catch((err) => {
                console.log('DELETE ERROR', err);
            })
            .finally(() => {
                setShowDeleteDialog(false);
            });
    };

    const handleClickUser = (playerID: string) => {
        if (searchParams.get('create')) {
            setSearchParams((params) => {
                params.delete('create');
                return params;
            });
        }
        setSearchParams((params) => {
            params.set('id', playerID);
            return params;
        });
    };

    const handleCloseDrawer = () => {
        if (searchParams.get('id')) {
            setSearchParams((params) => {
                params.delete('id');
                return params;
            });
        } else {
            setSearchParams((params) => {
                params.delete('create');
                return params;
            });
        }
    };

    const handleViewUser = (playerID: string) => {
        console.log('VIEW', playerID);
    };

    const handleFocusUser = (player: Player) => {
        setFocusedUser(player);
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey && teamID) {
            const { lastEvaluatedKey } = data;
            if (lastEvaluatedKey && lastEvaluatedKey.cursor) {
                refetch({
                    teamID,
                    cursor: lastEvaluatedKey.cursor,
                });
            }
        }
    };

    return (
        <>
            <DocumentHead
                title="Players - Rookie Me Hub"
                description="Players management page"
            />
            <PlayersView
                data={data && data.data ? data.data : []}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                showDeleteDialog={showDeleteDialog}
                focusedUser={focusedUser}
                onLoadMore={handleLoadMore}
                onCloseDeleteDialog={handleCloseDelete}
                onCloseInviteDialog={handleCloseInvite}
                onShowInviteDialog={handleShowInvite}
                onShowDeleteDialog={handleShowDelete}
                onClickUser={handleClickUser}
                onDeleteUser={handleDeleteUser}
                onViewUser={handleViewUser}
                onFocusUser={handleFocusUser}
                onCloseDrawer={handleCloseDrawer}
                roles={props.roles}
                showPagination={!!data?.lastEvaluatedKey?.cursor}
            />
        </>
    );
};

export default PlayersContainer;
