import { Outlet } from 'react-router-dom';
import { isArray } from 'lodash';

// Pages

import Account from './Account';
import Dashboard from './Dashboard';
import LogOut from './LogOut';
import { Route } from '../types/route';
import UserEventsContainer from '../pages/events/UserEventsContainer';
import StaffContainer from '../pages/users/StaffContainer';
import PlayersContainer from '../pages/users/PlayersContainer';
import TeamsImport from '../pages/teams/TeamsImport';
import TeamListingContainer from '../pages/teams/TeamListingContainer';
import EntityListingContainer from '../pages/teams/EntityListingContainer';
import PlayerImport from '../pages/users/PlayerImport';
import OrganisationsImport from '../pages/organisations/OrganisationsImport';
import TeamHome from '../pages/teams/TeamHome';
import OrganisationHome from '../pages/organisations/OrganisationHome';
import UserHome from '../pages/users/UserHome';
import TeamSettings from '../pages/teams/TeamSettings';
import OrganisationSettings from '../pages/organisations/OrganisationSettings';
import TeamForm from '../pages/teams/TeamForm';
import OrganisationEventsContainer from '../pages/events/OrganisationEventsContainer';
import TeamEventsContainer from '../pages/events/TeamEventsContainer';
import EventsSingleContainer from '../pages/events/EventSingleContainer';

// Helpers

export const ASSOCIATION_ROUTE = 'a';
export const ASSOCIATION_PARAM = 'associationID';
export const ORGANISATION_ROUTE = 'o';
export const ORGANISATION_PARAM = 'organisationID';
export const TEAM_ROUTE = 't';
export const TEAM_PARAM = 'teamID';

interface MapType {
    [roleName: string]: {
        urlSegment: string;
        urlParam: string;
    };
}

export const entityMap: MapType = {
    associations: {
        urlSegment: ASSOCIATION_ROUTE,
        urlParam: 'ASSOCIATION_PARAM',
    },
    organisations: {
        urlSegment: ORGANISATION_ROUTE,
        urlParam: ORGANISATION_PARAM,
    },
    teams: {
        urlSegment: TEAM_ROUTE,
        urlParam: TEAM_PARAM,
    },
};

// Routes

const globalRoutes = [
    {
        id: 'root',
        title: 'Home',
        path: '/',
        permissions: [],
        component: UserHome,
    },
    {
        id: 'logout',
        title: 'Log Out',
        path: '/logout',
        permissions: [],
        component: LogOut,
    },
];

const userRoutes = [
    {
        id: 'user-home',
        title: 'Home',
        path: '/u/:userID',
        permissions: [],
        component: UserHome,
    },
    {
        id: 'user-entities',
        title: 'Entities',
        path: '/u/:userID/entities/*',
        permissions: [],
        component: EntityListingContainer,
    },
    {
        id: 'user-events',
        title: 'Events',
        path: '/u/:userID/events',
        permissions: [],
        component: UserEventsContainer,
    },
    {
        id: 'user-account',
        title: 'Account',
        path: '/u/:userID/account',
        permissions: [],
        component: Outlet,
    },
    {
        id: 'user-profile',
        title: 'Profile',
        path: '/u/:userID/profile',
        permissions: [],
        component: Account,
    },
    {
        id: 'user-security',
        title: 'Security',
        path: '/u/:userID/security',
        permissions: [],
        component: Dashboard,
    },
    {
        id: 'user-payments',
        title: 'Payments',
        path: '/u/:userID/payments',
        permissions: [],
        component: Dashboard,
    },
    {
        id: 'user-team-import',
        title: 'Team',
        path: '/u/:userID/teams/import',
        permissions: [],
        component: TeamsImport,
    },
    {
        id: 'user-organisation-import',
        title: 'Organisations',
        path: '/u/:userID/organisations/import',
        permissions: [],
        component: OrganisationsImport,
    },
];

const teamRoutes = (idPrefix = '', urlPrefix = '') => [
    {
        id: `${idPrefix}team-home`,
        path: `${urlPrefix}/t/:teamID`,
        permissions: [],
        icon: 'home',
        title: 'Team',
        component: TeamHome,
    },
    {
        id: `${idPrefix}team-events`,
        path: `${urlPrefix}/t/:teamID/events/:tab?`,
        icon: 'event',
        title: 'Events',
        permissions: [],
        component: TeamEventsContainer,
    },
    {
        id: `${idPrefix}team-event`,
        path: `${urlPrefix}/t/:teamID/event/:eventID/:tab?`,
        icon: 'event',
        title: 'Events',
        permissions: [],
        component: EventsSingleContainer,
    },
    {
        id: `${idPrefix}team-staff`,
        path: `${urlPrefix}/t/:teamID/staff/:tab?`,
        icon: 'manage_accounts',
        title: 'Staff',
        permissions: [],
        component: StaffContainer,
    },
    {
        id: `${idPrefix}team-players`,
        path: `${urlPrefix}/t/:teamID/players`,
        title: 'Players',
        icon: 'group',
        permissions: [],
        component: PlayersContainer,
    },
    {
        id: `${idPrefix}team-player-import`,
        path: `${urlPrefix}/t/:teamID/players/import`,
        icon: 'upload',
        title: 'Import',
        permissions: [],
        component: PlayerImport,
    },
    /*
    {
        id: `${idPrefix}team-licences`,
        path: `${urlPrefix}/t/:teamID/licences`,
        icon: 'key',
        title: 'Licences',
        permissions: [],
        component: Dashboard,
    },
    */
    {
        id: `${idPrefix}team-settings`,
        path: `${urlPrefix}/t/:teamID/settings`,
        icon: 'settings',
        title: 'Settings',
        permissions: [],
        component: TeamSettings,
    },
];

const organisationRoutes = (idPrefix = '', urlPrefix = '') => [
    {
        id: `${idPrefix}organisation-home`,
        path: `${urlPrefix}/o/:organisationID`,
        icon: 'home',
        title: 'Organisation',
        permissions: [],
        component: OrganisationHome,
    },
    {
        id: `${idPrefix}organisation-events`,
        path: `${urlPrefix}/o/:organisationID/events/:tab?`,
        icon: 'event',
        title: 'Events',
        permissions: [],
        component: OrganisationEventsContainer,
    },
    {
        id: `${idPrefix}organisation-staff`,
        path: `${urlPrefix}/o/:organisationID/staff/:tab?`,
        icon: 'manage_accounts',
        title: 'Staff',
        permissions: [],
        component: StaffContainer,
    },
    {
        id: `${idPrefix}organisation-teams`,
        path: `${urlPrefix}/o/:organisationID/teams`,
        icon: 'group',
        title: 'Teams',
        permissions: [],
        component: TeamListingContainer,
    },
    {
        id: `${idPrefix}organisation-team-create`,
        path: `${urlPrefix}/o/:organisationID/teams/new`,
        icon: 'add',
        title: 'Teams',
        permissions: [],
        component: TeamForm,
    },
    {
        id: `${idPrefix}organisation-team-import`,
        path: `${urlPrefix}/o/:organisationID/teams/import`,
        icon: 'upload',
        title: 'Import',
        permissions: [],
        component: TeamsImport,
    },
    /*
    {
        id: `${idPrefix}organisation-licences`,
        path: `${urlPrefix}/o/:organisationID/licences`,
        icon: 'key',
        title: 'Licences',
        permissions: [],
        component: Dashboard,
    },
    */
    {
        id: `${idPrefix}organisation-settings`,
        path: `${urlPrefix}/o/:organisationID/settings`,
        icon: 'settings',
        title: 'Settings',
        permissions: [],
        component: OrganisationSettings,
    },
];

export const routes = [
    ...globalRoutes,
    ...userRoutes,
    ...teamRoutes(),
    ...organisationRoutes(),
    ...teamRoutes('organisation-', '/o/:organisationID'),
];

const flattenArray = (arr: Route[]) =>
    arr.reduce((result: Route[], val: Route) => {
        result.push(val);

        const prevPath = isArray(val.path) ? val.path[0] : val.path;

        if (val.children) {
            result.push(
                ...flattenArray(
                    val.children.map((child) => ({
                        ...child,
                        path: `${prevPath}/${child.path}`,
                    }))
                )
            );
        }

        return result;
    }, []);

const flattenedRoutes = flattenArray(routes);

const getRoutesByID = (ids: string[]) =>
    flattenedRoutes
        .filter((route) => ids.includes(route.id))
        .sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));

export const getRouteByID = (id: string) =>
    flattenedRoutes.find((route) => id === route.id);

// Menus

export const TopMenuItems = getRoutesByID(['user-events', 'help']);

export const OrganisationMenuItems = getRoutesByID([
    'organisation-teams',
    'organisation-events',
    'organisation-staff',
    //'organisation-licences',
    'organisation-settings',
]);

export const TeamMenuItems = getRoutesByID([
    'team-events',
    'team-staff',
    'team-players',
    //'team-licences',
    'team-settings',
]);

export const UserMenuItems = getRoutesByID([
    'user-profile',
    'user-account',
    'logout',
]);

export const AccountMenuItems = getRoutesByID([
    'user-profile',
    'user-account',
    'logout',
]);
