import React from 'react';
import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { useParams } from 'react-router-dom';
import { pick } from 'lodash';
import { useUpsertPlayerMutation } from '../../api/teams';
import { auDateLocale } from '../../util/helper';

import Icon from '../../components/Icon';
import ButtonIcon from '../../components/ButtonIcon';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';

import { ACTION_TYPES, INPUT_TYPES } from '../../types/common.d';
import { PlayerForm as PlayerFormTypes, Player } from '../../types/team.d';
import { ColourOptions } from '../../types/design.d';

interface Props {
    formHasActions?: boolean;
    initialValues: PlayerFormTypes;
    playerID?: Player['playerID'];
    onCancel?: Function;
    onSubmit?: Function;
}

const requiredFields = [
    'gender',
    'firstName',
    'lastName',
    'displayName',
    'uniformNumber',
    'dateOfBirth',
];

// set colour options, pull out keys for mapping
const colourOptions = pick(ColourOptions, [
    'Black',
    'Green',
    'Teal',
    'Maroon',
    'Yellow',
    'VegasGold',
    'White',
]);
const colourKeys = Object.values(colourOptions);

const PlayerForm = (props: Props) => {
    const { teamID } = useParams();
    const { formHasActions, initialValues, onSubmit, playerID } = props;
    const [upsertPlayer] = useUpsertPlayerMutation();

    // init au locale + set DOB input limits.
    auDateLocale();
    const playerDOBMax = new Date();
    const playerDOBMin = new Date();
    playerDOBMin.setFullYear(playerDOBMin.getFullYear() - 100);

    const handleSubmit = (
        data: PlayerFormTypes,
        { resetForm, setSubmitting }: FormikHelpers<PlayerFormTypes>
    ) => {
        let formData = {
            ...data,
            uniformNumber: data.uniformNumber.toString(),
            dateOfBirth: data.dateOfBirth.toString(),
            colour: data.colour,
            startDate: '01/28/2023',
            endDate: '',
        };

        setSubmitting(true);

        upsertPlayer({ teamID, playerID, ...formData })
            .then((response) => {
                console.log('create success', response);
            })
            .catch((err) => {
                console.log('create error', err);
                onSubmit && onSubmit('error');
            })
            .finally(() => {
                setSubmitting(false);
                playerID
                    ? onSubmit && onSubmit('updated')
                    : onSubmit && onSubmit('success');
                resetForm();
            });
    };

    const validate = (values: FormikValues) => {
        const errors: FormikErrors<PlayerFormTypes> = {};

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field as keyof PlayerFormTypes] =
                    'Field cannot be blank';
            }
        });

        return errors;
    };

    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
            }) => {
                return (
                    <form className={'p-fluid form'} onSubmit={handleSubmit}>
                        <div className="form-fields">
                            <div className="form-segment">
                                <label className="form-label" htmlFor="gender">
                                    Gender
                                </label>
                                <div
                                    className={`input-wrap ${
                                        errors.gender &&
                                        touched.gender &&
                                        'has-error'
                                    }`}
                                >
                                    <Dropdown
                                        className="form-field has-dropdown"
                                        id="gender"
                                        name="gender"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Players gender"
                                        value={values?.gender}
                                        options={[
                                            { label: 'Male', value: 'Male' },
                                            {
                                                label: 'Female',
                                                value: 'Female',
                                            },
                                        ]}
                                        showClear={true}
                                    />
                                    {errors.gender && touched.gender && (
                                        <Icon name="warning" size="small" />
                                    )}
                                </div>
                                {errors.gender && touched.gender && (
                                    <Message
                                        className="form-message form-message--error message-floated"
                                        severity="error"
                                        text={errors.gender}
                                        icon={
                                            <Icon name="error" size="small" />
                                        }
                                    />
                                )}
                            </div>

                            <div className="form-segment">
                                <label
                                    className="form-label"
                                    htmlFor="firstName"
                                >
                                    First Name
                                </label>
                                <div
                                    className={`input-wrap ${
                                        errors.firstName &&
                                        touched.firstName &&
                                        'has-error'
                                    }`}
                                >
                                    <InputText
                                        className="form-field"
                                        id="firstName"
                                        name="firstName"
                                        type={INPUT_TYPES.text}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter First Name"
                                        value={values?.firstName || ''}
                                        required
                                    />
                                    {errors.firstName && touched.firstName && (
                                        <Icon name="warning" size="small" />
                                    )}
                                </div>
                                {errors.firstName && touched.firstName && (
                                    <Message
                                        className="form-message form-message--error message-floated"
                                        severity="error"
                                        text={errors.firstName}
                                        icon={
                                            <Icon name="error" size="small" />
                                        }
                                    />
                                )}
                            </div>

                            <div className="form-segment">
                                <label
                                    className="form-label"
                                    htmlFor="lastName"
                                >
                                    Last Name
                                </label>
                                <div
                                    className={`input-wrap ${
                                        errors.lastName &&
                                        touched.lastName &&
                                        'has-error'
                                    }`}
                                >
                                    <InputText
                                        className="form-field"
                                        id="lastName"
                                        name="lastName"
                                        type={INPUT_TYPES.text}
                                        onInput={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Last Name"
                                        value={values?.lastName || ''}
                                        required
                                    />
                                    {errors.lastName && touched.lastName && (
                                        <Icon name="warning" size="small" />
                                    )}
                                </div>
                                {errors.lastName && touched.lastName && (
                                    <Message
                                        className="form-message form-message--error message-floated"
                                        severity="error"
                                        text={errors.lastName}
                                        icon={
                                            <Icon name="error" size="small" />
                                        }
                                    />
                                )}
                            </div>

                            <div className="form-segment">
                                <label
                                    className="form-label"
                                    htmlFor="displayName"
                                >
                                    Display Name
                                </label>
                                <div
                                    className={`input-wrap ${
                                        errors.displayName &&
                                        touched.displayName &&
                                        'has-error'
                                    }`}
                                >
                                    <InputText
                                        className="form-field"
                                        id="displayName"
                                        type={INPUT_TYPES.text}
                                        name="displayName"
                                        onInput={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Display Name"
                                        value={values?.displayName || ''}
                                        required
                                    />
                                    {errors.displayName &&
                                        touched.displayName && (
                                            <Icon name="warning" size="small" />
                                        )}
                                </div>
                                {errors.displayName && touched.displayName && (
                                    <Message
                                        className="form-message form-message--error message-floated"
                                        severity="error"
                                        text={errors.displayName}
                                        icon={
                                            <Icon name="error" size="small" />
                                        }
                                    />
                                )}
                            </div>

                            <div className="form-segment">
                                <label
                                    className="form-label"
                                    htmlFor="uniformNumber"
                                >
                                    Playing Number
                                </label>
                                <div
                                    className={`input-wrap ${
                                        errors.uniformNumber &&
                                        touched.uniformNumber &&
                                        'has-error'
                                    }`}
                                >
                                    <InputText
                                        className="form-field"
                                        id="uniformNumber"
                                        type={INPUT_TYPES.number}
                                        name="uniformNumber"
                                        onInput={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            const maxLength = 2;
                                            const val =
                                                e.target.value.length >
                                                maxLength
                                                    ? e.target.value.slice(
                                                          0,
                                                          maxLength
                                                      )
                                                    : e.target.value;
                                            setFieldValue('uniformNumber', val);
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter Playing Number"
                                        value={values?.uniformNumber || ''}
                                        required
                                    />
                                    {errors.uniformNumber &&
                                        touched.uniformNumber && (
                                            <Icon name="warning" size="small" />
                                        )}
                                </div>
                                {errors.uniformNumber &&
                                    touched.uniformNumber && (
                                        <Message
                                            className="form-message form-message--error message-floated"
                                            severity="error"
                                            text={errors.uniformNumber}
                                            icon={
                                                <Icon
                                                    name="error"
                                                    size="small"
                                                />
                                            }
                                        />
                                    )}
                            </div>

                            <div className="form-segment">
                                <label htmlFor="dateOfBirth">
                                    Date Of Birth
                                </label>
                                <Calendar
                                    className="form-field"
                                    dateFormat="dd/mm/yy"
                                    id="dateOfBirth"
                                    locale="en-au"
                                    minDate={playerDOBMin}
                                    maxDate={playerDOBMax}
                                    name="dateOfBirth"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="DD/MM/YY"
                                    required
                                    value={
                                        (values?.dateOfBirth &&
                                            new Date(values?.dateOfBirth)) ||
                                        ''
                                    }
                                />

                                {errors.dateOfBirth && touched.dateOfBirth && (
                                    <Message
                                        className="form-message form-message--error message-floated"
                                        severity="error"
                                        text={errors.dateOfBirth}
                                        icon={
                                            <Icon name="error" size="small" />
                                        }
                                    />
                                )}
                            </div>

                            <div className="form-segment">
                                <label>Colour</label>
                                <div className="form-segment_row">
                                    {colourKeys.map((colour) => {
                                        return (
                                            <div
                                                key={colour}
                                                className="field-radiobutton colour-pick-radio"
                                            >
                                                <RadioButton
                                                    inputId={`colour-${colour}`}
                                                    name="colour"
                                                    value={colour}
                                                    type={INPUT_TYPES.radio}
                                                    onChange={handleChange}
                                                    placeholder="Select Player Colour"
                                                    checked={
                                                        values.colour === colour
                                                    }
                                                    style={
                                                        {
                                                            '--colour-option':
                                                                colour,
                                                        } as React.CSSProperties
                                                    }
                                                />
                                                <label
                                                    htmlFor={`colour-${colour}`}
                                                >
                                                    {colour}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        {formHasActions && (
                            <div className="form-actions">
                                <Button
                                    className="p-button-outlined p-button-secondary"
                                    disabled={isSubmitting}
                                    onClick={() =>
                                        props.onCancel && props.onCancel()
                                    }
                                    type="button"
                                >
                                    <ButtonIcon
                                        iconName="cancel"
                                        placement="left"
                                    />
                                    <span className="p-button-label">
                                        Cancel
                                    </span>
                                </Button>
                                <Button
                                    disabled={isSubmitting}
                                    type={ACTION_TYPES.submit}
                                >
                                    <ButtonIcon
                                        className={
                                            isSubmitting
                                                ? 'is-submitting'
                                                : undefined
                                        }
                                        iconName={
                                            isSubmitting
                                                ? 'autorenew'
                                                : 'person_add'
                                        }
                                        placement="left"
                                    />
                                    <span className="p-button-label">
                                        {playerID && isSubmitting
                                            ? 'Updating'
                                            : playerID
                                            ? 'Update Player'
                                            : isSubmitting
                                            ? 'Saving'
                                            : 'Save Player'}
                                    </span>
                                </Button>
                            </div>
                        )}
                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

PlayerForm.defaultProps = {
    initialValues: {
        gender: 'Male',
        firstName: '',
        lastName: '',
        colour: colourKeys[0],
        displayName: '',
        uniformNumber: '',
        dateOfBirth: '',
    },
    formHasActions: true,
};

export default PlayerForm;
