import React from 'react';
import Icon from './Icon';

interface Props {
    iconName: string,
    placement?: string,
    className?: string,
    size?: "small" | "medium" | "large",
}

const ButtonIcon = (props: Props) => {

    const { className, iconName, placement, size } = props;

    const getPositionClassName = (position: Props['placement']) => {
        switch (position) {
            case 'left':
                return "p-button-icon-left"
            case 'right':
                return "p-button-icon-right"
            default:
                return ""
        }
    }

    return (
        <Icon className={`${className ? className : ''} ${getPositionClassName(placement)}`} name={iconName} size={size} />
    );

}

ButtonIcon.defaultProps = {
    placement: "left"
};

export default ButtonIcon;