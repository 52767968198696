import { useParams } from 'react-router-dom';

import { useGetTeamStaffInvitesQuery } from '../../api/teams';
import { useGetOrganisationStaffInvitesQuery } from '../../api/organisations';

import StaffPendingView from './StaffPendingView';
import { useState } from 'react';

const StaffPendingContainer = () => {
    const { teamID, organisationID } = useParams();
    const [showRevokeDialog, setShowRevokeDialog] = useState<boolean>(false);
    const [showResendDialog, setShowResendDialog] = useState<boolean>(false);

    const useGetStaffInvitesQuery = organisationID
        ? useGetOrganisationStaffInvitesQuery
        : useGetTeamStaffInvitesQuery;

    const { data, isLoading, isError, isFetching } = useGetStaffInvitesQuery(
        { organisationID, teamID },
        { skip: !teamID && !organisationID }
    );

    const handleCloseRevoke = () => {
        setShowRevokeDialog(false);
    };

    const handleShowRevoke = () => {
        setShowRevokeDialog(true);
    };

    const handleCloseResend = () => {
        setShowResendDialog(false);
    };

    const handleShowResend = () => {
        setShowResendDialog(true);
    };

    const handleRevokeUser = (userID: string) => {
        console.log('DELETE', userID);

        // TODO
    };

    const handleResendInvite = (userID: string) => {
        console.log('RESEND', userID);

        // TODO
    };

    return (
        <StaffPendingView
            data={data ? data.data : []}
            isError={isError}
            isFetching={isFetching}
            isLoading={isLoading}
            onCloseRevokeDialog={handleCloseRevoke}
            showRevokeDialog={showRevokeDialog}
            onShowRevokeDialog={handleShowRevoke}
            onRevokeUser={handleRevokeUser}
            onCloseResendDialog={handleCloseResend}
            showResendDialog={showResendDialog}
            onShowResendDialog={handleShowResend}
            onResendInvite={handleResendInvite}
        />
    );
};

export default StaffPendingContainer;
