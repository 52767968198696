import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { useGetTeamStaffDetailsSingleQuery } from '../../api/teams';
import { useGetOrganisationStaffDetailsSingleQuery } from '../../api/organisations';

import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';

import ErrorDisplay from '../../components/ErrorDisplay';
import InlineDrawer from '../../components/InlineDrawer';

import { ERROR_TYPES } from '../../types/common.d';

interface Props {
    staffID?: string | null;
    onClose?: Function;
}

const StaffPane = (props: Props) => {
    const { organisationID, teamID } = useParams();

    const useGetStaffDetailsQuery = organisationID
        ? useGetOrganisationStaffDetailsSingleQuery
        : useGetTeamStaffDetailsSingleQuery;

    const { data, isLoading, isError, isFetching } = useGetStaffDetailsQuery(
        {
            // @ts-expect-error organisationID may not exist in url
            organisationID,
            // @ts-expect-error teamID may not exist in url
            teamID,
            // @ts-expect-error userID may not exist in url
            userID: props.staffID,
        },
        { skip: !props.staffID }
    );

    const staff = data?.data;

    const paneItem = (title: string, value: string | React.ReactNode) => {
        return (
            <div key={title} className="member-pane_item">
                <h5 className="member-pane_item-title title">{title}</h5>
                <h4 className="member-pane_item-value">{value}</h4>
            </div>
        );
    };

    const renderHeader = () => {
        if (isLoading || isFetching) {
            return <Skeleton />;
        }

        if (staff) {
            return <div>{staff.name}</div>;
        }

        return null;
    };

    const renderBody = () => {
        if (isLoading || isFetching) {
            return <div>Loading...</div>;
        }

        if (isError) {
            return (
                <ErrorDisplay
                    title="An error has occurred"
                    errorType={ERROR_TYPES.somethingsWrong}
                />
            );
        }

        if (!staff) {
            return (
                <ErrorDisplay
                    title="No staff found"
                    errorType={ERROR_TYPES.notFound}
                />
            );
        }

        return (
            <div className="member-pane">
                <div className="member-pane_section">
                    <h6 className="member-pane_section-title title">Team</h6>
                    <div className="list">
                        {[
                            paneItem('Team', staff.entityPreview.entityName),
                            paneItem('Role', staff.roles[0].roleName),
                            paneItem(
                                'Status',
                                <Tag value={staff.entityStatus} />
                            ),
                        ]}
                    </div>
                </div>
                <div className="member-pane_section">
                    <h6 className="member-pane_section-title title">
                        Personal
                    </h6>
                    <div className="list">
                        {[
                            paneItem('Staff name', staff.name),
                            paneItem(
                                'Last Login',
                                format(new Date(staff.lastEdited), 'dd/MM/yyyy')
                            ),
                        ]}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <InlineDrawer header={renderHeader()} onClose={props.onClose}>
            {renderBody()}
        </InlineDrawer>
    );
};

export default StaffPane;
