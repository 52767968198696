import { Outlet, useParams } from 'react-router-dom';
import { Roles } from '../../types/roles';
import { Me } from '../../types/user';
import SideNav from './SideNav';
import TopNav from './TopNav';

interface Props {
    roles: Roles;
    user: Me;
}

const PageWrapper = ({ roles, user }: Props) => {
    const { teamID, organisationID } = useParams();
    return (
        <div className="app-wrapper">
            {(teamID || organisationID) && <SideNav user={user} />}
            <div className="page-wrapper">
                <TopNav user={user} roles={roles} />
                <div className="page-wrapper_inner">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default PageWrapper;
