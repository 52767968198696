import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { pick } from 'lodash';

import { useUpsertTeamMutation, useDeleteTeamMutation } from '../../api/teams';
import {
    useUpsertOrganisationTeamMutation,
    useDeleteOrganisationTeamMutation,
} from '../../api/organisations';

import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { ProgressBar } from 'primereact/progressbar';
import { SelectButton } from 'primereact/selectbutton';

import ButtonIcon from '../../components/ButtonIcon';
import EntityPreview from '../../components/EntityPreview';
import EntityDesigner from '../../components/EntityDesigner';
import Icon from '../../components/Icon';
import ListItem from '../../components/ListItem';
import Modal from '../../components/Modal';

import { ACTION_TYPES, INPUT_TYPES, SEVERITY } from '../../types/common.d';
import { TeamForm as TeamFormTypes, Team } from '../../types/team.d';
import { Design } from '../../types/design.d';

import {
    teamAgeOptions,
    teamGenderOptions,
    teamGradeOptions,
    teamTypeOptions,
} from '../../util/constants';
import { useLazyGetRolesQuery } from '../../api/roles';

interface Props {
    formHasActions: boolean;
    initialValues: TeamFormTypes;
    teamID?: Team['teamID'];
    onCancel?: Function;
    onSubmit?: Function;
}

const requiredFields: Array<keyof TeamFormTypes> = [
    'design',
    'typeID',
    'teamName',
    'shortName',
    'age',
    'gender',
];
const optionalFields: Array<keyof TeamFormTypes> = ['grade'];
const editableFields: Array<keyof TeamFormTypes> = [
    'teamName',
    'shortName',
    'age',
    'gender',
    'grade',
];

const TeamForm = (props: Props) => {
    const { userID, organisationID } = useParams();
    const { formHasActions, initialValues, onCancel, onSubmit, teamID } = props;

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [showEditor, setShowEditor] = useState(false);

    const [getRoles] = useLazyGetRolesQuery();
    const upsertTeamMutation = organisationID
        ? useUpsertOrganisationTeamMutation
        : useUpsertTeamMutation;
    const deleteTeamMutation = organisationID
        ? useDeleteOrganisationTeamMutation
        : useDeleteTeamMutation;

    const [upsertTeam] = upsertTeamMutation();
    const [deleteTeam] = deleteTeamMutation();

    const isCreate = !teamID;

    const handleSubmit = (
        data: TeamFormTypes,
        { setSubmitting }: FormikHelpers<TeamFormTypes>
    ) => {
        // set formik to submitting
        setSubmitting(true);

        let fixedData = isCreate
            ? { seasonName: new Date().getFullYear().toString() }
            : {};

        // init creation/update method
        upsertTeam({ teamID, organisationID, userID, ...data, ...fixedData })
            .then((response) => {
                teamID
                    ? onSubmit && onSubmit('updated')
                    : onSubmit && onSubmit('success');

                if (userID) {
                    getRoles({ userID });
                }
            })
            .catch((err) => {
                onSubmit && onSubmit('error');
            })
            .finally(() => {
                setSubmitting(false);
                isCreate && onCancel && onCancel();
            });
    };

    const handleDelete = ({
        setSubmitting,
    }: Pick<FormikHelpers<TeamFormTypes>, 'setSubmitting'>) => {
        // set formik to submitting
        setSubmitting(true);

        // close modal to show form
        setShowDeleteDialog(false);

        // init deletion method
        deleteTeam({
            organisationID: organisationID ? organisationID : '',
            teamID: teamID ? teamID : '',
        })
            .then((response) => {
                console.log(response);
                onSubmit && onSubmit('success');

                if (userID) {
                    getRoles({ userID });
                }
            })
            .catch((err) => {
                console.dir(err);
                onSubmit && onSubmit('error');
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const validate = (values: FormikValues) => {
        const errors: FormikErrors<TeamFormTypes> = {};

        const fields = isCreate ? requiredFields : editableFields;

        fields.forEach((field) => {
            if (!values[field]) {
                errors[field as keyof TeamFormTypes] = 'Field cannot be blank';
            }
        });

        return errors;
    };

    const confirmRemovalModalHeader = (
        <h4 className={'title-is-bold'}>Confirm Deletion</h4>
    );

    const confirmRemovalModalHeaderIcon = (
        <Button
            className="p-button-sm p-button-text p-button-secondary ui-button"
            onClick={() => setShowDeleteDialog(false)}
            type="button"
        >
            <Icon name="close" size="small" />
        </Button>
    );

    const confirmRemovalModalContent = !isCreate && (
        <div className="modal-content">
            <p className="delete-msg">
                Are you sure you want to delete this Team?
            </p>
            <div className="list">
                <ListItem
                    avatar={{
                        label: initialValues.teamName,
                    }}
                    title={initialValues.teamName}
                />
            </div>
        </div>
    );

    return (
        <Formik
            initialValues={pick(initialValues, [
                ...requiredFields,
                ...optionalFields,
            ])}
            validate={validate}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                setSubmitting,
            }) => {
                return (
                    <>
                        <Modal
                            content={
                                <EntityDesigner
                                    initialValues={values.design}
                                    onCancel={() => setShowEditor(false)}
                                    onSubmit={(value: Design) => {
                                        setFieldValue('design', value);
                                        setShowEditor(false);
                                    }}
                                    submitLabel="Save Design"
                                />
                            }
                            hasFooter={false}
                            maximizable={false}
                            onHide={() => setShowEditor(false)}
                            title="Kit Designer"
                            visible={showEditor}
                        />

                        <form
                            className={`form ${
                                !isCreate ? 'form-is-wrapped' : ''
                            }`}
                            onSubmit={handleSubmit}
                        >
                            {!isCreate && (
                                <div className="wrapped-form_header">
                                    <h4 className="title">Team Settings</h4>
                                </div>
                            )}
                            <div className="form-fields">
                                <div className="form-segment kit-edit_wrap">
                                    <div
                                        className="kit-edit"
                                        onClick={() => setShowEditor(true)}
                                    >
                                        <EntityPreview design={values.design} />
                                        <div className="edit-badge">
                                            <Icon
                                                name="edit"
                                                size="small"
                                            ></Icon>
                                        </div>
                                    </div>
                                    {isCreate && (
                                        <div className="form-segment">
                                            <label
                                                className="form-label"
                                                htmlFor="typeID"
                                            >
                                                Team Type
                                            </label>
                                            <div
                                                className={`input-wrap ${
                                                    errors.typeID &&
                                                    touched.typeID
                                                        ? 'has-error'
                                                        : ''
                                                }`}
                                            >
                                                <SelectButton
                                                    value={values?.typeID}
                                                    options={teamTypeOptions}
                                                    disabled={
                                                        !isCreate &&
                                                        !editableFields.includes(
                                                            'typeID'
                                                        )
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'typeID',
                                                            e.value
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                            {errors.typeID &&
                                                touched.typeID && (
                                                    <Message
                                                        className="form-message form-message--error message-floated"
                                                        severity="error"
                                                        text={errors.typeID}
                                                        icon={
                                                            <Icon
                                                                name="error"
                                                                size="small"
                                                            />
                                                        }
                                                    />
                                                )}
                                        </div>
                                    )}

                                    <div className="form-segment">
                                        <label
                                            className="form-label"
                                            htmlFor="teamName"
                                        >
                                            Team Name
                                        </label>
                                        <div
                                            className={`input-wrap ${
                                                errors.teamName &&
                                                touched.teamName
                                                    ? 'has-error'
                                                    : ''
                                            } ${!isCreate && 'input-disabled'}`}
                                        >
                                            <InputText
                                                className="form-field"
                                                id="teamName"
                                                name="teamName"
                                                type={INPUT_TYPES.text}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter Team Name"
                                                value={values?.teamName || ''}
                                                required
                                                disabled={
                                                    !isCreate &&
                                                    editableFields.includes(
                                                        'teamName'
                                                    )
                                                }
                                            />
                                            {errors.teamName &&
                                                touched.teamName && (
                                                    <Icon
                                                        name="warning"
                                                        size="small"
                                                    />
                                                )}
                                        </div>
                                        {errors.teamName &&
                                            touched.teamName && (
                                                <Message
                                                    className="form-message form-message--error message-floated"
                                                    severity="error"
                                                    text={errors.teamName}
                                                    icon={
                                                        <Icon
                                                            name="error"
                                                            size="small"
                                                        />
                                                    }
                                                />
                                            )}
                                    </div>

                                    <div className="form-segment">
                                        <label
                                            className="form-label"
                                            htmlFor="shortName"
                                        >
                                            Short Name
                                        </label>
                                        <div
                                            className={`input-wrap ${
                                                errors.shortName &&
                                                touched.shortName
                                                    ? 'has-error'
                                                    : ''
                                            }`}
                                        >
                                            <InputText
                                                className="form-field"
                                                id="shortName"
                                                name="shortName"
                                                type={INPUT_TYPES.text}
                                                onInput={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter Team Short Name"
                                                value={values?.shortName || ''}
                                                required
                                                disabled={
                                                    !isCreate &&
                                                    !editableFields.includes(
                                                        'shortName'
                                                    )
                                                }
                                            />
                                            {errors.shortName &&
                                                touched.shortName && (
                                                    <Icon
                                                        name="warning"
                                                        size="small"
                                                    />
                                                )}
                                        </div>
                                        {errors.shortName &&
                                            touched.shortName && (
                                                <Message
                                                    className="form-message form-message--error message-floated"
                                                    severity="error"
                                                    text={errors.shortName}
                                                    icon={
                                                        <Icon
                                                            name="error"
                                                            size="small"
                                                        />
                                                    }
                                                />
                                            )}
                                    </div>

                                    <div className="form-segment">
                                        <label
                                            className="form-label"
                                            htmlFor="age"
                                        >
                                            Team Age
                                        </label>
                                        <div
                                            className={`input-wrap ${
                                                errors.age && touched.age
                                                    ? 'has-error'
                                                    : ''
                                            }`}
                                        >
                                            <Dropdown
                                                className="form-field has-dropdown"
                                                id="age"
                                                name="age"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Team Age"
                                                value={values?.age}
                                                options={teamAgeOptions}
                                                showClear={true}
                                                disabled={
                                                    !isCreate &&
                                                    !editableFields.includes(
                                                        'age'
                                                    )
                                                }
                                            />
                                        </div>
                                        {errors.age && touched.age && (
                                            <Message
                                                className="form-message form-message--error message-floated"
                                                severity="error"
                                                text={errors.age}
                                                icon={
                                                    <Icon
                                                        name="error"
                                                        size="small"
                                                    />
                                                }
                                            />
                                        )}
                                    </div>

                                    <div className="form-segment">
                                        <label
                                            className="form-label"
                                            htmlFor="gender"
                                        >
                                            Team Gender
                                        </label>
                                        <div
                                            className={`input-wrap ${
                                                errors.gender && touched.gender
                                                    ? 'has-error'
                                                    : ''
                                            }`}
                                        >
                                            <SelectButton
                                                value={values?.gender}
                                                options={teamGenderOptions}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'gender',
                                                        e.value
                                                    )
                                                }
                                                disabled={
                                                    !isCreate &&
                                                    !editableFields.includes(
                                                        'gender'
                                                    )
                                                }
                                            />
                                        </div>
                                        {errors.gender && touched.gender && (
                                            <Message
                                                className="form-message form-message--error message-floated"
                                                severity="error"
                                                text={errors.gender}
                                                icon={
                                                    <Icon
                                                        name="error"
                                                        size="small"
                                                    />
                                                }
                                            />
                                        )}
                                    </div>

                                    <div className="form-segment">
                                        <label
                                            className="form-label"
                                            htmlFor="grade"
                                        >
                                            Team Grade
                                        </label>
                                        <div
                                            className={`input-wrap ${
                                                errors.grade && touched.grade
                                                    ? 'has-error'
                                                    : ''
                                            }`}
                                        >
                                            <Dropdown
                                                className="form-field has-dropdown"
                                                id="grade"
                                                name="grade"
                                                editable={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Team Grade"
                                                value={values?.grade}
                                                options={teamGradeOptions}
                                                showClear={true}
                                                disabled={
                                                    !isCreate &&
                                                    !editableFields.includes(
                                                        'grade'
                                                    )
                                                }
                                            />
                                        </div>
                                        {errors.grade && touched.grade && (
                                            <Message
                                                className="form-message form-message--error message-floated"
                                                severity="error"
                                                text={errors.grade}
                                                icon={
                                                    <Icon
                                                        name="error"
                                                        size="small"
                                                    />
                                                }
                                            />
                                        )}
                                    </div>
                                </div>

                                {formHasActions && (
                                    <div className="form-actions">
                                        {isCreate && (
                                            <Button
                                                className={`p-button-text p-button-sm p-button-${SEVERITY.secondary}`}
                                                disabled={isSubmitting}
                                                onClick={() =>
                                                    onCancel && onCancel()
                                                }
                                                type={ACTION_TYPES.button}
                                            >
                                                <ButtonIcon
                                                    iconName="cancel"
                                                    placement="left"
                                                />
                                                <span className="p-button-label">
                                                    Cancel
                                                </span>
                                            </Button>
                                        )}
                                        {!isCreate && (
                                            <Button
                                                className={`p-button-sm p-button-${SEVERITY.danger}`}
                                                disabled={isSubmitting}
                                                onClick={() =>
                                                    setShowDeleteDialog(true)
                                                }
                                                type={ACTION_TYPES.button}
                                            >
                                                <ButtonIcon
                                                    iconName="delete"
                                                    placement="left"
                                                />
                                                <span className="p-button-label">
                                                    Delete
                                                </span>
                                            </Button>
                                        )}
                                        <Button
                                            className={`p-button-sm p-button-${SEVERITY.primary}`}
                                            disabled={isSubmitting}
                                            type={ACTION_TYPES.submit}
                                        >
                                            <ButtonIcon
                                                className={
                                                    isSubmitting
                                                        ? 'is-submitting'
                                                        : undefined
                                                }
                                                iconName={
                                                    isSubmitting
                                                        ? 'autorenew'
                                                        : 'add_moderator'
                                                }
                                                placement="left"
                                            />
                                            <span className="p-button-label">
                                                {!isCreate && isSubmitting
                                                    ? 'Updating'
                                                    : !isCreate
                                                    ? 'Update Team'
                                                    : isSubmitting
                                                    ? 'Creating'
                                                    : 'Create Team'}
                                            </span>
                                        </Button>
                                    </div>
                                )}

                                {isSubmitting && (
                                    <div className="form-overlay">
                                        <ProgressBar mode="indeterminate" />
                                    </div>
                                )}

                                {!isCreate && (
                                    <ConfirmDialog
                                        className="modal delete-modal"
                                        closeOnEscape
                                        draggable={false}
                                        dismissableMask
                                        footer={
                                            <>
                                                <Button
                                                    className={`p-button-text p-button-${SEVERITY.secondary}`}
                                                    onClick={() =>
                                                        setShowDeleteDialog(
                                                            false
                                                        )
                                                    }
                                                    type={ACTION_TYPES.button}
                                                >
                                                    <ButtonIcon
                                                        iconName="cancel"
                                                        placement="left"
                                                    />
                                                    <span className="p-button-label">
                                                        Cancel
                                                    </span>
                                                </Button>
                                                <Button
                                                    className={`p-button-${SEVERITY.danger}`}
                                                    onClick={() =>
                                                        handleDelete({
                                                            setSubmitting,
                                                        })
                                                    }
                                                    type={ACTION_TYPES.submit}
                                                >
                                                    <ButtonIcon
                                                        iconName="delete"
                                                        placement="left"
                                                    />
                                                    <span className="p-button-label">
                                                        Yes, Delete
                                                    </span>
                                                </Button>
                                            </>
                                        }
                                        header={confirmRemovalModalHeader}
                                        icons={confirmRemovalModalHeaderIcon}
                                        message={confirmRemovalModalContent}
                                        onHide={() =>
                                            setShowDeleteDialog(false)
                                        }
                                        visible={showDeleteDialog}
                                    />
                                )}
                            </div>
                        </form>
                    </>
                );
            }}
        </Formik>
    );
};

export default TeamForm;

TeamForm.defaultProps = {
    formHasActions: true,
    initialValues: {
        teamName: '',
        shortName: '',
        typeID: '',
        age: '',
        gender: '',
        grade: '',
        design: {
            template: '',
            primary: '',
            secondary: '',
            tertiary: '',
        },
    },
};
