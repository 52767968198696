import { useNavigate, useParams } from 'react-router-dom';

import {
    useGetOrganisationTeamsQuery,
    useLazyGetOrganisationTeamsQuery,
    useUpdateOrganisationTeamMutation,
} from '../../api/organisations';

import TeamListingView from './TeamListingView';
import DocumentHead from '../../components/DocumentHead';

const TeamListingContainer = () => {
    const navigate = useNavigate();
    const { organisationID } = useParams();

    const [updateTeam] = useUpdateOrganisationTeamMutation();
    const [refetch] = useLazyGetOrganisationTeamsQuery();

    const { data, isLoading, isFetching, isError } =
        useGetOrganisationTeamsQuery({
            organisationID,
        });

    const handleImportClick = () => {
        const route = organisationID
            ? `/o/${organisationID}/teams/import`
            : '/teams/import';

        navigate(route);
    };

    const handleLoadMore = () => {
        const { lastEvaluatedKey } = data;
        if (lastEvaluatedKey && lastEvaluatedKey.cursor) {
            refetch({
                organisationID,
                cursor: lastEvaluatedKey.cursor,
            });
        }
    };

    const handleGenerateCodeClick = (mode, team) => {
        updateTeam({
            organisationID: team.organisationID,
            teamID: team.teamID,
            teamJoinMode: mode,
        });
    };

    return (
        <>
            <DocumentHead
                title="Team Listing - Rookie Me Hub"
                description="Team listing page"
            />
            <TeamListingView
                data={data ? data.data : []}
                isError={isError}
                isLoading={isLoading}
                isFetching={isFetching}
                onImportClick={handleImportClick}
                onGenerateCode={handleGenerateCodeClick}
                onLoadMore={handleLoadMore}
                showPagination={
                    data && data.lastEvaluatedKey && data.lastEvaluatedKey.cursor
                }
            />
        </>
    );
};

export default TeamListingContainer;
