import { ButtonProps } from 'primereact/button';

export interface EntityPreview {
    entityName: string;
}

export interface Action {
    className?: string;
    command?: Function | void;
    disabled?: boolean;
    form?: string;
    icon?: string;
    iconRight?: boolean;
    key?: string;
    label: string;
    severity?: SEVERITY;
    type?: ACTION_TYPES;
    url?: string;
}

export interface RMButtonProps extends ButtonProps {
    icon: string;
}

export enum ACTION_TYPES {
    submit = 'submit',
    button = 'button',
    reset = 'reset',
}

export const SEVERITY = {
    danger: 'danger',
    info: 'info',
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    warning: 'warning',
};

export const INPUT_TYPES = {
    button: 'button',
    checkbox: 'checkbox',
    color: 'color',
    date: 'date',
    datetimeLocal: 'datetime-local',
    email: 'email',
    file: 'file',
    image: 'image',
    month: 'month',
    number: 'number',
    password: 'password',
    radio: 'radio',
    reset: 'reset',
    search: 'search',
    submit: 'submit',
    tel: 'tel',
    text: 'text',
    url: 'url',
    week: 'week',
};

export enum ERROR_TYPES {
    pageNotFound = 'page-not-found',
    maintenance = 'maintenance',
    notFound = 'not-found',
    fileError = 'file-error',
    empty = 'empty',
    emptyFolder = 'empty-folder',
    inboxEmpty = 'inbox-empty',
    somethingsWrong = 'somethings-wrong',
}

/**
 * Entity types
 */
export enum EntityType {
    users = 'users',
    teams = 'teams',
    players = 'players',
    organisations = 'organisations',
    associations = 'associations',
    competitions = 'competitions',
    leagues = 'leagues',
    seasons = 'seasons',
    grades = 'grades',
    clubs = 'clubs',
    games = 'games',
    usersDefault = 'usersDefault',
}
