import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { Auth0User, Auth0Form } from '../types/user';

export const auth0Api = createApi({
    reducerPath: 'auth0Api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        updateProfile: builder.mutation<Auth0User, Auth0Form>({
            query: (data) => ({
                url: apiEndpoints.updateProfile.url,
                method: apiEndpoints.updateProfile.method,
                body: data,
            }),
        }),
    }),
});

export const { useUpdateProfileMutation } = auth0Api;
