import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function AuthGatekeeper(props) {
    const { loginWithRedirect } = useAuth0();

    loginWithRedirect();

    return (
        <div className="auth0-app auth0-lock auth0-lock">
            <p>Redirecting to login...</p>
        </div>
    );
}
