import { TeamGrade, TeamSport, TeamCategory, TeamGender, TeamAge } from "../types/team.d";


export const teamGradeOptions = Object.keys(TeamGrade).map((key, i) => ({
    label: Object.values(TeamGrade)[i],
    value: key
}));

export const teamSportOptions = Object.keys(TeamSport).map((key, i) => ({
    label: Object.values(TeamSport)[i],
    value: key
}));

export const teamTypeOptions = Object.keys(TeamCategory).map((key, i) => ({
    label: Object.values(TeamCategory)[i],
    value: key
}));

export const teamGenderOptions = Object.keys(TeamGender).map((key, i) => ({
    label: Object.values(TeamGender)[i],
    value: key
}));

export const teamAgeOptions = Object.keys(TeamAge).map((key, i) => ({
    label: Object.values(TeamAge)[i],
    value: key
}));