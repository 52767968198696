import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ImportForm from '../../forms/ImportForm';
import PageHeader from '../../components/page/PageHeader';
import DocumentHead from '../../components/DocumentHead';

import { useCreateOrganisationMutation } from '../../api/organisations';
import { useGetMeQuery } from '../../api/roles';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';
const STATUS_LOADING = 'loading';

const columns = [
    {
        field: 'organisationName',
        header: 'Organisation Name',
    },
];

const OrganisationsImport = (props) => {
    const [importedData, setImportedData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useRef(null);

    const navigate = useNavigate();

    // API Handling
    const { data: dataUser, isError } = useGetMeQuery();
    const [createOrganisation] = useCreateOrganisationMutation();

    const handleSubmit = async () => {
        const userID = dataUser && dataUser.data && dataUser.data.userID;
        let successCount = 0;

        setIsSubmitting(true);

        await Promise.allSettled(
            importedData.map(async (data, rowIndex) => {
                updateRowStatus(rowIndex, STATUS_LOADING);

                const response = await createOrganisation({
                    userID,
                    ...data,
                    status: 'Active',
                });

                if (response.data) {
                    successCount++;
                    updateRowStatus(rowIndex, STATUS_SUCCESS);
                } else {
                    updateRowStatus(rowIndex, STATUS_ERROR);
                }
            })
        ).then(() => {
            setIsSubmitting(false);
        });

        toast.current.show({
            severity:
                successCount === 0
                    ? 'error'
                    : successCount === importedData.length
                        ? 'success'
                        : '',
            className:
                successCount === 0 || successCount === importedData.length
                    ? 'p-toast-message--is-inline'
                    : '',
            summary: `${successCount}/${importedData.length} Imported`,
            detail: successDetailContent(successCount, importedData.length),
            life: 5000,
        });
    };

    const successDetailContent = (count, maxCount) => {
        return (
            <>
                {maxCount === count ? (
                    <Button
                        className="p-button-outlined p-button-sm p-button-secondary toast-action"
                        label="View"
                        onClick={() =>
                            navigate('/', {
                                replace: true,
                            })
                        }
                    />
                ) : count > 0 ? (
                    <div className="button-group">
                        <Button
                            className="p-button-outlined p-button-sm p-button-warn toast-action"
                            label="Clear And Retry"
                            onClick={() => handleRetryImport(count)}
                        />
                        <Button
                            className="p-button-outlined p-button-sm p-button-secondary toast-action"
                            label="View Imported"
                            onClick={() =>
                                navigate('/', {
                                    replace: true,
                                })
                            }
                        />
                    </div>
                ) : (
                    <Button
                        className="p-button-outlined p-button-sm p-button-secondary toast-action"
                        label="Retry"
                        onClick={() => handleSubmit()}
                    />
                )}
            </>
        );
    };

    const updateRowStatus = (rowIndex, status) => {
        setImportedData((prevData) =>
            prevData.map((item, index) =>
                index === rowIndex ? { ...item, status } : item
            )
        );
    };

    const handleImport = (data) => {
        setImportedData(data);
    };

    const handleClearAll = () => {
        setImportedData([]);
    };

    const handleClearCompleted = () => {
        setImportedData((prevState) =>
            prevState.filter((data) => data.status !== STATUS_SUCCESS)
        );
    };

    const handleRetryImport = (completedCount) => {
        if (completedCount > 0) {
            handleClearCompleted();
        }
        handleSubmit();
    };

    const handleFileError = () => {
        toast.current.show({
            severity: 'error',
            summary: `File type not supported`,
            detail: 'Import a CSV or Excel Sheet',
            life: 5000,
        });
    };

    const handleRemoveRow = (index) => {
        setImportedData((data) => [
            ...data.slice(0, index),
            ...data.slice(index + 1),
        ]);
    };

    const handleAddRow = (data) => {
        setImportedData(data);
    };

    const handleEditComplete = (data) => {
        setImportedData(data);
    };

    return (
        <>
            <DocumentHead
                title="Organisation Import - Rookie Me Hub"
                description="Organisation import page"
            />
            <div className="page view">
                <div className="view-inner">
                    <PageHeader title='Organisations' showBreadcrumbs={false} />
                    <ImportForm
                        columns={columns}
                        data={importedData}
                        isError={isError}
                        isSubmitting={isSubmitting}
                        onAddRow={handleAddRow}
                        onClearAll={handleClearAll}
                        onClearCompleted={handleClearCompleted}
                        onImportError={handleFileError}
                        onEditComplete={handleEditComplete}
                        onRemoveRow={handleRemoveRow}
                        onSubmit={handleSubmit}
                        onImportComplete={handleImport}
                    />
                    <Toast ref={toast} />
                </div>
            </div>
        </>
    );
};

export default OrganisationsImport;
