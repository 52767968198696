import React, { PropsWithChildren } from 'react';
import AccountForm from '../forms/AccountForm';
import DocumentHead from '../components/DocumentHead';

const Account: React.FC<PropsWithChildren<any>> = (props) => {
    return (
        <>
            <DocumentHead 
                title="Account - Rookie Me Hub"
                description="User account page"
            />
            <div className="p-5 flex flex-column flex-auto">
                <div>
                    <AccountForm {...props} />
                </div>
            </div>
        </>
    );
}

export default Account;
