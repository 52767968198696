import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { auth0Api } from './api/auth0';
import { eventsApi } from './api/events';
import { licensesApi } from './api/licenses';
import { notesApi } from './api/notes';
import { organisationsApi } from './api/organisations';
import { rolesApi } from './api/roles';
import { teamsApi } from './api/teams';
import { sportsApi } from './api/sports';

export const store = configureStore({
    reducer: {
        [auth0Api.reducerPath]: auth0Api.reducer,
        [eventsApi.reducerPath]: eventsApi.reducer,
        [licensesApi.reducerPath]: licensesApi.reducer,
        [notesApi.reducerPath]: notesApi.reducer,
        [organisationsApi.reducerPath]: organisationsApi.reducer,
        [rolesApi.reducerPath]: rolesApi.reducer,
        [teamsApi.reducerPath]: teamsApi.reducer,
        [sportsApi.reducerPath]: sportsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            auth0Api.middleware,
            eventsApi.middleware,
            licensesApi.middleware,
            notesApi.middleware,
            organisationsApi.middleware,
            rolesApi.middleware,
            teamsApi.middleware,
            sportsApi.middleware,
        ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
