import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import { DataTable } from 'primereact/datatable';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Toolbar } from 'primereact/toolbar';

import ButtonIcon from '../components/ButtonIcon';
import ErrorDisplay from '../components/ErrorDisplay';
import Icon from '../components/Icon';

import { ACTION_TYPES, ERROR_TYPES, SEVERITY } from '../types/common.d';

const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';
const STATUS_LOADING = 'loading';
const STATUS_INVALID = 'invalid';

const ImportForm = (props) => {
    const { isSubmitting, isError } = props;
    const navigate = useNavigate();

    // States
    const [editingRows, setEditingRows] = useState([]);

    // Refs
    const uploadButton = useRef(null);

    // Helpers
    const availableCols = props.columns.map((col) => col.field);

    const handleUpload = (e) => {
        const file = e.files[0];

        switch (file.type) {
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
                importExcel(file);
                break;
            case 'text/csv':
                importCSV(file);
                break;
            default:
                props.onImportError();
        }

        // Reset upload button after uploading
        uploadButton.current.clear();
    };

    const importCSV = (file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const csv = e.target.result;
            const data = csv.split('\n');

            // Prepare DataTable
            const cols = data[0]
                .replace(/['"\r]+/g, '')
                .split(',')
                .filter((col) => availableCols.includes(col));

            data.shift();

            const importedData = data
                .map((d, mi) => {
                    d = d.split(',');
                    return cols.reduce((obj, c, i) => {
                        obj[c] = d[i].replace(/['"\r]+/g, '');
                        return obj;
                    }, {});
                })
                .filter((value) => Object.keys(value).length !== 0);

            props.onImportComplete(importedData);
        };

        reader.readAsText(file, 'UTF-8');
    };

    const importExcel = (file) => {
        import('xlsx').then((xlsx) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

                // Prepare DataTable
                const cols = data[0].filter((col) =>
                    availableCols.includes(col)
                );

                data.shift();

                const importedData = data
                    .map((d) => {
                        return cols.reduce((obj, c, i) => {
                            obj[c] = d[i];
                            return obj;
                        }, {});
                    })
                    .filter((value) => Object.keys(value).length !== 0);

                props.onImportComplete(importedData);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const clearAll = () => {
        if (props.onClearAll) {
            props.onClearAll();
        }
    };

    const clearUploaded = () => {
        if (props.onClearCompleted) {
            props.onClearCompleted();
        }
    };

    const cellEditor = (options, column) => {
        if (column.component) {
            return column.component(options);
        }
        return (
            <InputText
                onChange={(e) => options.editorCallback(e.target.value)}
                placeholder={startCase(options.field)}
                type="text"
                value={options.value}
            />
        );
    };

    const onRowEditComplete = ({ newData, index }) => {
        if (props.onEditComplete) {
            const data = props.data.map((data, i) =>
                i === index ? newData : data
            );
            props.onEditComplete(data);
        }
    };

    const onRowEditChange = (e) => {
        setEditingRows(e.data);
    };

    const addRow = () => {
        const emptyRow = availableCols.reduce((result, val, index) => {
            result[val] = '';
            return result;
        }, {});

        if (props.onAddRow) {
            props.onAddRow([...props.data, emptyRow]);
            setEditingRows([emptyRow]);
        }
    };

    const removeRow = (index) => {
        if (props.onRemoveRow) {
            props.onRemoveRow(index);
        }
    };

    const rowClass = (data) => {
        if (data && data.status) {
            return `is-${data.status}`;
        }
    };

    // clear splitButton action
    const clearActions = [
        {
            command: () => clearUploaded(),
            className: `p-button-${SEVERITY.secondary}`,
            disabled: isSubmitting,
            icon: <ButtonIcon iconName="remove_done" placement="left" />,
            label: 'Clear Completed',
        },
    ];

    // Templating
    const header = (
        <div className="has-loadingbar">
            <Toolbar
                left={
                    <FileUpload
                        ref={uploadButton}
                        chooseOptions={{
                            className: `p-button-sm ${
                                isSubmitting && 'p-disabled'
                            }`,
                            icon: (
                                <ButtonIcon
                                    iconName="upload_file"
                                    placement="left"
                                />
                            ),
                            label: 'Import',
                        }}
                        mode="basic"
                        name="teamImport"
                        auto
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        customUpload
                        uploadHandler={handleUpload}
                    />
                }
                right={
                    <>
                        {props.data.length > 0 && (
                            <>
                                {props.data.some(
                                    (data) => data.status === STATUS_SUCCESS
                                ) ? (
                                    <SplitButton
                                        className="p-button-sm p-button-secondary"
                                        model={clearActions}
                                        type={ACTION_TYPES.button}
                                        onClick={() => clearAll()}
                                        disabled={isSubmitting}
                                        buttonTemplate={
                                            <>
                                                <ButtonIcon
                                                    iconName="do_not_disturb_on"
                                                    placement="left"
                                                />
                                                <span className="p-button-label">
                                                    Clear All
                                                </span>
                                            </>
                                        }
                                    />
                                ) : (
                                    <Button
                                        className={`p-button-sm p-button-${SEVERITY.secondary}`}
                                        disabled={isSubmitting}
                                        onClick={() => clearAll()}
                                        type={ACTION_TYPES.button}
                                    >
                                        <ButtonIcon
                                            iconName="do_not_disturb_on"
                                            placement="left"
                                        />
                                        <span className="p-button-label">
                                            Clear All
                                        </span>
                                    </Button>
                                )}

                                <Button
                                    className={`p-button-sm p-button-${SEVERITY.success}`}
                                    disabled={isSubmitting}
                                    onClick={props.onSubmit}
                                    type={ACTION_TYPES.submit}
                                >
                                    <ButtonIcon
                                        iconName="upload"
                                        placement="left"
                                    />
                                    <span className="p-button-label">
                                        Upload
                                    </span>
                                </Button>
                            </>
                        )}
                    </>
                }
            />
            {isSubmitting && <ProgressBar mode="indeterminate" />}
        </div>
    );

    const obj = props.data[props.data.length - 1];
    let isDisabled = obj && !Object.values(obj).some((val) => !!val);

    const editColumnTemplate = (data, row) => {
        if (row.rowEditor.editing) {
            return (
                <div className="actions-td_wrapper">
                    {renderStatus(data)}
                    <div className="p-buttonset set-is-spaced">
                        <Button
                            className={`p-button-sm p-button-icon-only p-button-rounded p-button-${SEVERITY.success}`}
                            onClick={(e) => row.rowEditor.onSaveClick(e)}
                        >
                            <ButtonIcon iconName="check_circle" />
                        </Button>
                        <Button
                            className={`p-button-sm p-button-icon-only p-button-rounded p-button-${SEVERITY.danger}`}
                            onClick={(e) => row.rowEditor.onCancelClick(e)}
                        >
                            <ButtonIcon iconName="cancel" />
                        </Button>
                    </div>
                </div>
            );
        } else if (data && data.status === 'loading') {
            return (
                <div className="actions-td_wrapper">
                    <ProgressSpinner
                        animationDuration="3s"
                        className="loading-spinner is-small clear is-secondary"
                    />
                </div>
            );
        } else if (data && data.status === 'success') {
            return renderStatus(data);
        } else {
            return (
                <div className="actions-td_wrapper">
                    {renderStatus(data)}
                    <div className="p-buttonset set-is-spaced">
                        <Button
                            className={`p-button-sm p-button-icon-only p-button-rounded p-button-${SEVERITY.secondary}`}
                            onClick={(e) => row.rowEditor.onInitClick(e)}
                        >
                            <ButtonIcon iconName="edit" />
                        </Button>
                        <Button
                            className={`p-button-sm p-button-icon-only p-button-rounded p-button-${SEVERITY.secondary}`}
                            onClick={() => removeRow(row.rowIndex)}
                        >
                            <ButtonIcon iconName="remove" />
                        </Button>
                    </div>
                </div>
            );
        }
    };

    const renderStatus = (rowData) => {
        switch (rowData && rowData.status) {
            case STATUS_LOADING:
                return '';
            case STATUS_ERROR:
                return (
                    <Chip
                        className="p-chip--error"
                        template={() => {
                            return (
                                <>
                                    <Icon name="error" />
                                    <span className="p-chip-text">Failed</span>
                                </>
                            );
                        }}
                    />
                );
            case STATUS_SUCCESS:
                return (
                    <Chip
                        className="p-chip--success"
                        template={() => {
                            return (
                                <>
                                    <Icon name="check_circle" />
                                    <span className="p-chip-text">
                                        Uploaded
                                    </span>
                                </>
                            );
                        }}
                    />
                );
            case STATUS_INVALID:
                return (
                    <Chip
                        className="p-chip--error"
                        template={() => {
                            return (
                                <>
                                    <Icon name="warning" />
                                    <span className="p-chip-text">
                                        Invalid Values
                                    </span>
                                </>
                            );
                        }}
                    />
                );
            default:
                return '';
        }
    };

    const tableEmptyState = (
        <FileUpload
            ref={uploadButton}
            chooseOptions={{
                className: `${isSubmitting && 'p-disabled'} p-button-secondary`,
                icon: <ButtonIcon iconName="upload_file" placement="left" />,
                label: 'Import',
            }}
            mode="advanced"
            name="teamImport"
            auto
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            customUpload
            uploadHandler={handleUpload}
            emptyTemplate={() => {
                return (
                    <ErrorDisplay
                        action={
                            isError
                                ? {
                                      command: () => navigate(0), // refresh
                                      icon: 'refresh',
                                      label: 'Retry',
                                      severity: SEVERITY.primary,
                                  }
                                : {
                                      className: SEVERITY.primary,
                                      command: '',
                                      icon: 'home',
                                      label: 'Import',
                                  }
                        }
                        alignment="middle"
                        desc={
                            isError
                                ? 'Refresh to try the request again'
                                : 'Drag and drop or select "Import" to upload a CSV or EXCEL file.'
                        }
                        errorType={ERROR_TYPES.empty}
                        hasReturn={false}
                        proportion="compact"
                        title={isError ? 'No data returned' : `Get Started`}
                    />
                );
            }}
        />
    );

    const footerTemplate = (
        <>
            <Button
                className={`p-button-sm p-button-${SEVERITY.secondary}`}
                disabled={isDisabled || isSubmitting}
                onClick={addRow}
            >
                <ButtonIcon iconName="add_circle" placement="left" />
                <span className="p-button-label">Add Row</span>
            </Button>
            {props.onLoadMore && (
                <Button
                    onClick={props.onLoadMore}
                    className={`p-button-${SEVERITY.secondary} p-button-sm`}
                    label="Load more"
                >
                    <ButtonIcon iconName="pending" placement="right" />
                </Button>
            )}
        </>
    );

    return (
        <DataTable
            alwaysShowPaginator={false}
            className="empty-message--has-uploader"
            editMode="row"
            editingRows={editingRows}
            emptyMessage={tableEmptyState}
            footer={footerTemplate}
            header={header}
            onRowEditComplete={onRowEditComplete}
            onRowEditChange={onRowEditChange}
            paginator={props.data.length > 0}
            removableSort
            responsiveLayout="scroll"
            rows={50}
            rowClassName={rowClass}
            value={props.data}
        >
            {props.data.length > 0 &&
                props.columns.map((col, index) => (
                    <Column
                        key={index}
                        body={col.body}
                        editor={(options) => cellEditor(options, col)}
                        field={col.field}
                        header={col.header}
                        sortable
                    />
                ))}
            {props.data.length > 0 && (
                <Column
                    body={editColumnTemplate}
                    className="actions-td"
                    rowEditor
                />
            )}
        </DataTable>
    );
};

export default ImportForm;
