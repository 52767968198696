// @ts-nocheck
import { useMemo, useRef, useState } from 'react';
import enAu from 'date-fns/locale/en-AU';
import { isArray } from 'lodash';
import { format, getDay, isSameDay, parse, startOfWeek } from 'date-fns';
import {
    Calendar,
    Components,
    dateFnsLocalizer,
    EventProps,
    Formats,
    Navigate,
    SlotInfo,
    ToolbarProps,
    Views,
    Messages,
} from 'react-big-calendar';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton } from 'primereact/selectbutton';
import { Toolbar } from 'primereact/toolbar';

import ButtonIcon from '../../components/ButtonIcon';
import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import ListItem from '../../components/ListItem';
import Modal from '../../components/Modal';

import CalendarAgenda from './CalendarAgenda';
import CalendarPane from './CalendarPane';
import EventForm from './EventForm';

import { Event, EventType, MaybeEvent } from '../../types/event.d';
import { ACTION_TYPES, ERROR_TYPES, SEVERITY } from '../../types/common.d';
import { Toast } from 'primereact/toast';

interface CalendarEvent
    extends Omit<Event, 'startDateTime' | 'arrivalDateTime' | 'endDateTime'> {
    startDateTime: Date;
    arrivalDateTime: Date;
    endDateTime: Date;
}

interface TruncatedEventsModal {
    truncatedEvents?: Event[];
    truncatedDate?: Date;
    visible: boolean;
}

interface AgendaEvent extends Omit<EventProps, 'event'> {
    event: CalendarEvent;
}

interface Props {
    data: Event[];
    selectedEvent: Event | null;
    onCloseCreateDialog: () => void;
    onCloseDeleteDialog: () => void;
    onDeleteEvent: (eventID: string) => void;
    onShowDeleteDialog: () => void;
    onShowCreateDialog: (event?: MaybeEvent) => void;
    onSelectEvent: (event: Event | null) => void;
    showCreateDialog: boolean;
    showDeleteDialog: boolean;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
}

const CREATE_FORM_ID = 'create-event-form';

const locales = {
    'en-AU': enAu,
};

const localizer = dateFnsLocalizer({
    format,
    getDay,
    locales,
    parse,
    startOfWeek,
});

const CalendarView = (props: Props) => {
    const {
        data,
        onCloseCreateDialog,
        onCloseDeleteDialog,
        onDeleteEvent,
        onSelectEvent,
        onShowCreateDialog,
        onShowDeleteDialog,
        selectedEvent,
        showCreateDialog,
        showDeleteDialog,
    } = props;

    const [showMoreConfig, setShowMoreConfig] = useState<TruncatedEventsModal>({
        truncatedDate: undefined,
        truncatedEvents: [],
        visible: false,
    });
    const eventPopover = useRef<OverlayPanel>(null);
    const selectedEventID = selectedEvent?.eventID;
    const eventSingleFormToast = useRef<Toast>(null);

    const eventFormToast = (isCreate: boolean) => {
        eventSingleFormToast.current?.show({
            severity: isCreate ? 'success' : 'warn',
            summary: isCreate ? 'New event created' : 'Event changes saved',
            detail: isCreate
                ? 'Your event has been created and is ready to manage.'
                : 'This events details have been updated.',
        });
    };

    /**
     * @desc Modal create/edit/delete content
     * @returns JSX
     */
    const eventCreateContent = (
        <EventForm
            eventID={selectedEvent?.eventID}
            formID={CREATE_FORM_ID}
            initialValues={
                selectedEvent && !selectedEvent.eventID && selectedEvent
            }
            hasSubmitUI={false}
            onCancel={onCloseCreateDialog}
            onSubmit={eventFormToast}
        />
    );

    const eventDeleteContent = selectedEvent && (
        <span>
            Are you sure you want to delete <b>{selectedEvent.eventName}</b>?
        </span>
    );

    /**
     * @desc Create/Edit Modal actions
     * @returns Actions[]
     */
    const formFooterActions = [
        {
            form: CREATE_FORM_ID,
            icon:
                selectedEvent && selectedEvent.eventID
                    ? 'sync'
                    : 'check_circle',
            label:
                selectedEvent && selectedEvent.eventID
                    ? 'Update Details'
                    : 'Create Event',
            severity:
                selectedEvent && selectedEvent.eventID
                    ? SEVERITY.warning
                    : SEVERITY.success,
            type: ACTION_TYPES.submit,
        },
    ];

    /**
     * @desc Delete Modal actions
     * @returns Actions[]
     */
    const deleteFooterActions = [
        {
            icon: 'delete',
            label: 'Delete',
            severity: SEVERITY.danger,
            command: () =>
                selectedEventID ? onDeleteEvent(selectedEventID) : undefined,
        },
    ];

    /**
     * @desc checks passed in start/end data + time and returns a check on event extending a full day.
     * @param startDateTime
     * @param endDateTime
     * @returns boolean
     */
    const allDayCheck = (startDateTime: number, endDateTime: number) => {
        let isAllDay = false;

        const endDay = new Date(endDateTime).getDate();
        const nextDay = new Date(startDateTime).getDate() + 1;
        const endTime = format(endDateTime, 'H:mm:ss');

        if (nextDay === endDay && endTime === '0:00:00') {
            isAllDay = true;
        }

        return isAllDay;
    };

    const components = useMemo<Components>(() => {
        /**
         * @desc single event render method.
         * @param eventData
         * @param eventView
         * @returns JSX.Element
         */
        const renderEvent = (eventData: EventProps, eventView: string) => {
            const allDayEvent = allDayCheck(
                // @ts-expect-error
                eventData.event.startDateTime,
                // @ts-expect-error
                eventData.event.endDateTime
            );

            return (
                <ListItem
                    caption={eventData.title}
                    title={
                        eventView === 'week' && allDayEvent
                            ? 'All-Day'
                            : isSameDay(
                                  // @ts-expect-error
                                  eventData.event.startDateTime,
                                  // @ts-expect-error
                                  eventData.event.endDateTime
                              )
                            ? // @ts-expect-error
                              format(eventData.event.startDateTime, 'h:mmaaa') +
                              ' - ' +
                              // @ts-expect-error
                              format(eventData.event.endDateTime, 'h:mmaaa')
                            : // @ts-expect-error
                              format(eventData.event.startDateTime, 'h:mmaaa')
                    }
                    icon={
                        // @ts-expect-error
                        eventData.event.eventType === 'Game'
                            ? 'stadium'
                            : // @ts-expect-error
                            eventData.event.eventType === 'Testing'
                            ? 'inventory'
                            : // @ts-expect-error
                            eventData.event.eventType === 'Training'
                            ? 'fitness_center'
                            : 'event_available'
                    }
                    className="event-calendar_chip"
                    listInline={
                        eventView === 'month' ||
                        eventView === 'agenda' ||
                        (eventView === 'week' && allDayEvent)
                    }
                    proportion="compact"
                />
            );
        };

        return {
            toolbar: (cal: ToolbarProps) => {
                return (
                    <Toolbar
                        left={
                            <>
                                <div className="p-buttonset">
                                    <Button
                                        className="p-button-icon-only p-button-sm p-button-secondary"
                                        onClick={() =>
                                            cal.onNavigate(Navigate.PREVIOUS)
                                        }
                                    >
                                        <ButtonIcon
                                            iconName="chevron_left"
                                            size="small"
                                        />
                                    </Button>
                                    <Button
                                        className="p-button-sm p-button-secondary"
                                        onClick={() =>
                                            cal.onNavigate(Navigate.TODAY)
                                        }
                                    >
                                        <ButtonIcon
                                            iconName="today"
                                            placement="left"
                                            size="small"
                                        />
                                        <span className="p-button-label">
                                            Today
                                        </span>
                                    </Button>
                                    <Button
                                        className="p-button-icon-only p-button-sm p-button-secondary"
                                        onClick={() =>
                                            cal.onNavigate(Navigate.NEXT)
                                        }
                                    >
                                        <ButtonIcon
                                            iconName="chevron_right"
                                            size="small"
                                        />
                                    </Button>
                                </div>
                                <h4 className="toolbar-title">{cal.label}</h4>
                            </>
                        }
                        right={
                            <>
                                <Button
                                    className="p-button-sm"
                                    onClick={() => onShowCreateDialog()}
                                >
                                    <ButtonIcon
                                        iconName="add_circle"
                                        size="small"
                                        placement="left"
                                    />
                                    <span className="p-button-label">
                                        Create Event
                                    </span>
                                </Button>
                                <SelectButton
                                    value={cal.view}
                                    onChange={(e) => cal.onView(e.value)}
                                    options={
                                        isArray(cal.views)
                                            ? cal.views.map((view) => ({
                                                  className: 'p-button-sm',
                                                  label: (
                                                      <Icon
                                                          name={
                                                              view === 'month'
                                                                  ? 'calendar_view_month'
                                                                  : view ===
                                                                    'week'
                                                                  ? 'calendar_view_week'
                                                                  : 'calendar_view_day'
                                                          }
                                                          size="small"
                                                      />
                                                  ),
                                                  value: view,
                                              }))
                                            : []
                                    }
                                />
                            </>
                        }
                    />
                );
            },
            list: {
                event: (comp: AgendaEvent) => {
                    // @ts-expect-error
                    return renderEvent(comp, 'agenda');
                },
            },
            week: {
                event: (comp: EventProps) => {
                    return renderEvent(comp, 'week');
                },
            },
            month: {
                event: (comp: EventProps) => {
                    return renderEvent(comp, 'month');
                },
            },
        };
    }, [onShowCreateDialog]);

    const formats = useMemo<Formats>(
        () => ({
            agendaHeaderFormat: (date, culture, localizer) => {
                const { start, end } = date;
                const startFormat =
                    start.getFullYear() !== end.getFullYear()
                        ? 'MMMM yyyy'
                        : 'MMMM';

                return `${localizer?.format(
                    start,
                    startFormat,
                    culture
                )} - ${localizer?.format(end, 'MMMM yyyy', culture)}`;
            },
        }),
        []
    );

    const accessors = useMemo(
        () => ({
            titleAccessor: 'eventName',
            tooltipAccessor: 'eventName',
            startAccessor: 'startDateTime',
            endAccessor: 'endDateTime',
        }),
        []
    );

    const { views } = useMemo(
        () => ({
            views: {
                month: true,
                week: true,
                list: CalendarAgenda,
            },
        }),
        []
    );

    const events = data.map((event: Event) => ({
        ...event,
        arrivalDateTime:
            event.arrivalDateTime && new Date(event.arrivalDateTime),
        startDateTime: event.startDateTime && new Date(event.startDateTime),
        endDateTime: event.endDateTime && new Date(event.endDateTime),
    }));

    /**
     * @desc custom classes applied along-side .event-wrapper
     * @param event
     * @returns string
     */
    const eventWrapperProps = (event: { eventType: EventType }) => {
        return {
            className: ` event-item_wrapper ${event.eventType.toLowerCase()}-event`,
        };
    };

    /**
     * @desc Modal onHide handler
     * @return useState
     */
    const closeModalHandler = () => {
        setShowMoreConfig({ visible: false });
    };

    /**
     * @desc Map over truncated events to be displayed within modal.
     * @param truncatedEvents
     * @returns JSX.Element[]
     */
    const mappedTruncatedEvents =
        showMoreConfig.truncatedEvents &&
        showMoreConfig.truncatedEvents.length > 0 &&
        showMoreConfig.truncatedEvents.map((truncEvent) => {
            return (
                <div
                    className={`rbc-event event-item_wrapper ${truncEvent.eventType.toLowerCase()}-event`}
                >
                    <div
                        className="rbc-event-content"
                        onClick={(e) => {
                            if (onSelectEvent) {
                                onSelectEvent(truncEvent);
                            }
                            if (eventPopover.current) {
                                eventPopover.current.toggle(e);
                            }
                        }}
                    >
                        <ListItem
                            caption={
                                // TODO: clean up and pass in as helper method, this is second use of same code
                                truncEvent.endDateTime &&
                                isSameDay(
                                    new Date(truncEvent.startDateTime),
                                    new Date(truncEvent.endDateTime)
                                )
                                    ? format(
                                          new Date(truncEvent.startDateTime),
                                          'h:mmaaa'
                                      ) +
                                      ' - ' +
                                      format(
                                          new Date(truncEvent.endDateTime),
                                          'h:mmaaa'
                                      )
                                    : format(
                                          new Date(truncEvent.startDateTime),
                                          'h:mmaaa'
                                      )
                            }
                            title={truncEvent.eventName}
                            icon={
                                // TODO: clean up and pass in as helper method, this is second use of same code
                                truncEvent.eventType === 'Game'
                                    ? 'stadium'
                                    : truncEvent.eventType === 'Testing'
                                    ? 'inventory'
                                    : truncEvent.eventType === 'Training'
                                    ? 'fitness_center'
                                    : 'event_available'
                            }
                            className="event-calendar_chip"
                            proportion="compact"
                        />
                    </div>
                </div>
            );
        });

    /**
     * @desc render content for Modal component
     * @returns JSX.Element
     */
    const modalContent = showMoreConfig.truncatedEvents ? (
        <div className="truncated-events-wrapper">{mappedTruncatedEvents}</div>
    ) : (
        <ErrorDisplay
            errorType={ERROR_TYPES.notFound}
            desc="Try again or refresh your browser."
            title="Events not found"
        />
    );

    /**
     * @desc pass custom messages to replace rbc defaults
     * @returns string
     */
    const messages: Messages = {
        showMore: (total) => `${total} more`,
    };

    return (
        <>
            <Calendar
                eventPropGetter={eventWrapperProps}
                className="calendar-wrapper"
                events={events}
                // @ts-expect-error
                components={components}
                formats={formats}
                defaultView={Views.MONTH}
                localizer={localizer}
                onSelectEvent={(event, e) => {
                    if (onSelectEvent) {
                        onSelectEvent({
                            ...event,
                            arrivalDateTime:
                                event.arrivalDateTime &&
                                new Date(event.arrivalDateTime).getTime(),
                            startDateTime:
                                event.startDateTime &&
                                new Date(event.startDateTime).getTime(),
                            endDateTime:
                                event.endDateTime &&
                                new Date(event.endDateTime).getTime(),
                        });
                    }
                    if (eventPopover.current) {
                        eventPopover.current.toggle(e);
                    }
                }}
                onSelectSlot={(slot: SlotInfo) => {
                    if (onShowCreateDialog) {
                        onShowCreateDialog({
                            startDateTime: slot.start,
                            endDateTime: slot.end,
                            arrivalDateTime: slot.start,
                        });
                    }
                }}
                messages={messages}
                onShowMore={(events, date) => {
                    setShowMoreConfig({
                        truncatedDate: date,
                        // @ts-expect-error // TODO: Check
                        truncatedEvents: events,
                        visible: true,
                    });
                }}
                selectable
                views={views}
                {...accessors}
            />
            <Modal
                className="truncated-events"
                title={
                    showMoreConfig.truncatedDate
                        ? format(showMoreConfig.truncatedDate, 'd')
                        : ''
                }
                topic={
                    showMoreConfig.truncatedDate
                        ? format(showMoreConfig.truncatedDate, 'E')
                        : ''
                }
                content={modalContent}
                onHide={closeModalHandler}
                visible={showMoreConfig.visible}
                isBasic={true}
                maximizable={false}
            />
            <OverlayPanel
                appendTo="self"
                ref={eventPopover}
                className="calendar-popover"
                dismissable
                // onHide={() => {
                //     // TODO: get this working, does not remove active class when selectedEvent unset
                //     if (onSelectEvent) {
                //         onSelectEvent(null)
                //     }
                // }}
            >
                {selectedEvent && (
                    <CalendarPane
                        eventID={selectedEvent.eventID}
                        onClose={() => {
                            if (eventPopover.current) {
                                eventPopover.current.hide();
                            }
                        }}
                        onDelete={() => {
                            if (eventPopover.current) {
                                eventPopover.current.hide();
                            }
                            if (onShowDeleteDialog) {
                                onShowDeleteDialog();
                            }
                        }}
                        onEdit={() => {
                            if (eventPopover.current) {
                                eventPopover.current.hide();
                            }
                            if (onShowCreateDialog) {
                                onShowCreateDialog(selectedEvent);
                            }
                        }}
                    />
                )}
            </OverlayPanel>
            <Modal
                topic={
                    selectedEvent && selectedEvent.eventID
                        ? 'Edit: '
                        : 'Create New Event'
                }
                title={
                    selectedEvent && selectedEvent.eventID
                        ? selectedEvent.eventName
                        : ''
                }
                actions={formFooterActions}
                content={eventCreateContent}
                onHide={onCloseCreateDialog}
                visible={showCreateDialog}
                maximizable={false}
            />
            <Modal
                topic="Confirm Deletion:"
                title={selectedEvent ? selectedEvent.eventName : ''}
                actions={deleteFooterActions}
                // @ts-expect-error
                content={eventDeleteContent}
                onHide={onCloseDeleteDialog}
                visible={showDeleteDialog}
                maximizable={false}
            />
            <Toast ref={eventSingleFormToast} />
        </>
    );
};

export default CalendarView;
