import { PaginatedResponse, Response } from './api';
import { Design } from './design';

// Team

export enum TeamStatus {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted',
}

export interface Team {
    teamID: string;
    teamName: string;
    shortName: string;
    teamStatus: string;
    parentPlaceholderName: string;
    age: string;
    gender: string;
    grade: string;
    structure1: string;
    structure2: string;
    design: Design;
    lastEdited: string;
    lastEditedBy: string;
    createdAt: string;
    createdBy: string;
}

export interface TeamForm {
    userID?: string;
    teamID?: string;
    typeID?: string;
    teamName: string;
    shortName: string;
    seasonName?: string;
    parentPlaceholderName?: string;
    age: string;
    gender: string;
    grade: string;
    design: Design;
    organisationID?: string;
}

// Staff

export interface TeamStaff {
    created_at: string;
    displayName: string;
    entityPreview: {
        entityName: string;
        shortName: string;
    };
    entityStatus: string;
    family_name: string;
    given_name: string;
    last_login: string;
    permissions: [];
    picture: string;
    roles: string[];
    roleID: string;
    roleName: string;
    shortName: string;
    teamID: string;
    teamName: string;
    teamStatus: TeamStatus;
    updated_at: string;
    userID: string;
}

export interface TeamStaffForm {
    email: string;
}

export interface OpponentTeam {
    teamName: string;
    shortName: string;
    design: Design;
}

// Players

export enum PlayerStatus {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted',
}

export interface Player {
    playerID: string;
    firstName: string;
    lastName: string;
    displayName: string;
    uniformNumber: string;
    dateOfBirth: string;
    teamID: string;
    teamName: string;
    status: PlayerStatus;
    colour: string;
    lastEditedBy: string;
    gender: string;
    permissions: [];
    image;
    playerImageURL;
    startDate: string;
    endDate: TeamStatus;
    createdAt: string;
    createdBy: string;
    lastEdited: string;
}

export interface PlayerForm {
    firstName: string;
    lastName: string;
    displayName: string;
    uniformNumber: string;
    dateOfBirth: string;
    gender: string;
    colour: string;
    startDate: string;
    endDate: string;
}

// Team Options

export enum TeamSport {
    Australian_Football = 'Australian Football',
    Soccer = 'Soccer',
    Basketball = 'Basketball',
}

export enum TeamCategory {
    Club = 'Club',
    School = 'School',
    Recreational = 'Recreational',
}

export enum TeamGrade {
    Blue = 'Blue',
    Green = 'Green',
    Red = 'Red',
    Yellow = 'Yellow',
    North = 'North',
    South = 'South',
    East = 'East',
    North = 'North',
    West = 'West',
}

export enum TeamGender {
    Male = 'Male',
    Female = 'Female',
    Mixed = 'Mixed',
}

export enum TeamAge {
    Open = 'Open',
    Under_8 = 'Under 8',
    Year_3 = 'Year 3',
    Under_9 = 'Under 9',
    Year_4 = 'Year 4',
    Under_10 = 'Under 10',
    Year_5 = 'Year 5',
    Under_11 = 'Under 11',
    Year_6 = 'Year 6',
    Under_12 = 'Under 12',
    Under_12_5 = 'Under 12.5',
    Year_7 = 'Year 7',
    Under_13 = 'Under 13',
    Under_13_5 = 'Under 13.5',
    Year_8 = 'Year 8',
    Under_14 = 'Under 14',
    Under_14_5 = 'Under 14.5',
    Year_9 = 'Year 9',
    Under_15 = 'Under 15',
    Under_15_5 = 'Under 15.5',
    Year_10 = 'Year 10',
    Under_16 = 'Under 16',
    Under_16_5 = 'Under 16.5',
    Year_11 = 'Year 11',
    Under_17 = 'Under 17',
    Under_17_5 = 'Under 17.5',
    Year_12 = 'Year 12',
    Under_18 = 'Under 18',
    Under_19 = 'Under 19',
    Under_20 = 'Under 20',
    Under_21 = 'Under 21',
    Under_22 = 'Under 22',
    Under_23 = 'Under 23',
    Junior_Colt = 'Junior Colt',
    Senior_Colts = 'Senior Colts',
    Colts = 'Colts',
    Development = 'Development',
    Over_35 = 'Over 35',
    Over_40 = 'Over 40',
    Over_45 = 'Over 45',
    Over_50 = 'Over 50',
    Over_55 = 'Over 55',
    Over_60 = 'Over 60',
    Over_65 = 'Over 65',
}

// Responses

export type PlayerResponse = PaginatedResponse<Player[]>;
export type TeamStaffResponse = PaginatedResponse<TeamStaff[]>;
export type TeamsResponse = PaginatedResponse<Team[]>;
export type TeamResponse = Response<Team>;
