import React, { useMemo, useRef } from 'react';
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import TeamForm from './TeamForm';

import EntityAvatar from '../../components/EntityAvatar';
import ErrorDisplay from '../../components/ErrorDisplay';
import Modal from '../../components/Modal';
import PageHeader from '../../components/page/PageHeader';
import ButtonIcon from '../../components/ButtonIcon';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';

import { ERROR_TYPES, SEVERITY } from '../../types/common.d';
import { sortBy } from 'lodash';

const TeamListingView = (props) => {
    const { isError, isFetching, isLoading, onGenerateCode } = props;

    const navigate = useNavigate();

    const teamCodeToast = useRef(null);
    const formResponseToast = useRef();

    const { organisationID } = useParams();

    const [searchParams, setSearchParams] = useSearchParams('');

    const openCreationPortal = () => {
        if (!searchParams.get('form')) {
            setSearchParams((params) => {
                params.set('form', 'create');
                return params;
            });
        }
    };

    const closeCreationPortal = () => {
        if (searchParams.get('form')) {
            setSearchParams((params) => {
                params.delete('form');
                return params;
            });
        }
    };

    const handleSubmitResult = (submitResult) => {
        const hasSubmitted = submitResult === 'success';
        const hasUpdated = submitResult === 'updated';

        formResponseToast.current?.show({
            severity: hasSubmitted || hasUpdated ? 'success' : 'warn',
            summary: hasUpdated
                ? `Team Updated`
                : hasSubmitted
                ? `Team Added`
                : `Team Addition Unsuccessful`,
            detail: hasUpdated
                ? `The Team details have been amended.`
                : hasSubmitted
                ? `The new Team has been successfully added.`
                : `Team creation could not be completed, try again later.`,
        });
    };

    const handleCodeClick = (code) => {
        // Copy the code
        navigator.clipboard.writeText(code);

        // Show toast
        teamCodeToast.current.show({
            summary: 'Team code copied',
            detail: code,
        });
    };

    const columns = useMemo(() => {
        const nameTemplate = (data) => {
            const route = organisationID
                ? `/o/${organisationID}/t/${data.teamID}`
                : `/t/${data.teamID}`;
            return (
                <Link to={route} className="list-item link-item">
                    <EntityAvatar
                        entityID={data.teamID}
                        entityName={data.teamName}
                        entityType="teams"
                    />
                    {data.teamName}
                </Link>
            );
        };

        const joinCodeTemplate = (data, row) => {
            return data.teamJoinMode !== 'Disabled' ? (
                <SplitButton
                    model={[
                        {
                            command: () => onGenerateCode('Disabled', data),
                            label: 'Disable code',
                        },
                    ]}
                    icon={
                        <ButtonIcon placement="right" iconName="content_copy" />
                    }
                    label={data.teamJoinCode}
                    onClick={() => handleCodeClick(data.teamJoinCode)}
                    className="p-button-rounded p-button-secondary"
                />
            ) : (
                <SplitButton
                    label="Generate Code"
                    model={[
                        {
                            command: () => onGenerateCode('SingleUse', data),
                            label: 'Single Use',
                        },
                    ]}
                    className="p-button-secondary p-button-rounded"
                    onClick={() => onGenerateCode('Enabled', data)}
                >
                    Generate
                </SplitButton>
            );
        };

        return [
            {
                body: isLoading ? <Skeleton /> : nameTemplate,
                field: 'teamName',
                header: 'Name',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : null,
                field: 'age',
                header: 'Age',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : null,
                field: 'gender',
                header: 'Gender',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : null,
                field: 'grade',
                header: 'Grade',
                sortable: true,
            },
            {
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (data) => format(parseISO(data.lastEdited), 'dd/MM/yyyy')
                ),
                field: 'lastEdited',
                header: 'Last Edited',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : joinCodeTemplate,
                field: 'teamJoinCode',
                header: 'Join Code',
                sortable: false,
            },
        ];
    }, [organisationID, isLoading, onGenerateCode]);

    const mappedColumns = columns.map((col, i) => (
        <Column key={`col-${i}`} {...col} />
    ));

    const headerActions = [
        {
            command: props.onImportClick,
            icon: 'upload_file',
            key: 'import',
            label: 'Import Teams',
            severity: SEVERITY.secondary,
        },
        {
            command: () => openCreationPortal(),
            icon: 'add_circle',
            key: 'create',
            label: 'Create New',
            severity: SEVERITY.primary,
        },
    ];

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              command: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                              severity: SEVERITY.primary,
                          },
                      ]
                    : headerActions
            }
            alignment="middle"
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : 'Create your first team to get started.'
            }
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No Teams found`}
        />
    );

    // empty array to populate rows for skeleton loading components
    const blankRows = Array.from({ length: 5 });

    const footerTemplate = () => {
        return (
            <Button
                onClick={props.onLoadMore}
                className={`p-button-${SEVERITY.secondary} p-button-sm`}
                label="Load more"
            >
                <ButtonIcon iconName="pending" placement="right" />
            </Button>
        );
    };

    return (
        <div className={`page view`}>
            <div className="view-inner">
                <PageHeader title="Teams" actions={headerActions} />
                <DataTable
                    value={
                        isLoading ? blankRows : sortBy(props.data, 'teamName')
                    }
                    loading={isFetching && !isLoading}
                    footer={
                        !isFetching && props.showPagination
                            ? footerTemplate
                            : null
                    }
                    emptyMessage={tableEmptyMessage}
                    responsiveLayout="scroll"
                >
                    {isLoading
                        ? mappedColumns
                        : props.data.length > 0
                        ? mappedColumns
                        : null}
                </DataTable>

                {searchParams.get('form') && (
                    <Modal
                        content={
                            <TeamForm
                                onSubmit={handleSubmitResult}
                                onCancel={() => closeCreationPortal()}
                            />
                        }
                        hasFooter={false}
                        maximizable={false}
                        onHide={() => {
                            closeCreationPortal();
                        }}
                        title="Create Team"
                        visible={true}
                    />
                )}
                <Toast ref={formResponseToast} />
                <Toast ref={teamCodeToast} />
            </div>
        </div>
    );
};

export default TeamListingView;
