import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { generatePath } from 'react-router-dom';
import { Event, EventResponse, EventsResponse } from '../types/event';

export const eventsApi = createApi({
    reducerPath: 'eventsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['Event'],
    endpoints: (builder) => ({
        getUserEvents: builder.query<EventsResponse, { userID: string }>({
            query: ({ userID }) => ({
                url: generatePath(apiEndpoints.getEvents.url, { userID }),
                method: apiEndpoints.getEvents.method,
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ eventID }: Event) => ({
                              type: 'Event' as const,
                              eventID,
                          })),
                          'Event',
                      ]
                    : ['Event'];
            },
        }),
        getUserEvent: builder.query<
            EventResponse,
            { userID: string; eventID: string }
        >({
            query: ({ userID, eventID }) => ({
                url: generatePath(apiEndpoints.getEvent.url, {
                    userID,
                    eventID,
                }),
                method: apiEndpoints.getEvent.method,
            }),
            providesTags: ['Event'],
        }),
        createUserEvent: builder.mutation({
            query: ({ userID, ...data }) => ({
                url: generatePath(apiEndpoints.createEvent.url, { userID }),
                method: apiEndpoints.createEvent.method,
                body: data,
            }),
            invalidatesTags: ['Event'],
        }),
        updateUserEvent: builder.mutation({
            query: ({ userID, ...data }) => ({
                url: generatePath(apiEndpoints.updateEvent.url, { userID }),
                method: apiEndpoints.updateEvent.method,
                body: data,
            }),
            invalidatesTags: ['Event'],
        }),
        deleteUserEvent: builder.mutation({
            query: ({ userID, eventID }) => ({
                url: generatePath(apiEndpoints.deleteEvent.url, {
                    userID,
                    eventID,
                }),
                method: apiEndpoints.deleteEvent.method,
            }),
            invalidatesTags: ['Event'],
        }),
        upsertUserEvent: builder.mutation({
            query: ({ eventID, userID, ...data }) => ({
                url: generatePath(
                    eventID
                        ? apiEndpoints.updateEvent.url
                        : apiEndpoints.createEvent.url,
                    { eventID, userID }
                ),
                method: eventID
                    ? apiEndpoints.updateEvent.method
                    : apiEndpoints.createEvent.method,
                body: data,
            }),
            invalidatesTags: ['Event'],
        }),
    }),
});

export const {
    useGetUserEventQuery,
    useGetUserEventsQuery,
    useCreateUserEventMutation,
    useUpdateUserEventMutation,
    useDeleteUserEventMutation,
    useUpsertUserEventMutation,
} = eventsApi;
