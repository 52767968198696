import { useRef } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { orderBy } from 'lodash';

import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
//import { SelectButton } from 'primereact/selectbutton';
import { Skeleton } from 'primereact/skeleton';
import { TabPanel, TabView } from 'primereact/tabview';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
//import { Toolbar } from 'primereact/toolbar';

import ButtonIcon from '../../components/ButtonIcon';
import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import PageHeader from '../../components/page/PageHeader';

import EventForm from './EventForm';

import { ACTION_TYPES, ERROR_TYPES, SEVERITY } from '../../types/common.d';
import { Event } from '../../types/event.d';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';

export enum EventStatus {
    Live = 'Live',
    Complete = 'Complete',
}

interface Props {
    data: Event[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onClickEvent: (event: Event) => void;
    onCloseEventDialog: () => void;
    onCreateEvent: () => void;
    onDeleteEvent: (event: Event) => void;
    onEditEvent: (event: Event) => void;
    onLoadMore: () => void;
    onSelectEvent: (event: Event | null) => void;
    onStatusFilter: (key: string) => void;
    roles: Roles;
    route: Route;
    selectedEvent: Event | null;
    showEventDialog: boolean;
    showPagination: boolean;
    status: string;
    activeTab?: any;
    orderBy?: 'asc' | 'desc';
}

const CREATE_FORM_ID = 'create-event-form';

const statusColour = {
    Draft: 'transparent',
    Published: 'transparent',
    Live: '#10B981',
    Complete: '#101010',
};

const EventsTable = (props: Props) => {
    const menu = useRef<Menu>(null);
    const blankRows = Array.from({ length: 5 });
    //const { teamID } = useParams();

    const emptyTemplate = (
        <ErrorDisplay
            alignment="middle"
            desc={`${props.activeTab.title} events will appear here.`}
            errorType={ERROR_TYPES.empty}
            hasReturn={false}
            proportion="compact"
            title="No Events"
        />
    );

    /*
    const headerTemplate = (
        <Toolbar
            left={
                <div className="p-buttonset">
                    <SelectButton
                        value={props.status}
                        onChange={(e) => props.onStatusFilter(e.value)}
                        options={[
                            { label: 'All', value: false },
                            ...Object.keys(EventStatus).map((key) => ({
                                label: key,
                                value: key,
                            })),
                        ]}
                    />
                </div>
            }
        />
    );
    */

    const footerTemplate = () => {
        return (
            <Button
                onClick={props.onLoadMore}
                className={`p-button-${SEVERITY.secondary} p-button-sm`}
                label="Load more"
            >
                <ButtonIcon iconName="pending" placement="right" />
            </Button>
        );
    };

    const actionTemplate = (rowData: Event) => {
        return (
            <div style={{ textAlign: 'right' }}>
                <Button
                    type="button"
                    className="p-button-sm p-button-text p-button-icon-only"
                    onClick={(event) => {
                        props.onSelectEvent(rowData);
                        menu?.current && menu.current.toggle(event);
                    }}
                >
                    <ButtonIcon iconName="more_horiz" />
                </Button>
            </div>
        );
    };

    const rowMenuItems = [
        {
            command: () => {
                props.selectedEvent && props.onEditEvent(props.selectedEvent);
            },
            label: 'Edit',
            icon: <Icon name="edit" className="p-menuitem-icon" />,
        },
        {
            command: () =>
                props.selectedEvent && props.onDeleteEvent(props.selectedEvent),
            label: 'Delete',
            icon: <Icon name="delete" className="p-menuitem-icon" />,
        },
    ];

    const columnSchema = [
        {
            field: 'eventName',
            header: 'Name',
            body: props.isLoading && <Skeleton />,
        },
        {
            field: 'startDate',
            header: 'Date',
            body: props.isLoading ? (
                <Skeleton />
            ) : (
                (row: Event) =>
                    format(new Date(row.startDateTime), 'eeee, MMMM do')
            ),
        },
        {
            field: 'startTime',
            header: 'Time',
            body: props.isLoading ? (
                <Skeleton />
            ) : (
                (row: Event) => format(new Date(row.startDateTime), 'h:mm aaa')
            ),
        },
        {
            field: 'locationInfo.route',
            header: 'Location',
            body: props.isLoading && <Skeleton />,
        },
        {
            field: 'eventStatus',
            header: 'Status',
            style: { width: '100px' },
            body: props.isLoading ? (
                <Skeleton />
            ) : (
                (row: Event) => (
                    <Tag
                        style={{
                            color:
                                row.eventStatus === 'Live' ||
                                row.eventStatus === 'Draft'
                                    ? '#000'
                                    : '#fff',
                            border: '1px solid rgba(0,0,0,0.3)',
                            background:
                                statusColour[
                                    row.eventStatus as keyof typeof statusColour
                                ],
                        }}
                    >
                        {row.eventStatus === 'Live' ||
                        row.eventStatus === 'Draft'
                            ? 'Pre-game'
                            : row.eventStatus}
                    </Tag>
                )
            ),
        },
        {
            field: 'actions',
            header: '',
            body: props.isLoading ? <Skeleton /> : actionTemplate,
        },
    ];

    const rowGroupHeaderTemplate = (data: Event) =>
        data &&
        data.dateGroup && (
            <strong className="image-text">{data.dateGroup}</strong>
        );

    const dataWithGroup = props.data.map((data) => ({
        ...data,
        dateGroup:
            data.startDateTime &&
            format(new Date(data.startDateTime), 'MMMM, Y'),
    }));

    const orderedData = orderBy(dataWithGroup, 'startDateTime', props.orderBy);

    return (
        <>
            <DataTable
                emptyMessage={emptyTemplate}
                footer={
                    !props.isFetching && props.showPagination
                        ? footerTemplate
                        : null
                }
                loading={props.isFetching && !props.isLoading}
                responsiveLayout="scroll"
                selectionMode="single"
                value={props.isLoading ? blankRows : orderedData}
                rowGroupMode={!props.isLoading ? 'subheader' : ''}
                groupRowsBy="dateGroup"
                rowGroupHeaderTemplate={rowGroupHeaderTemplate}
                onRowClick={(e) => props.onClickEvent(e.data)}
            >
                {props.isLoading ||
                    (props.data.length > 0 &&
                        columnSchema.map((col: ColumnProps) => (
                            <Column key={col.field} {...col} />
                        )))}
            </DataTable>
            <Menu model={rowMenuItems} popup ref={menu} />
        </>
    );
};

const EventsView = (props: Props) => {
    const { onCloseEventDialog, onCreateEvent, selectedEvent } = props;

    const params = useParams();
    const navigate = useNavigate();

    const eventSingleFormToast = useRef<Toast>(null);

    const eventFormToast = (isCreate: boolean) => {
        eventSingleFormToast.current?.show({
            severity: isCreate ? 'success' : 'warn',
            summary: isCreate ? 'New event created' : 'Event changes saved',
            detail: isCreate
                ? 'Your event has been created and is ready to manage.'
                : 'This events details have been updated.',
        });
    };

    const formFooterActions = [
        {
            form: CREATE_FORM_ID,
            icon:
                selectedEvent && selectedEvent.eventID
                    ? 'sync'
                    : 'check_circle',
            label:
                selectedEvent && selectedEvent.eventID
                    ? 'Update Details'
                    : 'Create Event',
            severity:
                selectedEvent && selectedEvent.eventID
                    ? SEVERITY.warning
                    : SEVERITY.success,
            type: ACTION_TYPES.submit,
        },
    ];

    const pageHeaderActions = [
        {
            key: 'create',
            label: 'Add Event',
            command: onCreateEvent,
            icon: 'add_circle',
            severity: SEVERITY.primary,
            type: ACTION_TYPES.button,
        },
    ];

    const tabs = [
        {
            id: 'past',
            title: 'Past',
            component: EventsTable,
        },
        {
            id: 'upcoming',
            title: 'Upcoming',
            component: EventsTable,
        },
    ];

    const activeTabIndex = tabs.findIndex((tab) => params.tab === tab.id);

    return (
        <>
            <div className={`page view member-view`}>
                <div className="view-inner">
                    <PageHeader
                        actions={pageHeaderActions}
                        title="Events"
                        roles={props.roles}
                    />
                    <TabView
                        activeIndex={activeTabIndex}
                        onTabChange={(e) =>
                            navigate(
                                generatePath(props.route.path, {
                                    ...params,
                                    tab: tabs[e.index].id,
                                })
                            )
                        }
                    >
                        {tabs.map(({ id, title, component: TabContent }) => (
                            <TabPanel key={title} header={title}>
                                <TabContent
                                    activeTab={tabs[activeTabIndex]}
                                    orderBy={id === 'past' ? 'desc' : 'asc'}
                                    {...props}
                                />
                            </TabPanel>
                        ))}
                    </TabView>
                </div>
            </div>

            <Modal
                title={
                    selectedEvent && selectedEvent.eventID
                        ? 'Edit Event'
                        : 'Create Event'
                }
                actions={formFooterActions}
                content={
                    <EventForm
                        formID={CREATE_FORM_ID}
                        eventID={selectedEvent?.eventID}
                        hasSubmitUI={false}
                        onCancel={onCloseEventDialog}
                        onSubmit={eventFormToast}
                    />
                }
                onHide={onCloseEventDialog}
                visible={props.showEventDialog}
                maximizable={false}
            />

            <Toast ref={eventSingleFormToast} />
        </>
    );
};

export default EventsView;
