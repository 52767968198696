import { useState } from 'react';
import {
    useDeleteUserEventMutation,
    useGetUserEventsQuery,
} from '../../api/events';
import CalendarView from './CalendarView';
import DocumentHead from '../../components/DocumentHead';

import { Event, MaybeEvent } from '../../types/event';
import { useParams } from 'react-router-dom';

const UserEventsContainer = () => {
    // State Hooks
    const { userID } = useParams();
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    // API Hooks
    const { data, isError, isFetching, isLoading } = useGetUserEventsQuery(
        {
            //@ts-expect-error
            userID,
        },
        {
            skip: !userID,
        }
    );

    const [deleteEvent] = useDeleteUserEventMutation();

    const handleShowCreate = (event?: MaybeEvent) => {
        if (event) setSelectedEvent(event);

        setShowCreateDialog(true);
    };

    const handleCloseCreate = () => {
        setSelectedEvent(null);
        setShowCreateDialog(false);
    };

    const handleShowDelete = () => {
        setShowDeleteDialog(true);
    };

    const handleCloseDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteEvent = (eventID: string) => {
        deleteEvent({ eventID })
            .then((response) => {
                console.log('DELETE EVENT SUCCESS', response);
            })
            .catch((err) => {
                console.warn('DELETE EVENT ERROR', err);
            })
            .finally(() => {
                setShowDeleteDialog(false);
            });
    };

    const handleSelectEvent = (value: Event | null) => {
        setSelectedEvent(value);
    };

    return (
        <>
            <DocumentHead
                title="Events - Rookie Me Hub"
                description="User events page"
            />
            <CalendarView
                data={data ? data.data : []}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                selectedEvent={selectedEvent}
                showDeleteDialog={showDeleteDialog}
                showCreateDialog={showCreateDialog}
                onCloseCreateDialog={handleCloseCreate}
                onCloseDeleteDialog={handleCloseDelete}
                onShowCreateDialog={handleShowCreate}
                onShowDeleteDialog={handleShowDelete}
                onDeleteEvent={handleDeleteEvent}
                onSelectEvent={handleSelectEvent}
            />
        </>
    );
};

export default UserEventsContainer;
