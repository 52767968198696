import { useRef } from 'react';
import {
    generatePath,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';

import Modal from '../../components/Modal';
import PageHeader from '../../components/page/PageHeader';

import StaffActiveContainer from './StaffActiveContainer';
import StaffForm from './StaffForm';
import StaffPane from './StaffPane';
import StaffPendingContainer from './StaffPendingContainer';

import { ACTION_TYPES, SEVERITY } from '../../types/common.d';
import { Roles } from '../../types/roles';
import { TeamStaff } from '../../types/team';
import { Route } from '../../types/route';

const STATUS_SUCCESS = 'success';

interface Props {
    data: TeamStaff[];
    focusedUser: TeamStaff | null;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onClickUser: (userID: string) => void;
    onCloseDeleteDialog: () => void;
    onCloseDrawer: () => void;
    onCloseInviteDialog: () => void;
    onDeleteUser: (userID: string) => void;
    onFocusUser: (user: TeamStaff) => void;
    onLoadMore?: () => void;
    onShowDeleteDialog: () => void;
    onShowInviteDialog: () => void;
    onViewUser: (userID: string) => void;
    roles: Roles;
    showInviteDialog: boolean;
    showDeleteDialog: boolean;
    showPagination: boolean;
    viewMedia?: string;
    route: Route;
}

const tabs = [
    {
        id: 'active',
        title: 'Active',
        component: StaffActiveContainer,
    },
    {
        id: 'pending',
        title: 'Pending',
        component: StaffPendingContainer,
    },
];

const StaffView = (props: Props) => {
    const staffFormToast = useRef<Toast>(null);

    const [searchParams] = useSearchParams();

    const params = useParams();
    const navigate = useNavigate();

    const activeTabIndex = tabs.findIndex((tab) => params.tab === tab.id);

    const handleSubmitResult = (submitResult: string) => {
        const hasSubmitted = submitResult === STATUS_SUCCESS;

        staffFormToast.current?.show({
            severity: hasSubmitted ? 'success' : 'warn',
            summary: hasSubmitted
                ? 'Staff Member Invited'
                : 'Invitation Unsuccessful',
            detail: hasSubmitted
                ? 'The invitation has been successfully sent.'
                : 'The Invitation failed to send, please check details and try again.',
        });

        if (submitResult === 'success') {
            props.onCloseInviteDialog();
        }
    };

    const pageHeaderActions = [
        {
            key: 'create',
            label: 'Add Staff',
            command: props.onShowInviteDialog,
            icon: 'add_circle',
            severity: SEVERITY.primary,
            type: ACTION_TYPES.button,
        },
    ];

    return (
        <div className={`page view member-view`}>
            <div className="view-inner">
                <PageHeader
                    actions={pageHeaderActions}
                    roles={props.roles}
                    title="Staff"
                />
                <TabView
                    activeIndex={activeTabIndex}
                    onTabChange={(e) =>
                        navigate(
                            generatePath(props.route.path, {
                                ...params,
                                tab: tabs[e.index].id,
                            })
                        )
                    }
                >
                    {tabs.map(({ title, component: TabContent }) => (
                        <TabPanel header={title}>
                            <TabContent {...props} />
                        </TabPanel>
                    ))}
                </TabView>
            </div>

            {searchParams.get('id') && (
                <StaffPane
                    staffID={searchParams.get('id')}
                    onClose={props.onCloseDrawer}
                />
            )}

            <Modal
                className="member-form-modal form-modal"
                content={
                    <StaffForm
                        onCancel={props.onCloseInviteDialog}
                        onSubmit={handleSubmitResult}
                    />
                }
                hasFooter={false}
                maximizable={false}
                onHide={props.onCloseInviteDialog}
                title="Add Staff"
                visible={props.showInviteDialog}
            />

            <Toast ref={staffFormToast} />
        </div>
    );
};

export default StaffView;
