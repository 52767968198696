import React, { useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { Menu } from 'primereact/menu';
import Icon from './Icon';

import { findMatchedRoutes } from '../util/helper';
import { routes } from '../routes/routes';
import { PathMatchWithRoute } from '../types/route';
import { Roles } from '../types/roles';

interface Props {
    roles: Roles;
    overflow?: boolean;
}

const BreadCrumbs = (props: Props) => {
    const crumbPopover = useRef<Menu>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const matches = findMatchedRoutes(routes, location.pathname);
    const crumbs = matches.sort((p1, p2) =>
        p1.pathname > p2.pathname ? 1 : p1.pathname < p2.pathname ? -1 : 0
    );

    /**
     * @todo Currently only swapping entity nodes - need to hook up to other node API's
     * @desc swap route params with node title, otherwise default to route title
     * @param crumb
     * @returns string
     */
    const getTitle = (crumb: PathMatchWithRoute) => {
        const lastUrlSegment = crumb.pattern.path.substring(
            crumb.pattern.path.lastIndexOf('/') + 1
        );
        const isParam = lastUrlSegment.startsWith(':');
        const lastParam = lastUrlSegment.slice(1, lastUrlSegment.length);
        const isParamEntity = [
            'associationID',
            'organisationID',
            'teamID',
        ].includes(lastParam);

        if (isParam && isParamEntity && props.roles) {
            const { params } = crumb;

            const entID =
                params.associationID || params.organisationID || params.teamID;

            const entity = props.roles.find((ent) => ent.entityID === entID);

            return entity ? entity.entityPreview.entityName : crumb.value.title;
        }

        return crumb.value.title;
    };

    const renderCrumb = (crumb: PathMatchWithRoute, active?: boolean) => (
        <li className={`p-menuitem${active ? ' p-menuitem-active' : ''}`}>
            <Link className="p-menuitem-link" to={crumb.pathname}>
                <span className="p-menuitem-text ">{getTitle(crumb)}</span>
            </Link>
        </li>
    );

    const renderDivider = () => (
        <Icon
            className="crumb-divider"
            name="keyboard_arrow_right"
            size="small"
        />
    );

    const renderCompact = () => {
        const firstCrumb = crumbs.shift() as PathMatchWithRoute;
        const lastCrumb = crumbs.pop() as PathMatchWithRoute;

        const menuCrumbs = crumbs.map((crumb) => ({
            key: crumb.value.id,
            id: crumb.value.id,
            label: getTitle(crumb),
            icon: crumb.value.icon && (
                <Icon name={crumb.value.icon} size="small" />
            ),
            command: () => navigate(crumb.pathname),
        }));

        return (
            <>
                {renderCrumb(firstCrumb)}
                {renderDivider()}
                <li className="p-menuitem p-breadcrumbs-consolidated">
                    <span
                        className="p-menuitem-link"
                        onClick={(e) =>
                            crumbPopover.current &&
                            crumbPopover.current.toggle(e)
                        }
                    >
                        <Icon name="more_horiz" size="small" />
                    </span>
                    <Menu model={menuCrumbs} popup={true} ref={crumbPopover} />
                </li>
                {renderDivider()}
                {renderCrumb(lastCrumb, true)}
            </>
        );
    };

    const renderFull = () => {
        return crumbs.map((crumb, i) => {
            return (
                <React.Fragment key={`crumb-${i}`}>
                    {renderCrumb(crumb)}
                    {i !== crumbs.length - 1 && renderDivider()}
                </React.Fragment>
            );
        });
    };

    return (
        <nav className="p-breadcrumb p-component" aria-label="Breadcrumb">
            <ul>
                {props.overflow && crumbs.length > 2
                    ? renderCompact()
                    : renderFull()}
            </ul>
        </nav>
    );
};

BreadCrumbs.defaultProps = {
    overflow: true,
};

export default BreadCrumbs;
