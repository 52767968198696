import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { isEqual } from 'lodash';

import {
    Team,
    TeamForm,
    TeamStaff,
    TeamResponse,
    TeamStaffResponse,
    PlayerResponse,
    Player,
} from '../types/team';

import {
    UserDetailResponse,
    UserDetailsResponse,
    UserForm,
} from '../types/user';

import { DesignResponse } from '../types/design';
import { StatResponse, TimeStatResponse } from '../types/game';
import { TicketResponse } from '../types/ticket';

export const teamsApi = createApi({
    reducerPath: 'teamsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['Team', 'Staff', 'Player'],
    endpoints: (builder) => ({
        getTeam: builder.query<TeamResponse, string>({
            query: (teamID) => ({
                url: generatePath(apiEndpoints.getTeam.url, { teamID }),
                method: apiEndpoints.getTeam.method,
            }),
            providesTags: ['Team'],
        }),
        createTeam: builder.mutation<Team, TeamForm>({
            query: ({ userID, ...data }) => ({
                url: generatePath(apiEndpoints.createTeam.url, { userID }),
                method: apiEndpoints.createTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),
        updateTeam: builder.mutation<Team, TeamForm>({
            query: ({ teamID, ...data }) => ({
                url: generatePath(apiEndpoints.updateTeam.url, { teamID }),
                method: apiEndpoints.updateTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),
        deleteTeam: builder.mutation<Team, { teamID: string }>({
            query: ({ teamID }) => ({
                url: generatePath(apiEndpoints.deleteTeam.url, { teamID }),
                method: apiEndpoints.deleteTeam.method,
            }),
            invalidatesTags: ['Team'],
        }),
        upsertTeam: builder.mutation({
            query: ({ teamID, userID, ...data }) => ({
                url: generatePath(
                    teamID
                        ? apiEndpoints.updateTeam.url
                        : apiEndpoints.createTeam.url,
                    {
                        teamID,
                        userID,
                    }
                ),
                method: teamID
                    ? apiEndpoints.updateTeam.method
                    : apiEndpoints.createTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),

        // Team Staff
        getTeamStaff: builder.query<
            TeamStaffResponse,
            { teamID: string; cursor: string }
        >({
            query: ({ teamID, cursor }) => ({
                url: generatePath(
                    `${apiEndpoints.getTeamStaff.url}${
                        cursor ? '?cursor=' + cursor : ''
                    }`,
                    { teamID }
                ),
                method: apiEndpoints.getTeamStaff.method,
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ userID }) => ({
                              type: 'Staff' as const,
                              userID,
                          })),
                          'Staff',
                      ]
                    : ['Staff'];
            },
        }),
        getTeamStaffDetails: builder.query<
            UserDetailsResponse,
            { teamID: string; cursor: string }
        >({
            query: ({ teamID, cursor }) => ({
                url: generatePath(
                    `${apiEndpoints.getTeamStaffDetails.url}${
                        cursor ? '?cursor=' + cursor : ''
                    }`,
                    { teamID }
                ),
                method: apiEndpoints.getTeamStaffDetails.method,
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ userID }) => ({
                              type: 'Staff' as const,
                              userID,
                          })),
                          'Staff',
                      ]
                    : ['Staff'];
            },
        }),
        getTeamStaffSingle: builder.query<
            TeamStaff,
            { teamID: string; staffID: string }
        >({
            query: ({ teamID, staffID }) => ({
                url: generatePath(apiEndpoints.getTeamStaffSingle.url, {
                    teamID,
                    staffID,
                }),
                method: apiEndpoints.getTeamStaffSingle.method,
            }),
            providesTags: ['Staff'],
        }),
        getTeamStaffDetailsSingle: builder.query<
            UserDetailResponse,
            { teamID: string; userID: string }
        >({
            query: ({ teamID, userID }) => ({
                url: generatePath(apiEndpoints.getTeamStaffDetailsSingle.url, {
                    teamID,
                    userID,
                }),
                method: apiEndpoints.getTeamStaffDetailsSingle.method,
            }),
            providesTags: ['Staff'],
        }),
        getTeamStaffInvites: builder.query({
            query: ({ teamID }) => ({
                url: generatePath(apiEndpoints.getTeamStaffInvites.url, {
                    teamID,
                }),
                method: apiEndpoints.getTeamStaffInvites.method,
            }),
        }),
        createTeamStaffInvite: builder.mutation<TicketResponse, UserForm>({
            query: ({ teamID, ...data }) => ({
                url: generatePath(apiEndpoints.createTeamStaffInvite.url, {
                    teamID,
                }),
                method: apiEndpoints.createTeamStaffInvite.method,
                body: data,
            }),
            invalidatesTags: ['Staff'],
        }),
        createTeamStaff: builder.mutation({
            query: ({ teamID, ...data }) => ({
                url: generatePath(apiEndpoints.createTeamStaff.url, { teamID }),
                method: apiEndpoints.createTeamStaff.method,
                body: data,
            }),
            invalidatesTags: ['Staff'],
        }),
        deleteTeamStaff: builder.mutation({
            query: ({ teamID, staffID }) => ({
                url: generatePath(apiEndpoints.deleteTeamStaff.url, {
                    teamID,
                    staffID,
                }),
                method: apiEndpoints.deleteTeamStaff.method,
            }),
            invalidatesTags: ['Staff'],
        }),
        resendTeamStaffInvite: builder.mutation<
            TicketResponse,
            { entityID: string }
        >({
            query: ({ entityID }) => ({
                url: generatePath(apiEndpoints.resendTicket.url, {
                    entityID,
                }),
                method: apiEndpoints.resendTicket.method,
            }),
            invalidatesTags: ['Staff'],
        }),
        revokeTeamStaffInvite: builder.mutation<
            TicketResponse,
            { entityID: string; entityType: string }
        >({
            query: ({ entityID, entityType }) => ({
                url: generatePath(apiEndpoints.revokeTicket.url, {
                    entityID,
                    entityType,
                }),
                method: apiEndpoints.revokeTicket.method,
            }),
            invalidatesTags: ['Staff'],
        }),

        // Team Players
        getPlayers: builder.query<
            PlayerResponse,
            { teamID: string; cursor: string; status?: string }
        >({
            query: ({ teamID, cursor, status = 'Active' }) => ({
                url: generatePath(
                    `${apiEndpoints.getPlayers.url}?status=${status}
                        ${cursor ? '&cursor=' + cursor : ''}`,
                    { teamID }
                ),
                method: apiEndpoints.getPlayers.method,
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ playerID }: Player) => ({
                              type: 'Player' as const,
                              playerID,
                          })),
                          'Player',
                      ]
                    : ['Player'];
            },
        }),
        getPlayer: builder.query({
            query: ({ teamID, playerID }) => ({
                url: generatePath(apiEndpoints.getPlayer.url, {
                    teamID,
                    playerID,
                }),
                method: apiEndpoints.getPlayer.method,
            }),
            providesTags: ['Player'],
        }),
        getPlayersByUser: builder.query({
            query: ({ userID }) => ({
                url: generatePath(apiEndpoints.getPlayersByUser.url, {
                    userID,
                }),
                method: apiEndpoints.getPlayersByUser.method,
            }),
            providesTags: ['Player'],
        }),
        createPlayer: builder.mutation({
            query: ({ teamID, ...data }) => ({
                url: generatePath(apiEndpoints.createPlayer.url, { teamID }),
                method: apiEndpoints.createPlayer.method,
                body: data,
            }),
            invalidatesTags: ['Player'],
        }),
        updatePlayer: builder.mutation({
            query: ({ teamID, playerID, ...data }) => ({
                url: generatePath(apiEndpoints.updatePlayer.url, {
                    teamID,
                    playerID,
                }),
                method: apiEndpoints.updatePlayer.method,
                body: data,
            }),
            invalidatesTags: ['Player'],
        }),
        upsertPlayer: builder.mutation({
            query: ({ teamID, playerID, ...data }) => ({
                url: generatePath(
                    playerID
                        ? apiEndpoints.updatePlayer.url
                        : apiEndpoints.createPlayer.url,
                    { playerID, teamID }
                ),
                method: playerID
                    ? apiEndpoints.updatePlayer.method
                    : apiEndpoints.createPlayer.method,
                body: data,
            }),
        }),
        deletePlayer: builder.mutation({
            query: ({ teamID, playerID }) => ({
                url: generatePath(apiEndpoints.deletePlayer.url, {
                    teamID,
                    playerID,
                }),
                method: apiEndpoints.deletePlayer.method,
            }),
            invalidatesTags: ['Player'],
        }),
        linkPlayerToUser: builder.mutation({
            query: ({ teamID, playerID, ...data }) => ({
                url: generatePath(apiEndpoints.linkPlayerToUser.url, {
                    teamID,
                    playerID,
                }),
                method: apiEndpoints.linkPlayerToUser.method,
                body: data,
            }),
            invalidatesTags: ['Player'],
        }),
        unlinkPlayerFromUser: builder.mutation({
            query: ({ teamID, playerID }) => ({
                url: generatePath(apiEndpoints.unlinkPlayerFromUser.url, {
                    teamID,
                    playerID,
                }),
                method: apiEndpoints.unlinkPlayerFromUser.method,
            }),
            invalidatesTags: ['Player'],
        }),

        // Team Events
        getTeamEvents: builder.query({
            query: ({ teamID, searchParams }) => ({
                url: `${generatePath(apiEndpoints.getTeamEvents.url, {
                    teamID,
                })}${searchParams ? '?' + searchParams : ''}`,
                method: apiEndpoints.getTeamEvents.method,
            }),
        }),
        getTeamEvent: builder.query({
            query: ({ eventID, teamID }) => ({
                url: generatePath(apiEndpoints.getTeamEvent.url, {
                    eventID,
                    teamID,
                }),
                method: apiEndpoints.getTeamEvent.method,
            }),
        }),
        createTeamEvent: builder.mutation({
            query: ({ teamID, data }) => ({
                url: generatePath(apiEndpoints.createTeamEvent.url, { teamID }),
                method: apiEndpoints.createTeamEvent.method,
                body: data,
            }),
        }),
        updateTeamEvent: builder.mutation({
            query: ({ eventID, teamID, ...data }) => ({
                url: generatePath(apiEndpoints.updateTeam.url, {
                    eventID,
                    teamID,
                }),
                method: apiEndpoints.updateTeamEvent.method,
                body: data,
            }),
        }),
        deleteTeamEvent: builder.mutation({
            query: ({ eventID, teamID }) => ({
                url: generatePath(apiEndpoints.deleteTeamEvent.url, {
                    eventID,
                    teamID,
                }),
                method: apiEndpoints.deleteTeamEvent.method,
            }),
        }),
        upsertTeamEvent: builder.mutation({
            query: ({ eventID, teamID, ...data }) => ({
                url: generatePath(
                    eventID
                        ? apiEndpoints.updateTeamEvent.url
                        : apiEndpoints.createTeamEvent.url,
                    { eventID, teamID }
                ),
                method: eventID
                    ? apiEndpoints.updateTeamEvent.method
                    : apiEndpoints.createTeamEvent.method,
                body: data,
            }),
        }),
        getTeamGameStats: builder.query<
            StatResponse,
            { eventID: string; teamID: string }
        >({
            query: ({ eventID, teamID }) => ({
                url: generatePath(apiEndpoints.getTeamGameStats.url, {
                    eventID,
                    teamID,
                }),
                method: apiEndpoints.getTeamGameStats.method,
            }),
        }),
        getTeamGameTimeStats: builder.query<
            TimeStatResponse,
            { eventID: string; teamID: string; report: string }
        >({
            query: ({ eventID, teamID, report = 'Basic' }) => ({
                // @TODO using temp data. Change to correct endpoint once available
                url: `https://d1danqrazdmf5j.cloudfront.net/teams/${teamID}/games/${eventID}?environment=${
                    process.env.NODE_ENV === 'production'
                        ? 'live'
                        : process.env.NODE_ENV === 'test'
                        ? 'staging'
                        : 'dev'
                }`,
                method: apiEndpoints.getTeamGameTimeStats.method,
            }),
        }),
        getTeamKit: builder.query({
            query: ({ teamID }) => ({
                url: generatePath(apiEndpoints.getTeamKit.url, {
                    teamID,
                }),
                method: apiEndpoints.getTeamKit.method,
                responseHandler: 'text',
            }),
        }),
        updateTeamKit: builder.mutation({
            query: ({ teamID, ...data }) => ({
                url: generatePath(apiEndpoints.updateTeamKit.url, {
                    teamID,
                }),
                method: apiEndpoints.updateTeamKit.method,
                body: data,
            }),
        }),
        getDesignOptions: builder.query<DesignResponse, void>({
            query: () => ({
                url: apiEndpoints.getDesignOptions.url,
                method: apiEndpoints.getDesignOptions.method,
            }),
        }),
    }),
});

export const {
    useGetTeamQuery,
    useCreateTeamMutation,
    useUpdateTeamMutation,
    useDeleteTeamMutation,
    useUpsertTeamMutation,

    useGetTeamStaffQuery,
    useLazyGetTeamStaffQuery,
    useGetTeamStaffSingleQuery,
    useCreateTeamStaffMutation,
    useDeleteTeamStaffMutation,
    useResendTeamStaffInviteMutation,

    useGetTeamStaffDetailsQuery,
    useLazyGetTeamStaffDetailsQuery,
    useCreateTeamStaffInviteMutation,
    useRevokeTeamStaffInviteMutation,
    useGetTeamStaffDetailsSingleQuery,
    useGetTeamStaffInvitesQuery,

    useGetPlayerQuery,
    useLazyGetPlayersQuery,
    useGetPlayersQuery,
    useGetPlayersByUserQuery,
    useCreatePlayerMutation,
    useUpdatePlayerMutation,
    useUpsertPlayerMutation,
    useDeletePlayerMutation,
    useLinkPlayerToUserMutation,
    useUnlinkPlayerFromUserMutation,

    useGetTeamEventsQuery,
    useLazyGetTeamEventsQuery,
    useGetTeamEventQuery,
    useCreateTeamEventMutation,
    useUpdateTeamEventMutation,
    useDeleteTeamEventMutation,
    useUpsertTeamEventMutation,

    useGetTeamGameStatsQuery,
    useGetTeamGameTimeStatsQuery,
    useLazyGetTeamGameTimeStatsQuery,

    useGetTeamKitQuery,
    useUpdateTeamKitMutation,
    useGetDesignOptionsQuery,
} = teamsApi;
