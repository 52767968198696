//@ts-nocheck
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { pick } from 'lodash';
import { apiEndpoints } from '../api/apiEndpoints';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Tag } from 'primereact/tag';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

export default function AccountForm(props: any) {
    const auth0 = useAuth0();
    const { user }: { user?: any } = auth0;
    const requiredFields: string[] = [
        'given_name',
        'family_name',
        'picture',
        'email',
    ];
    const [isVerifying, setIsVerifying] = useState(false);
    const [canVerify, setCanVerify] = useState(0);
    const [attemptedVerify, setAttemptedVerify] = useState(0);
    let verifyInterval: ReturnType<typeof setInterval>;

    const handleSubmit = async (
        data: any,
        { setSubmitting }: FormikHelpers<any>
    ) => {
        setSubmitting(true);
        try {
            setSubmitting(true);
            return await auth0
                .getAccessTokenSilently({ detailedResponse: true }) // get a detailed response
                .then((detailedResponse) => detailedResponse.id_token) // use just the stateful JWT
                .then((accessToken: string) => {
                    console.log(accessToken);
                    fetch(apiEndpoints.updateProfile.url, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        method: apiEndpoints.updateProfile.method,
                        body: JSON.stringify(data),
                    });
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((data) => {
                    auth0.getAccessTokenSilently({ ignoreCache: true }); // ignore the cache and force a refresh
                    return data;
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    setSubmitting(false);
                });
        } catch (error) {
            alert('error:', error);
            console.log('error:', error);
            setSubmitting(false);
        }
    };

    const handleVerify = async () => {
        setIsVerifying(true);
        try {
            return await auth0
                .getAccessTokenSilently({ detailedResponse: true }) // get a detailed response
                .then((detailedResponse) => detailedResponse.id_token) // use just the stateful JWT
                .then((accessToken: string) =>
                    fetch(apiEndpoints.verifyEmail.url, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        method: apiEndpoints.verifyEmail.method,
                    })
                )
                .then((response) => {
                    if (response.ok) {
                        setAttemptedVerify(true);
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((data: any) => {
                    console.log(data);

                    // show some notification?
                    return data;
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    setIsVerifying(false);
                    setCanVerify(30); // start a 30 sec timer to protect against spamming of the verify server
                    verifyInterval = setInterval(() => {
                        setCanVerify((prevState) => {
                            if (prevState === 1) {
                                clearInterval(verifyInterval);
                            }
                            return prevState - 1;
                        });
                    }, 1000);
                });
        } catch (error) {
            alert('error:', error);
            console.log('error:', error);
            setIsVerifying(false);
        }
    };

    const providerRaw = user.sub.substring(0, user.sub.indexOf('|'));
    const providers: { [key: string]: string } = {
        facebook: 'Facebook',
        gmail: 'Gmail',
        'google-oauth2': 'Gmail',
    };
    const providerNice = providers[providerRaw] || 'login';
    const canEditCore = user.sub.substring(0, 6) === 'auth0|';

    return (
        <Formik
            initialValues={pick(user, requiredFields)}
            validate={(values: any) => {
                const errors: { [key: string]: string } = {};
                requiredFields.forEach((field) => {
                    if (!values[field]) {
                        errors[field] = 'Required';
                    }
                });
                return errors;
            }}
            onSubmit={(values, formikHelpers) =>
                handleSubmit(values, formikHelpers)
            }
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }: /* and other goodies */
            FormikProps<any>) => (
                <form
                    className={'grid formgrid p-fluid'}
                    onSubmit={handleSubmit}
                >
                    {!canEditCore && (
                        <div className="mb-4 col-12">
                            <Message
                                text={
                                    'Please note, you will need to edit these details on your ' +
                                    providerNice +
                                    ' account.'
                                }
                                severity={'info'}
                            />
                        </div>
                    )}

                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="given_name" className="font-medium">
                            First Name
                        </label>
                        <InputText
                            id="given_name"
                            type="text"
                            onInput={handleChange}
                            value={values?.given_name}
                            disabled={!canEditCore}
                        />
                    </div>

                    {errors.given_name &&
                        touched.given_name &&
                        errors.given_name}

                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="family_name" className="font-medium">
                            Last Name
                        </label>
                        <InputText
                            id="family_name"
                            type="text"
                            onInput={handleChange}
                            value={values?.family_name}
                            disabled={!canEditCore}
                        />
                    </div>
                    {errors.family_name &&
                        touched.family_name &&
                        errors.family_name}

                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="email" className="font-medium">
                            Email
                        </label>
                        <div className="p-inputgroup">
                            <InputText
                                id="email"
                                type="text"
                                onInput={handleChange}
                                value={values?.email}
                                disabled={!canEditCore}
                            />
                            {!user.email_verified && canVerify === 0 && (
                                <Button
                                    icon="pi pi-check"
                                    className="p-button-warning"
                                    onClick={handleVerify}
                                    tooltip={
                                        'Click here to verify your email address'
                                    }
                                    disabled={isVerifying}
                                />
                            )}
                            {!user.email_verified && canVerify > 0 && (
                                <Button
                                    label={canVerify}
                                    className="p-button-warning"
                                    tooltip={
                                        'Please wait to try verifying your email again'
                                    }
                                    disabled
                                />
                            )}
                        </div>
                        {user.email_verified && (
                            <Tag icon={'pi pi-check'} severity={'success'}>
                                Verified
                            </Tag>
                        )}
                        {!user.email_verified && !!attemptedVerify && (
                            <span className={'text-sm'}>
                                This email has not been verified. Please check
                                your email to find your verification link.
                            </span>
                        )}
                        {!user.email_verified && !attemptedVerify && (
                            <span className={'text-sm'}>
                                This email has not been verified. If you believe
                                this is an error, please log out and log in
                                again.
                            </span>
                        )}
                    </div>
                    {errors.email && touched.email && errors.email}

                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="picture" className="font-medium">
                            Avatar
                        </label>
                        <div className="flex align-items-center">
                            <img
                                src={values?.picture}
                                alt="avatar-f-4"
                                className="mr-4"
                            />
                            {canEditCore && (
                                <input
                                    type="file"
                                    id="pictureUpload"
                                    name="pictureUpload"
                                    onChange={handleChange}
                                />
                            )}
                        </div>
                    </div>
                    {errors.pictureUpload &&
                        touched.pictureUpload &&
                        errors.pictureUpload}
                    <div
                        className="surface-100 mb-3 col-12"
                        style={{ height: '2px' }}
                    ></div>

                    <div className="col-12">
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            className="w-auto mt-3"
                            label={isSubmitting ? 'Loading...' : 'Submit'}
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
}
