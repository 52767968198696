import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import ButtonIcon from './ButtonIcon';
import { Avatar, AvatarProps } from 'primereact/avatar';
import { Button } from 'primereact/button';

type ActionItem = {
    icon?: string;
    text?: string;
    path?: string;
    command?: Function;
};

interface Props {
    actions?: ActionItem[];
    avatar?: AvatarProps;
    caption?: string | JSX.Element;
    carded?: boolean;
    className?: string;
    icon?: string;
    listInline?: boolean;
    proportion?: 'compact' | 'regular' | 'enlarged';
    title: string;
    trailingElement?: JSX.Element;
    startElement?: JSX.Element;
}

const ListItem = (props: Props) => {
    const {
        actions,
        avatar,
        caption,
        carded,
        className,
        icon,
        listInline,
        proportion,
        startElement,
        title,
        trailingElement,
    } = props;

    /***
     * @desc apply avatar size class modifier based on proportion prop
     * @param proportion: "compact" | "regular" | "enlarged"
     */
    const getAvatarSize = (proportion: Props['proportion']) => {
        switch (proportion) {
            case 'compact':
                return 'normal';
            case 'regular':
                return 'large';
            case 'enlarged':
                return 'xlarge';
            default:
                return 'normal';
        }
    };

    /***
     * @desc apply button size class modifier based on proportion prop
     * @param proportion: "compact" | "regular" | "enlarged"
     */
    const getButtonSize = (proportion: Props['proportion']) => {
        switch (proportion) {
            case 'compact':
                return 'p-button-sm';
            case 'regular':
                return '';
            case 'enlarged':
                return 'p-button-lg';
            default:
                return '';
        }
    };

    /***
     * @desc set title html tag type based on proportion prop
     * @param proportion: "compact" | "regular" | "enlarged"
     * @fallback <H5>
     */
    const getHeadingTag = (proportion: Props['proportion']) => {
        switch (proportion) {
            case 'compact':
                return 'h5';
            case 'regular':
                return 'h4';
            case 'enlarged':
                return 'h3';
            default:
                return 'h5';
        }
    };
    const HeadingTag = getHeadingTag(proportion);

    const captionModifier =
        proportion === 'compact'
            ? ' caption-sm'
            : proportion === 'enlarged'
            ? ' caption-lg'
            : '';

    const renderActions = actions?.map((action, i) => {
        return action.command ? (
            <Button
                key={`list-button-${i}`}
                className={`p-button-text p-button-plain ${getButtonSize(
                    proportion
                )}`}
                onClick={() => action.command && action.command()}
            >
                {action.icon && (
                    <ButtonIcon
                        iconName={action.icon}
                        placement={action.text ? 'left' : ''}
                    />
                )}
                {action.text && (
                    <span className="p-button-label">{action.text}</span>
                )}
            </Button>
        ) : action.path ? (
            <Link to={action.path} key={`list-button-${i}`}>
                <Button
                    className={`p-button-text p-button-plain ${getButtonSize(
                        proportion
                    )}`}
                >
                    {action.icon && (
                        <ButtonIcon
                            iconName={action.icon}
                            placement={action.text ? 'left' : ''}
                        />
                    )}
                    {action.text && (
                        <span className="p-button-label">{action.text}</span>
                    )}
                </Button>
            </Link>
        ) : null;
    });

    return (
        <div
            className={`list-item${carded ? ' p-card' : ''} ${
                className ? className : ''
            } ${listInline ? 'is-inlined' : ''}`}
        >
            <div className="list-item_inner">
                {/* listItem - left */}
                {startElement ? (
                    startElement
                ) : avatar ? (
                    <Avatar
                        image={avatar.image && avatar.image}
                        label={avatar.label && avatar.label.substring(0, 1)}
                        shape="circle"
                        size={proportion && getAvatarSize(proportion)}
                        template={avatar.template}
                    />
                ) : icon ? (
                    <Icon name={icon} />
                ) : null}

                {/* listItem - content */}
                <div className="list-item_content">
                    <div className="list-item_content-title">
                        <HeadingTag>{title}</HeadingTag>
                    </div>
                    {caption && (
                        <span
                            className={`list-item_content-subline${captionModifier}`}
                        >
                            {caption}
                        </span>
                    )}
                </div>

                {/* listItem - right */}
                {trailingElement ? (
                    trailingElement
                ) : actions ? (
                    <div className="list-item_actions">{renderActions}</div>
                ) : null}
            </div>
        </div>
    );
};

ListItem.defaultProps = {
    proportion: 'regular',
    listInline: false,
};

export default ListItem;
