import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import EventsSingleView from './EventSingleView';

import {
    useGetPlayersQuery,
    useGetTeamEventQuery,
    useGetTeamGameStatsQuery,
    useGetTeamGameTimeStatsQuery,
    useGetTeamQuery,
} from '../../api/teams';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { useGetStatsQuery } from '../../api/sports';

interface Props {
    roles: Roles;
    route: Route;
}

const EventsSingleContainer = (props: Props) => {
    const { tab, teamID, eventID } = useParams();

    const [showEventDialog, setShowEventDialog] = useState<boolean>(false);

    const teamData = useGetTeamQuery(teamID || '', { skip: !teamID });

    const playerData = useGetPlayersQuery(
        {
            teamID: teamID ? teamID : '',
            cursor: '',
            status: 'All',
        },
        {
            skip: !teamID,
        }
    );

    const eventData = useGetTeamEventQuery({
        eventID,
        teamID,
    });

    const statsConfig = useGetStatsQuery({
        sportID: 'aus_football_au',
    });

    const timeStatsData = useGetTeamGameTimeStatsQuery({
        eventID: eventID ? eventID : '',
        teamID: teamID ? teamID : '',
        report: '',
    });

    const gameStatsData = useGetTeamGameStatsQuery({
        eventID: eventID ? eventID : '',
        teamID: teamID ? teamID : '',
    });

    const handleEditEvent = () => {
        setShowEventDialog(true);
    };

    const handleCloseEventDialog = () => {
        setShowEventDialog(false);
    };

    if (!tab) {
        return <Navigate to="./details" />;
    }

    return (
        <EventsSingleView
            eventData={eventData}
            teamData={teamData}
            playerData={playerData}
            timeStatsData={timeStatsData}
            gameStatsData={gameStatsData}
            statsConfigData={statsConfig}
            roles={props.roles}
            route={props.route}
            onCloseEventDialog={handleCloseEventDialog}
            onEditEvent={handleEditEvent}
            showEventDialog={showEventDialog}
        />
    );
};

export default EventsSingleContainer;
