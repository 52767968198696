import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ImportForm from '../../forms/ImportForm';
import PageHeader from '../../components/page/PageHeader';
import DocumentHead from '../../components/DocumentHead';

import { useCreateTeamMutation } from '../../api/teams';
import {
    useCreateOrganisationTeamMutation,
    useGetOrganisationQuery,
} from '../../api/organisations';
import { useGetMeQuery } from '../../api/roles';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import {
    teamAgeOptions,
    teamGenderOptions,
    teamGradeOptions,
    teamTypeOptions,
} from '../../util/constants';

const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';
const STATUS_LOADING = 'loading';

const columns = [
    {
        field: 'typeID',
        header: 'Type',
        component: (options) => (
            <Dropdown
                onChange={(e) => options.editorCallback(e.target.value)}
                options={teamTypeOptions}
                placeholder="Select Type"
                value={options.value}
            />
        ),
    },
    {
        field: 'teamName',
        header: 'Name',
        required: true,
    },
    {
        field: 'shortName',
        header: 'Short Name',
    },
    {
        field: 'gender',
        header: 'Gender',
        component: (options) => (
            <Dropdown
                onChange={(e) => options.editorCallback(e.target.value)}
                options={teamGenderOptions}
                placeholder="Select Gender"
                value={options.value}
            />
        ),
    },
    {
        field: 'age',
        header: 'Age',
        component: (options) => (
            <Dropdown
                onChange={(e) => options.editorCallback(e.target.value)}
                options={teamAgeOptions}
                placeholder="Select Age"
                value={options.value}
            />
        ),
    },
    {
        field: 'grade',
        header: 'Grade',
        component: (options) => (
            <Dropdown
                editable
                onChange={(e) => options.editorCallback(e.target.value)}
                options={teamGradeOptions}
                placeholder="Select Grade (Optional)"
                value={options.value}
            />
        ),
    },
    {
        field: 'seasonName',
        header: 'Season',
        component: (options) => (
            <InputText
                onChange={(e) => options.editorCallback(e.target.value)}
                placeholder="Season Name (Optional)"
                value={options.value}
            />
        ),
    },
    {
        field: 'teamJoinMode',
        header: 'Join Mode',
        component: (options) => (
            <Dropdown
                onChange={(e) => options.editorCallback(e.target.value)}
                options={[{
                    label: 'Enabled',
                    value: 'Enabled'
                }, {
                    label: 'Single Use',
                    value: 'SingleUse'
                }, {
                    label: 'Disabled',
                    value: 'Disabled'
                }]}
                placeholder="Select Mode"
                value={options.value}
            />
        ),
    },
    {
        field: 'teamJoinRole',
        header: 'Join Role',
        component: (options) => (
            <InputText
                onChange={(e) => options.editorCallback(e.target.value)}
                placeholder="Join Role (Optional)"
                value={options.value}
            />
        ),
    },
];

const TeamsImport = (props) => {
    const [importedData, setImportedData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useRef(null);

    const { organisationID } = useParams();

    const navigate = useNavigate();

    // API Handling
    const { data: dataUser, isError } = useGetMeQuery();
    const { data: dataOrg } = useGetOrganisationQuery(
        { organisationID },
        { skip: !organisationID }
    );

    const [createUserTeam] = useCreateTeamMutation();
    const [createOrgTeam] = useCreateOrganisationTeamMutation();

    const createTeam = organisationID ? createOrgTeam : createUserTeam;

    const handleSubmit = async () => {
        const userID = dataUser && dataUser.data && dataUser.data.userID;
        let successCount = 0;

        setIsSubmitting(true);

        await Promise.allSettled(
            importedData.map(async (data, rowIndex) => {
                updateRowStatus(rowIndex, STATUS_LOADING);

                const response = await createTeam({
                    userID,
                    organisationID,
                    ...data,
                    design: dataOrg?.data?.design,
                    status: 'Active',
                });

                if (response.data) {
                    successCount++;
                    updateRowStatus(rowIndex, STATUS_SUCCESS);
                } else {
                    updateRowStatus(rowIndex, STATUS_ERROR);
                }
            })
        ).then(() => {
            setIsSubmitting(false);
        });

        toast.current.show({
            severity:
                successCount === 0
                    ? 'error'
                    : successCount === importedData.length
                        ? 'success'
                        : '',
            className:
                successCount === 0 || successCount === importedData.length
                    ? 'p-toast-message--is-inline'
                    : '',
            summary: `${successCount}/${importedData.length} Imported`,
            detail: successDetailContent(successCount, importedData.length),
            life: 5000,
        });
    };

    const successDetailContent = (count, maxCount) => {
        return (
            <>
                {maxCount === count ? (
                    <Button
                        className="p-button-outlined p-button-sm p-button-secondary toast-action"
                        label="View"
                        onClick={() =>
                            navigate('/', {
                                replace: true,
                            })
                        }
                    />
                ) : count > 0 ? (
                    <div className="button-group">
                        <Button
                            className="p-button-outlined p-button-sm p-button-warn toast-action"
                            label="Clear And Retry"
                            onClick={() => handleRetryImport(count)}
                        />
                        <Button
                            className="p-button-outlined p-button-sm p-button-secondary toast-action"
                            label="View Imported"
                            onClick={() =>
                                navigate('/', {
                                    replace: true,
                                })
                            }
                        />
                    </div>
                ) : (
                    <Button
                        className="p-button-outlined p-button-sm p-button-secondary toast-action"
                        label="Retry"
                        onClick={() => handleSubmit()}
                    />
                )}
            </>
        );
    };

    const updateRowStatus = (rowIndex, status) => {
        setImportedData((prevData) =>
            prevData.map((item, index) =>
                index === rowIndex ? { ...item, status } : item
            )
        );
    };

    const handleImport = (data) => {
        setImportedData(data);
    };

    const handleClearAll = () => {
        setImportedData([]);
    };

    const handleClearCompleted = () => {
        setImportedData((prevState) =>
            prevState.filter((data) => data.status !== STATUS_SUCCESS)
        );
    };

    const handleRetryImport = (completedCount) => {
        if (completedCount > 0) {
            handleClearCompleted();
        }
        handleSubmit();
    };

    const handleFileError = () => {
        toast.current.show({
            severity: 'error',
            summary: `File type not supported`,
            detail: 'Import a CSV or Excel Sheet',
            life: 5000,
        });
    };

    const handleRemoveRow = (index) => {
        setImportedData((data) => [
            ...data.slice(0, index),
            ...data.slice(index + 1),
        ]);
    };

    const handleAddRow = (data) => {
        setImportedData(data);
    };

    const handleEditComplete = (data) => {
        setImportedData(data);
    };

    return (
        <>
            <DocumentHead
                title="Team Import - Rookie Me Hub"
                description="Team import page"
            />
            <div className="page view">
                <div className="view-inner">
                    <PageHeader title='Teams' showBreadcrumbs={false} />
                    <ImportForm
                        columns={columns}
                        data={importedData}
                        isError={isError}
                        isSubmitting={isSubmitting}
                        onAddRow={handleAddRow}
                        onClearAll={handleClearAll}
                        onClearCompleted={handleClearCompleted}
                        onImportError={handleFileError}
                        onEditComplete={handleEditComplete}
                        onRemoveRow={handleRemoveRow}
                        onSubmit={handleSubmit}
                        onImportComplete={handleImport}
                    />
                    <Toast ref={toast} />
                </div>
            </div>
        </>
    );
};

export default TeamsImport;
