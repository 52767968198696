import { Button, ButtonProps } from 'primereact/button';
import { format, isSameDay } from 'date-fns';

import { UseQueryResult } from '../../types/api';
import { StatResponse, TimeStatResponse } from '../../types/game';
import { PlayerResponse, TeamResponse } from '../../types/team';
import { EventResponse } from '../../types/event';
import { StatConfigResponse } from '../../types/sports';

interface Props {
    eventData: UseQueryResult<EventResponse>;
    timeStatsData: UseQueryResult<TimeStatResponse>;
    gameStatsData: UseQueryResult<StatResponse>;
    playerData: UseQueryResult<PlayerResponse>;
    teamData: UseQueryResult<TeamResponse>;
    statsConfigData: UseQueryResult<StatConfigResponse>;
}

interface SectionHeaderProps {
    title: string;
    subtitle?: string;
    actions?: ButtonProps[];
}

interface DetailListItemProps {
    title: string;
    content: any;
    actions?: ButtonProps[];
}

interface DetailListProps {
    items: DetailListItemProps[];
}

const SectionHeader = (props: SectionHeaderProps) => {
    const renderAction = ({ label, ...buttonProps }: ButtonProps) => {
        return <Button {...buttonProps}>{label}</Button>;
    };

    return (
        <div className="section-header">
            <div className="section-header_inner">
                <h1 className="section-header_title">{props.title}</h1>
                {props.subtitle && (
                    <p className="section-header_subtitle">{props.subtitle}</p>
                )}
            </div>
            {props.actions && props.actions.length > 0 && (
                <div className="section-header_actions">
                    {props.actions.map(renderAction)}
                </div>
            )}
        </div>
    );
};

const DetailList = (props: DetailListProps) => {
    const renderAction = ({ label, ...buttonProps }: ButtonProps) => {
        return <Button {...buttonProps}>{label}</Button>;
    };

    const renderItem = ({ title, content, actions }: DetailListItemProps) => {
        return (
            <li className="details-list_item">
                <div className="details-list_title">{title}</div>
                <div className="details-list_caption">{content}</div>
                {actions && actions.length > 0 && actions.map(renderAction)}
            </li>
        );
    };
    return <ul className="details-list">{props.items.map(renderItem)}</ul>;
};

const EventDetails = (props: Props) => {
    const { eventData } = props;
    const event = eventData?.data?.data;
    let eventDetails = [
        {
            title: 'Start Date',
            content:
                event &&
                format(new Date(event.startDateTime), 'eeee, MMMM do, yyyy'),
        },
        {
            title: 'Start Time',
            content: event && format(new Date(event.startDateTime), 'h:mm aaa'),
        },
    ];

    if (event?.endDateTime && event?.endDateTime !== event.startDateTime) {
        if (
            !isSameDay(new Date(event.endDateTime), new Date(event.endDateTime))
        ) {
            eventDetails.push({
                title: 'End Date',
                content: format(
                    new Date(event.endDateTime),
                    'eeee, MMMM do, yyyy'
                ),
            });
        }
        eventDetails.push({
            title: 'End Time',
            content: format(new Date(event.endDateTime), 'h:mm aaa'),
        });
    }

    if (event?.description) {
        eventDetails.push({
            title: 'Description',
            content: event.description,
        });
    }

    let gameDetails: Array<{ title: string; content: string }> = [];

    if (event?.game) {
        const { game } = event;

        gameDetails = [
            {
                title: 'Home/Away',
                content: game.isHomeTeam ? 'Home' : 'Away',
            },
            {
                title: 'Opponent',
                content: game.opponentTeam?.teamName || 'Opponent',
            },
        ];
    }

    return (
        <div>
            <SectionHeader title="Event Details" />
            <DetailList items={eventDetails} />

            {event?.eventType === 'Game' && (
                <>
                    <SectionHeader title="Game Details" />
                    <DetailList items={gameDetails} />
                </>
            )}
        </div>
    );
};

export default EventDetails;
