import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetOrganisationQuery } from '../../api/organisations';

import { Toast } from 'primereact/toast';

import OrganisationForm from './OrganisationForm';
import Loader from '../../components/Loader';
import ErrorDisplay from '../../components/ErrorDisplay';
import DocumentHead from '../../components/DocumentHead';
import { ERROR_TYPES, SEVERITY } from '../../types/common.d';

const OrganisationSettings = () => {
    const { organisationID } = useParams();

    const formResponseToast = useRef<Toast>(null);

    const navigate = useNavigate();

    // API Calls
    const { data, isLoading, isError } = useGetOrganisationQuery(
        // @ts-expect-error entity param may not exist in url
        { organisationID },
        {
            skip: !organisationID,
        }
    );

    const handleSubmitResult = (submitResult: string) => {
        const hasSubmitted = submitResult === 'success';
        const hasUpdated = submitResult === 'updated';

        formResponseToast.current?.show({
            severity: hasSubmitted || hasUpdated ? 'success' : 'warn',
            summary: hasUpdated
                ? `Organisation Updated`
                : hasSubmitted
                ? `Organisation Deleted`
                : `Deletion Unsuccessful`,
            detail: hasUpdated
                ? `The Organisation details have been amended.`
                : hasSubmitted
                ? `The Organisation has been successfully deleted.`
                : `Organisation deletion could not be completed, try again later.`,
        });
    };

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        command: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                        severity: SEVERITY.primary,
                    },
                ]}
                desc="We were unable to load your Organisation Settings, try again or contact Organisation Owner."
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Roles Returned"
            />
        );
    }

    return (
        <>
            <DocumentHead
                title="Organisation Settings - Rookie Me Hub"
                description="Organisation settings page"
            />
            <OrganisationForm
                // removing this as it's not declared and used
                // organisationID={data?.data?.organisationID}
                initialValues={data?.data}
                onSubmit={handleSubmitResult}
                formHasActions={false}
            />
            <Toast
                ref={formResponseToast}
                onHide={() => navigate('/', { replace: true })}
            />
        </>
    );
};

export default OrganisationSettings;
