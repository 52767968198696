import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { TabMenu } from 'primereact/tabmenu';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';

import ButtonIcon from '../ButtonIcon';
import BreadCrumbs from '../BreadCrumbs';

import { Action } from '../../types/common.d';
import { Roles } from '../../types/roles';

interface Tab {
    path: string;
    icon: string;
    label: string;
}

interface Props {
    title: string;
    description?: string;
    tabs?: Array<Tab>;
    options?: MenuItem[];
    actions?: Action[];
    showBreadcrumbs?: boolean;
    roles: Roles;
}

const PageHeader = (props: Props) => {
    const { actions, description, options, tabs, title } = props;

    const headerOptions = useRef<Menu>(null);

    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(NaN);

    /**
     * @desc checks for change to url and updates tabIndex state accordingly.
     * @returns tabIndex
     */
    useEffect(() => {
        if (tabs) {
            const activeTabIndex = tabs.findIndex((tab) => {
                const urlSegment = location.pathname.substring(
                    location.pathname.lastIndexOf('/') + 1
                );
                return tab.path === urlSegment;
            });
            setActiveTab(activeTabIndex);
        }
    }, [location.pathname, tabs]);

    /**
     * @desc returns page heading content
     * @returns JSX
     */
    const renderHeading = () => {
        return (
            <div
                className={`page-header_middle--left ${
                    description ? 'has-desc' : ''
                }`}
            >
                <div className="page-header_title title">
                    <h1>{title}</h1>
                </div>
                {description && (
                    <p className="page-header_desc">{description}</p>
                )}
            </div>
        );
    };

    /**
     * @desc options and actions props passed in, mapped over and rendered through primereact components.
     * @returns JSX
     */
    const renderActions = () => {
        return (
            <div className="page-header_middle--right">
                {options ? (
                    <>
                        <Button
                            className="p-button-sm p-button-icon-only p-button-secondary"
                            onClick={(event) =>
                                headerOptions.current?.toggle(event)
                            }
                        >
                            <span className="p-button-icon material-symbols-outlined">
                                more_horiz
                            </span>
                        </Button>
                        <Menu model={options} popup ref={headerOptions} />
                    </>
                ) : (
                    ''
                )}
                {actions?.map((action) =>
                    action ? (
                        <Button
                            key={action.key}
                            label={action.label}
                            className={`p-button p-component p-button-sm ${
                                action.severity
                                    ? 'p-button-' + action.severity
                                    : ''
                            } ${action.className ? action.className : ''}`}
                            icon={
                                <ButtonIcon
                                    iconName={action.icon ? action.icon : ''}
                                    placement="left"
                                    size="small"
                                />
                            }
                            onClick={() => {
                                action.command && action.command();
                            }}
                        />
                    ) : (
                        ''
                    )
                )}
            </div>
        );
    };

    const remappedTabs = tabs?.map((tab) => ({
        url: tab.path,
        ...tab,
    }));

    return (
        <div className={`page-header ${tabs ? 'has-tabs' : ''}`}>
            {props.showBreadcrumbs && (
                <div className="page-header_top">
                    <BreadCrumbs roles={props.roles} />
                </div>
            )}
            <div className="page-header_middle">
                {renderHeading()}
                {(actions || options) && renderActions()}
            </div>
            {tabs && (
                <div className="page-header_bottom">
                    <TabMenu
                        model={remappedTabs}
                        activeIndex={activeTab}
                        onTabChange={(e) => {
                            e.originalEvent.preventDefault();
                            if (e.value.url) {
                                navigate(e.value.url);
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
};

PageHeader.defaultProps = {
    showBreadcrumbs: true,
};

export default PageHeader;
