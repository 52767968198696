import { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {
    useDeleteTeamStaffMutation,
    useGetTeamStaffQuery,
    useLazyGetTeamStaffQuery,
} from '../../api/teams';

import UsersView from './StaffView';
import DocumentHead from '../../components/DocumentHead';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { TeamStaff } from '../../types/team';

interface Props {
    roles: Roles;
    route: Route;
}

const StaffContainer = (props: Props) => {
    const { teamID, organisationID , tab } = useParams();
    const navigate = useNavigate();
    const [, setSearchParams] = useSearchParams();

    const [focusedUser, setFocusedUser] = useState<TeamStaff | null>(null);
    const [showInviteDialog, setShowInviteDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const [refetch] = useLazyGetTeamStaffQuery();

    const { data, isLoading, isError, isFetching } = useGetTeamStaffQuery(
        {
            // @ts-expect-error teamID may not exist in the url
            teamID,
        },
        {
            skip: !teamID,
        }
    );

    const [deleteUser] = useDeleteTeamStaffMutation();

    const handleShowInvite = () => {
        setShowInviteDialog(true);
    };

    const handleCloseInvite = () => {
        setShowInviteDialog(false);
    };

    const handleShowDelete = () => {
        setShowDeleteDialog(true);
    };

    const handleCloseDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteUser = (userID: string) => {
        console.log('DELETE', userID);

        deleteUser({ userID })
            .then((response) => {
                console.log('DELETE SUCCESS', response);
            })
            .catch((err) => {
                console.log('DELETE ERROR', err);
            })
            .finally(() => {
                setShowDeleteDialog(false);
            });
    };

    const handleClickUser = (userID: string) => {
        setSearchParams((params) => {
            params.set('id', userID);
            return params;
        });
    };

    const handleCloseDrawer = () => {
        setSearchParams((params) => {
            params.delete('id');
            return params;
        });
    };

    const handleViewUser = (userID: string) => {
        console.log('VIEW', userID);
    };

    const handleFocusUser = (user: TeamStaff) => {
        setFocusedUser(user);
    };

    const handleLoadMore = () => {
        if (
            teamID &&
            data &&
            data.lastEvaluatedKey &&
            data.lastEvaluatedKey.cursor
        ) {
            refetch({
                teamID,
                cursor: data.lastEvaluatedKey.cursor,
            });
        }
    };

    useEffect(() => {
        // Handle redirection to "active" for specific URLs with /staff or /events
        if (tab === 'upcoming' || tab === 'past') {
          if (teamID) {
            navigate(`/t/${teamID}/staff/active`, { replace: true });
          } else if (organisationID) {
            navigate(`/o/${organisationID}/staff/active`, { replace: true });
          }
        }
      }, [navigate, teamID, organisationID, tab]);

    return (
        <>
            <DocumentHead
                title="Staff - Rookie Me Hub"
                description="Staff management page"
            />
            <UsersView
                data={data ? data.data : []}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                showDeleteDialog={showDeleteDialog}
                showInviteDialog={showInviteDialog}
                focusedUser={focusedUser}
                onCloseDeleteDialog={handleCloseDelete}
                onCloseInviteDialog={handleCloseInvite}
                onShowInviteDialog={handleShowInvite}
                onShowDeleteDialog={handleShowDelete}
                onClickUser={handleClickUser}
                onDeleteUser={handleDeleteUser}
                onViewUser={handleViewUser}
                onFocusUser={handleFocusUser}
                onCloseDrawer={handleCloseDrawer}
                viewMedia={props.route.icon}
                onLoadMore={handleLoadMore}
                showPagination={!!data?.lastEvaluatedKey?.cursor}
                {...props}
            />
        </>
    );
};

export default StaffContainer;