import React, { useMemo } from 'react';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { uniqueId } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

import ButtonIcon from './ButtonIcon';

import { Action, ERROR_TYPES } from '../types/common.d';

import { ReactComponent as Esg404 } from '../assets/images/states/esg_404.svg';
import { ReactComponent as EsgMaintenance } from '../assets/images/states/esg_maintenance.svg';
import { ReactComponent as EsgEmpty } from '../assets/images/states/esg_empty.svg';
import { ReactComponent as EsgEmptyDirectory } from '../assets/images/states/esg_empty-directory.svg';
import { ReactComponent as EsgNotFound } from '../assets/images/states/esg_unable-to-locate.svg';
import { ReactComponent as EsgFileNotFound } from '../assets/images/states/esg_file-not-found.svg';
import { ReactComponent as EsgEmptyInbox } from '../assets/images/states/esg_no-messages.svg';
import { ReactComponent as EsgSomethingWentWrong } from '../assets/images/states/esg_something-went-wrong.svg';

interface Props {
    actions?: Action[];
    alignment?: 'left' | 'middle' | 'right';
    desc?: string;
    errorType?: ERROR_TYPES;
    handleReset?(): Function | void;
    hasReturn?: boolean;
    hasReporting?: boolean;
    proportion?: 'compact' | 'regular' | 'enlarged' | 'fullscreen';
    title: string;
}

const ErrorDisplay = (props: Props) => {
    const {
        actions,
        alignment,
        desc,
        errorType,
        handleReset,
        hasReporting,
        hasReturn,
        proportion,
        title,
    } = props;

    const { user } = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();

    const getHeaderTag = useMemo(() => {
        if (proportion) {
            switch (proportion) {
                case 'compact':
                    return 'h4';
                case 'regular':
                    return 'h3';
                case 'enlarged':
                    return 'h2';
                case 'fullscreen':
                    return 'h1';
                default:
                    return 'h3';
            }
        }
    }, [proportion]);
    const HeaderTag = getHeaderTag;

    const getButtonClass = useMemo(() => {
        if (proportion) {
            switch (proportion) {
                case 'compact':
                    return 'p-button-sm';
                case 'regular':
                    return '';
                case 'enlarged':
                    return 'p-button-lg';
                case 'fullscreen':
                    return 'p-button-lg';
                default:
                    return '';
            }
        }
    }, [proportion]);

    /***
     * @desc apply error state graphics (esg) to a custom tag based on returned state prop
     * @param proportion: "left" | "middle" | "right"
     */
    const getAlignment = useMemo(() => {
        switch (alignment) {
            case 'left':
                return 'is-aligned--left';
            case 'middle':
                return '';
            case 'right':
                return 'is-aligned--right';
            default:
                return '';
        }
    }, [alignment]);

    /***
     * @desc apply error state graphics (esg) to a custom tag based on returned errorType prop
     * @param ErrorTypes
     */
    const getEmptyStateGraphic = useMemo(() => {
        switch (errorType) {
            case ERROR_TYPES.pageNotFound:
                return Esg404;
            case ERROR_TYPES.maintenance:
                return EsgMaintenance;
            case ERROR_TYPES.empty:
                return EsgEmpty;
            case ERROR_TYPES.emptyFolder:
                return EsgEmptyDirectory;
            case ERROR_TYPES.notFound:
                return EsgNotFound;
            case ERROR_TYPES.fileError:
                return EsgFileNotFound;
            case ERROR_TYPES.inboxEmpty:
                return EsgEmptyInbox;
            case ERROR_TYPES.somethingsWrong:
                return EsgSomethingWentWrong;
            default:
                return EsgSomethingWentWrong;
        }
    }, [errorType]);
    const EsgTag = getEmptyStateGraphic;

    /**
     * @desc map over actions prop and render buttons
     * @return JSX
     */
    const actionsMap =
        actions &&
        actions.map((action, i) => {
            return (
                <Button
                    key={`error-action-${i} `}
                    className={`${action.className ? action.className : ''} ${
                        action.severity && `p-button-${action.severity}`
                    } ${getButtonClass}`}
                    onClick={() =>
                        action.command
                            ? action.command()
                            : action.url
                            ? navigate(action.url)
                            : ''
                    }
                >
                    {action.icon && (
                        <ButtonIcon
                            iconName={action.icon}
                            placement={action.label ? 'left' : ''}
                        />
                    )}
                    {action.label && (
                        <span className="p-button-label">{action.label}</span>
                    )}
                </Button>
            );
        });

    /**
     * @desc a function to render JSX buttons from passed props
     * @param className
     * @param command
     * @param icon
     * @param label
     * @param url
     * @return JSX
     */
    const renderButton = ({ className, command, icon, label, url }: Action) => {
        return (
            <Button
                className={className + ' ' + getButtonClass}
                onClick={() => (command ? command() : url ? navigate(url) : '')}
            >
                {icon && (
                    <ButtonIcon iconName={icon} placement={label && 'left'} />
                )}
                <span className="p-button-label">{label}</span>
            </Button>
        );
    };

    // boolean check for any available action
    const actionCheck = actions || handleReset || hasReporting || hasReturn;
    return (
        <div
            className={`error-display ${getAlignment} error-display-${proportion}`}
        >
            <EsgTag
                className={`error-display_state-graphic is-${proportion}`}
            />
            {HeaderTag && (
                <HeaderTag className="error-display_title">{title}</HeaderTag>
            )}
            {desc && <p className="error-display_desc">{desc}</p>}
            {actionCheck && (
                <div className={`error-display_button-group is-${proportion}`}>
                    {handleReset &&
                        renderButton({
                            command: () => handleReset(),
                            className: getButtonClass,
                            icon: 'restart_alt',
                            label: 'Retry',
                        })}
                    {hasReturn &&
                        renderButton({
                            command: () => navigate(-1),
                            className: 'p-button-secondary ' + getButtonClass,
                            icon: 'keyboard_return',
                            label: 'Go Back',
                        })}
                    {hasReporting &&
                        renderButton({
                            command: () =>
                                Sentry.showReportDialog({
                                    logger: uniqueId('error-' + title + '-'),
                                    transaction: location,
                                    user: user && {
                                        name:
                                            user.firstName +
                                            ' ' +
                                            user.lastName,
                                        email: user.email,
                                    },
                                }),
                            className: 'p-button-secondary ' + getButtonClass,
                            icon: 'rate_review',
                            label: 'Submit Feedback',
                        })}
                    {actions && actionsMap}
                </div>
            )}
        </div>
    );
};

ErrorDisplay.defaultProps = {
    alignment: 'middle',
    hasReporting: false,
    hasReturn: true,
    proportion: 'regular',
    title: 'An error has occurred',
};

export default ErrorDisplay;
