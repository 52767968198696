import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { pick } from 'lodash';

import { useLazyGetRolesQuery } from '../../api/roles';
import {
    useDeleteOrganisationMutation,
    useUpsertOrganisationMutation,
} from '../../api/organisations';

import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressBar } from 'primereact/progressbar';

import ButtonIcon from '../../components/ButtonIcon';
import EntityDesigner from '../../components/EntityDesigner';
import EntityPreview from '../../components/EntityPreview';
import Icon from '../../components/Icon';
import ListItem from '../../components/ListItem';
import Modal from '../../components/Modal';

import { ACTION_TYPES, INPUT_TYPES, SEVERITY } from '../../types/common.d';

interface OrganisationFormProps {
    formHasActions: boolean;
    initialValues: {
        [key: string]: any;
    };
    onCancel?: () => void;
    onSubmit?: (status: string) => void;
}

export default function OrganisationForm(props: OrganisationFormProps): JSX.Element {
    const { formHasActions, initialValues, onCancel, onSubmit } = props;

    const { organisationID, userID } = useParams();

    const [getRoles] = useLazyGetRolesQuery();
    const [upsertOrganisation] = useUpsertOrganisationMutation();
    const [deleteOrganisation] = useDeleteOrganisationMutation();

    const [showEditor, setShowEditor] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const requiredFields = ['design', 'organisationName'];
    const optionalFields: string[] = [];

    const isCreate = !organisationID;

    const handleSubmit = (
        data: { [key: string]: any },
        { setSubmitting }: FormikHelpers<{ [key: string]: any }>
    ) => {
        setSubmitting(true);

        upsertOrganisation({
            organisationID,
            ...data,
        })
            .then(() => {
                organisationID ? onSubmit && onSubmit('updated') : onSubmit && onSubmit('success');

                if (userID) {
                    getRoles({ userID });
                }
            })
            .catch((err) => {
                console.dir(err);
                onSubmit && onSubmit('error');
            })
            .finally(() => {
                setSubmitting(false);
                isCreate && onCancel && onCancel();
            });
    };

    const handleDelete = ({ setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        // set formik to submitting
        setSubmitting(true);

        // close modal to show form
        setShowDeleteDialog(false);

        // init deletion method
        deleteOrganisation({ organisationID: organisationID ?? '' })
            .then(() => {
                organisationID ? onSubmit && onSubmit('updated') : onSubmit && onSubmit('success');
            })
            .catch((err) => {
                console.dir(err);
                onSubmit && onSubmit('error');

                if (userID) {
                    getRoles({ userID });
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const confirmRemovalModalHeader = <h4 className={'title-is-bold'}>Confirm Deletion</h4>;

    const confirmRemovalModalHeaderIcon = (
        <Button
            className="p-button-sm p-button-text p-button-secondary ui-button"
            onClick={() => setShowDeleteDialog(false)}
            type="button"
        >
            <Icon name="close" size="small" />
        </Button>
    );

    const confirmRemovalModalContent = !isCreate && (
        <div className="modal-content">
            <p className="delete-msg">Are you sure you want to delete this Organisation?</p>
            <div className="list">
                <ListItem avatar={{ label: initialValues.organisationName }} title={initialValues.organisationName} />
            </div>
        </div>
    );

    return (
        <Formik
            initialValues={pick(initialValues, [...requiredFields, ...optionalFields])}
            validate={(values) => {
                const errors: { [key: string]: string } = {};
                requiredFields.forEach((field) => {
                    if (!values[field]) {
                        errors[field] = 'Required';
                    }
                });
                return errors;
            }}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setSubmitting,
            }) => (
                <>
                    <Modal
                        content={
                            <EntityDesigner
                                initialValues={values.design}
                                onCancel={() => setShowEditor(false)}
                                onSubmit={(value) => {
                                    setFieldValue('design', value);
                                    setShowEditor(false);
                                }}
                                submitLabel="Save Design"
                            />
                        }
                        hasFooter={false}
                        maximizable={false}
                        onHide={() => setShowEditor(false)}
                        title="Kit Designer"
                        visible={showEditor}
                    />

                    <form className={`form ${!isCreate ? 'form-is-wrapped' : ''}`} onSubmit={handleSubmit}>
                        {!isCreate && (
                            <div className="wrapped-form_header">
                                <h4 className="title">Organisation Settings</h4>
                            </div>
                        )}
                        <div className="form-fields">
                            <div className="form-segment kit-edit_wrap">
                                <div className="kit-edit" onClick={() => setShowEditor(true)}>
                                    <EntityPreview design={values.design} />
                                    <div className="edit-badge">
                                        <Icon name="edit" size="small"></Icon>
                                    </div>
                                </div>
                            </div>
                            <div className="form-segment">
                                <label className="form-label" htmlFor="organisationName">
                                    Organisation Name
                                </label>
                                <div
                                    className={`input-wrap${errors.organisationName && touched.organisationName ? ' has-error' : ''
                                        } ${!isCreate && 'input-disabled'}`}
                                >
                                    <InputText
                                        className="form-field"
                                        id="organisationName"
                                        name="organisationName"
                                        type={INPUT_TYPES.text}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Organisation Name"
                                        value={values.organisationName}
                                        required={isCreate}
                                        disabled={!isCreate}
                                    />
                                    {errors.organisationName && touched.organisationName && (
                                        <Icon name="warning" size="small" />
                                    )}
                                </div>

                                {errors.organisationName && touched.organisationName && (
                                    <Message
                                        className="form-message form-message--error message-floated"
                                        severity="error"
                                        text={errors.organisationName as string}
                                        icon={<Icon name="error" size="small" />}
                                    />
                                )}
                            </div>
                        </div>

                        {formHasActions && (
                            <div className="form-actions">
                                {isCreate && (
                                    <Button
                                        className={`p-button-text p-button-sm p-button-${SEVERITY.secondary}`}
                                        disabled={isSubmitting}
                                        onClick={() => onCancel && onCancel()}
                                        type={ACTION_TYPES.button}
                                    >
                                        <ButtonIcon iconName="cancel" placement="left" />
                                        <span className="p-button-label">Cancel</span>
                                    </Button>
                                )}
                                {!isCreate && (
                                    <Button
                                        className={`p-button-sm p-button-${SEVERITY.danger}`}
                                        disabled={isSubmitting}
                                        onClick={() => setShowDeleteDialog(true)}
                                        type={ACTION_TYPES.button}
                                    >
                                        <ButtonIcon iconName="delete" placement="left" />
                                        <span className="p-button-label">Delete</span>
                                    </Button>
                                )}
                                <Button
                                    className={`p-button-sm p-button-${SEVERITY.primary}`}
                                    disabled={isSubmitting}
                                    type={ACTION_TYPES.submit}
                                >
                                    <ButtonIcon
                                        className={isSubmitting ? 'is-submitting' : undefined}
                                        iconName={isSubmitting ? 'autorenew' : 'add_home'}
                                        placement="left"
                                    />
                                    <span className="p-button-label">
                                        {!isCreate && isSubmitting
                                            ? 'Updating'
                                            : !isCreate
                                                ? 'Update Organisation'
                                                : isSubmitting
                                                    ? 'Creating'
                                                    : 'Create Organisation'}
                                    </span>
                                </Button>
                            </div>
                        )}

                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                        {!isCreate && (
                            <ConfirmDialog
                                className="modal delete-modal"
                                closeOnEscape
                                draggable={false}
                                dismissableMask
                                footer={
                                    <>
                                        <Button
                                            className={`p-button-text p-button-${SEVERITY.secondary}`}
                                            onClick={() => setShowDeleteDialog(false)}
                                            type={ACTION_TYPES.button}
                                        >
                                            <ButtonIcon iconName="cancel" placement="left" />
                                            <span className="p-button-label">Cancel</span>
                                        </Button>
                                        <Button
                                            className={`p-button-${SEVERITY.danger}`}
                                            onClick={() => handleDelete({ setSubmitting })}
                                            type={ACTION_TYPES.submit}
                                        >
                                            <ButtonIcon iconName="delete" placement="left" />
                                            <span className="p-button-label">Yes, Delete</span>
                                        </Button>
                                    </>
                                }
                                header={confirmRemovalModalHeader}
                                icons={confirmRemovalModalHeaderIcon}
                                message={confirmRemovalModalContent}
                                onHide={() => setShowDeleteDialog(false)}
                                visible={showDeleteDialog}
                            />
                        )}
                    </form>
                </>
            )}
        </Formik>
    );
}
