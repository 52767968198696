import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetTeamQuery } from '../../api/teams';

import { Toast } from 'primereact/toast';

import TeamForm from './TeamForm';
import Loader from '../../components/Loader';
import ErrorDisplay from '../../components/ErrorDisplay';
import DocumentHead from '../../components/DocumentHead';

import { ERROR_TYPES, SEVERITY } from '../../types/common.d';

const TeamSettings = () => {
    const { teamID } = useParams();

    const formResponseToast = useRef<Toast>(null);

    const navigate = useNavigate();

    // API Calls
    // @ts-expect-error entity param may not exist in url
    const { data, isLoading, isError } = useGetTeamQuery(teamID, {
        skip: !teamID,
    });

    const handleSubmitResult = (submitResult: string) => {
        const hasSubmitted = submitResult === 'success';
        const hasUpdated = submitResult === 'updated';

        formResponseToast.current?.show({
            severity: hasSubmitted || hasUpdated ? 'success' : 'warn',
            summary: hasUpdated
                ? `Team Updated`
                : hasSubmitted
                ? `Team Deleted`
                : `Deletion Unsuccessful`,
            detail: hasUpdated
                ? `The Team details have been amended.`
                : hasSubmitted
                ? `The Team has been successfully deleted.`
                : `Team deletion could not be completed, try again later.`,
        });
    };

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        command: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                        severity: SEVERITY.primary,
                    },
                ]}
                desc="We were unable to load your Team Settings, try again or contact Team Owner."
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Roles Returned"
            />
        );
    }

    return (
        <>
            <DocumentHead
                title="Team Settings - Rookie Me Hub"
                description="Team settings page"
            />
            <TeamForm
                teamID={data?.data?.teamID}
                initialValues={data?.data}
                onSubmit={handleSubmitResult}
            />
            <Toast
                ref={formResponseToast}
                onHide={() => navigate('/', { replace: true })}
            />
        </>
    );
};

export default TeamSettings;
