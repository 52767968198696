import { useRef, useState, useMemo } from 'react';
import { groupBy, sortBy, startCase, uniqBy } from 'lodash';

import { getFirstChars, periodSuffix } from '../../util/helper';

import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Column, ColumnBodyOptions, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton } from 'primereact/selectbutton';
import { Skeleton } from 'primereact/skeleton';
import { Slider } from 'primereact/slider';
import { ToggleButton } from 'primereact/togglebutton';
import { Toolbar } from 'primereact/toolbar';

import ButtonIcon from '../../components/ButtonIcon';
import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import Loader from '../../components/Loader';

import { UseQueryResult } from '../../types/api';
import { EventResponse } from '../../types/event';
import { Player, PlayerResponse, TeamResponse } from '../../types/team';
import { StatResponse, TimeStat, TimeStatResponse } from '../../types/game';
import { StatConfigResponse } from '../../types/sports';
import { ERROR_TYPES } from '../../types/common.d';

import playLogo from '../../assets/images/logos/rm-play-logo.png';

interface Props {
    eventData: UseQueryResult<EventResponse>;
    timeStatsData: UseQueryResult<TimeStatResponse>;
    gameStatsData: UseQueryResult<StatResponse>;
    playerData: UseQueryResult<PlayerResponse>;
    teamData: UseQueryResult<TeamResponse>;
    statsConfigData: UseQueryResult<StatConfigResponse>;
}

interface Stat {
    [key: string]: number | string | boolean;
}

interface CalculatedStat {
    displayName: string;
    player?: Player;
    stats: Stat;
}

interface CalculatedStats {
    [key: string]: CalculatedStat;
}

type StatCategory = 'time' | 'game';

enum ReportType {
    basic = 'basic',
    advanced = 'advanced',
    //expert = 'expert',
}

interface ErrorType {
    message: string;
    reason: 'NO_DATA' | 'INCOMPLETE_DATA' | 'NO_GAMEID';
    status: number;
}

const timeStatsSchema = {
    basic: {
        PT: {
            name: 'Period Time',
            shortName: 'PT',
            visible: false,
        },
        timeOnField: {
            name: 'Total Time On Field',
            shortName: 'ON',
            visible: true,
        },
        timeOffField: {
            name: 'Total Time Off Field',
            shortName: 'OFF',
            visible: true,
        },
        timeInjury: {
            name: 'Total Injury Time',
            shortName: 'INJ',
            visible: true,
        },
    },
    advanced: {
        PT: {
            name: 'Period Time',
            shortName: 'PT',
            visible: false,
        },
        PGT: {
            name: 'Percentage Game Time',
            shortName: 'PGT',
            visible: true,
        },
        timeOnField: {
            name: 'Total Field Time',
            shortName: 'FLD',
            visible: true,
        },
        timeBench: {
            name: 'Total Bench Time',
            shortName: 'BEN',
            visible: true,
        },
        timeInjury: {
            name: 'Total Injury Time',
            shortName: 'INJ',
            visible: true,
        },
        timeAssessment: {
            name: 'Total Assessment Time',
            shortName: 'ASS',
            visible: true,
        },
        timeDisciplinary: {
            name: 'Total Disciplinary Time',
            shortName: 'DIS',
            visible: true,
        },
        timeAbsent: {
            name: 'Total Absent Time',
            shortName: 'ABS',
            visible: true,
        },
        zoneTimeFOR: {
            name: 'Total Time In Attack',
            shortName: 'ATT',
            visible: true,
        },
        zoneTimeMID: {
            name: 'Total Time In Midfield',
            shortName: 'MID',
            visible: true,
        },
        zoneTimeBAC: {
            name: 'Total Time In Defence',
            shortName: 'DEF',
            visible: true,
        },
    },
    expert: {
        PT: {
            name: 'Period Time',
            shortName: 'PT',
            visible: false,
        },
        PGT: {
            name: 'Percentage Game Time',
            shortName: 'PGT',
            visible: true,
        },
        PMT: {
            name: 'Percentage M Time',
            shortName: 'PMT',
            visible: true,
        },
        timeOnField: {
            name: 'Total Field Time',
            shortName: 'FLD',
            visible: true,
        },
        countStintOnField: {
            name: 'Total On Count',
            shortName: 'CFLD',
            visible: true,
        },
        timeOffField: {
            name: 'Total Time Off Field',
            shortName: 'OFF',
            visible: true,
        },
        countStintOffField: {
            name: 'Total Off Count',
            shortName: 'COF',
            visible: true,
        },
        timeBench: {
            name: 'Total Bench Time',
            shortName: 'BEN',
            visible: true,
        },
        countStintBench: {
            name: 'Total Bench Count',
            shortName: 'CBEN',
            visible: true,
        },
        timeInjury: {
            name: 'Total Injury Time',
            shortName: 'INJ',
            visible: true,
        },
        countStintInjury: {
            name: 'Total Injury Count',
            shortName: 'CINJ',
            visible: true,
        },
        timeAssessment: {
            name: 'Total Assessment Time',
            shortName: 'ASS',
            visible: true,
        },
        countStintAssessment: {
            name: 'Total Assessment Count',
            shortName: 'CASS',
            visible: true,
        },
        timeDisciplinary: {
            name: 'Total Disciplinary Time',
            shortName: 'DIS',
            visible: true,
        },
        countStintDisciplinary: {
            name: 'Total Disciplinary Count',
            shortName: 'CDIS',
            visible: true,
        },
        timeOpposition: {
            name: 'Total Opposition Time',
            shortName: 'OPP',
            visible: true,
        },
        countStintOpposition: {
            name: 'Total Opposition Count',
            shortName: 'COPP',
            visible: true,
        },
        timeAbsent: {
            name: 'Total Absent Time',
            shortName: 'ABS',
            visible: true,
        },
        countStintAbsent: {
            name: 'Total Absent Count',
            shortName: 'CABS',
            visible: true,
        },
        zoneTimeFOR: {
            name: 'Total Time In Attack',
            shortName: 'ATT',
            visible: true,
        },
        zoneTimeMID: {
            name: 'Total Time In Midfield',
            shortName: 'MID',
            visible: true,
        },
        zoneTimeBAC: {
            name: 'Total Time In Defence',
            shortName: 'DEF',
            visible: true,
        },
        timePos__Field: {
            name: 'Total Field Time',
            shortName: 'FT',
            visible: true,
        },
        countStintPos__Field: {
            name: 'Total Field Count',
            shortName: 'CFI',
            visible: true,
        },
        timePos__afl_attacking_left_wing: {
            name: 'Total Attacking Left Wing Time',
            shortName: 'ALW',
            visible: true,
        },
        countStintPos__afl_attacking_left_wing: {
            name: 'Total Attacking Left Wing Count',
            shortName: 'CALW',
            visible: true,
        },
        timePos__afl_attacking_right_wing: {
            name: 'Total Attacking Right Wing Time',
            shortName: 'ARW',
            visible: true,
        },
        countStintPos__afl_attacking_right_wing: {
            name: 'Total Attacking Right Wing Count',
            shortName: 'CARW',
            visible: true,
        },
        timePos__afl_centre_half_back: {
            name: 'Total Mid Centre Half Back Time',
            shortName: 'MCHB',
            visible: true,
        },
        countStintPos__afl_centre_half_back: {
            name: 'Total Centre Half Back Count',
            shortName: 'CCHB',
            visible: true,
        },
        timePos__afl_centre_half_forward: {
            name: 'Total Mid Centre Half Forward Time',
            shortName: 'MCHF',
            visible: true,
        },
        countStintPos__afl_centre_half_forward: {
            name: 'Total Centre Half Forward Count',
            shortName: 'CCHF',
            visible: true,
        },
        countStintPos__afl_defensive_left_wing: {
            name: 'Total Defensive Left Wing Count',
            shortName: 'CDLW',
            visible: true,
        },
        timePos__afl_defensive_right_wing: {
            name: 'Total Defensive Right Wing Time',
            shortName: 'DRW',
            visible: true,
        },
        countStintPos__afl_defensive_right_wing: {
            name: 'Total Defensive Right Wing Count',
            shortName: 'CDRW',
            visible: true,
        },
        timePos__afl_full_back: {
            name: 'Total Full Back Time',
            shortName: 'FB',
            visible: true,
        },
        countStintPos__afl_full_back: {
            name: 'Total Full Back Count',
            shortName: 'CFB',
            visible: true,
        },
        timePos__afl_full_forward: {
            name: 'Total Full Forward Time',
            shortName: 'FF',
            visible: true,
        },
        countStintPos__afl_full_forward: {
            name: 'Total Full Forward Count',
            shortName: 'CFF',
            visible: true,
        },
        timePos__afl_left_back_pocket: {
            name: 'Total Left Back Pocket Time',
            shortName: 'LBP',
            visible: true,
        },
        countStintPos__afl_left_back_pocket: {
            name: 'Total Left Back Pocket Count',
            shortName: 'CLBP',
            visible: true,
        },
        timePos__afl_left_forward_pocket: {
            name: 'Total Left Forward Pocket Time',
            shortName: 'LFP',
            visible: true,
        },
        countStintPos__afl_left_forward_pocket: {
            name: 'Total Left Forward Pocket Count',
            shortName: 'CLFP',
            visible: true,
        },
        timePos__afl_left_half_back: {
            name: 'Total Left Half Back Time',
            shortName: 'LHB',
            visible: true,
        },
        countStintPos__afl_left_half_back: {
            name: 'Total Left Half Back Count',
            shortName: 'CLHB',
            visible: true,
        },
        timePos__afl_left_half_forward: {
            name: 'Total Left Half Forward Time',
            shortName: 'LHF',
            visible: true,
        },
        countStintPos__afl_left_half_forward: {
            name: 'Total Left Half Forward Count',
            shortName: 'CLHF',
            visible: true,
        },
        timePos__afl_left_wing: {
            name: 'Total Left Wing Time',
            shortName: 'LW',
            visible: true,
        },
        countStintPos__afl_left_wing: {
            name: 'Total Left Wing Count',
            shortName: 'CLW',
            visible: true,
        },
        timePos__afl_mid_1: {
            name: 'Total Mid 1 Time',
            shortName: 'M1',
            visible: true,
        },
        countStintPos__afl_mid_1: {
            name: 'Total Mid 1 Count',
            shortName: 'CM1',
            visible: true,
        },
        timePos__afl_mid_2: {
            name: 'Total Mid 2 Time',
            shortName: 'M2',
            visible: true,
        },
        countStintPos__afl_mid_2: {
            name: 'Total Mid 2 Count',
            shortName: 'CM2',
            visible: true,
        },
        timePos__afl_mid_3: {
            name: 'Total Mid 3 Time',
            shortName: 'M3',
            visible: true,
        },
        countStintPos__afl_mid_3: {
            name: 'Total Mid 3 Count',
            shortName: 'CM3',
            visible: true,
        },
        countStintPos__afl_mid_centre_half_back: {
            name: 'Total Mid Centre Half Back Count',
            shortName: 'CMCHB',
            visible: true,
        },
        countStintPos__afl_mid_centre_half_forward: {
            name: 'Total Mid Centre Half Forward Count',
            shortName: 'CMCHF',
            visible: true,
        },
        timePos__afl_mid_left_half_back: {
            name: 'Total Mid Left Half Back Time',
            shortName: 'MLHB',
            visible: true,
        },
        countStintPos__afl_mid_left_half_back: {
            name: 'Total Mid Left Half Back Count',
            shortName: 'CMLHB',
            visible: true,
        },
        timePos__afl_mid_left_half_forward: {
            name: 'Total Mid Left Half Forward Time',
            shortName: 'MLHF',
            visible: true,
        },
        countStintPos__afl_mid_left_half_forward: {
            name: 'Total Mid Left Half Forward Count',
            shortName: 'CMLHF',
            visible: true,
        },
        timePos__afl_mid_right_half_back: {
            name: 'Total Mid Right Half Back Time',
            shortName: 'MRHB',
            visible: true,
        },
        countStintPos__afl_mid_right_half_back: {
            name: 'Total Mid Right Half Back Count',
            shortName: 'CMRHB',
            visible: true,
        },
        timePos__afl_mid_right_half_forward: {
            name: 'Total Mid Right Half Forward Time',
            shortName: 'MRHF',
            visible: true,
        },
        countStintPos__afl_mid_right_half_forward: {
            name: 'Total Mid Right Half Forward Count',
            shortName: 'CMRHF',
            visible: true,
        },
        timePos__afl_right_back_pocket: {
            name: 'Total Right Back Pocket Time',
            shortName: 'RBP',
            visible: true,
        },
        countStintPos__afl_right_back_pocket: {
            name: 'Total Right Back Pocket Count',
            shortName: 'CRBP',
            visible: true,
        },
        timePos__afl_right_forward_pocket: {
            name: 'Total Right Forward Pocket Time',
            shortName: 'RFP',
            visible: true,
        },
        countStintPos__afl_right_forward_pocket: {
            name: 'Total Right Forward Pocket Count',
            shortName: 'CRFP',
            visible: true,
        },
        timePos__afl_right_half_back: {
            name: 'Total Right Half Back Time',
            shortName: 'RHB',
            visible: true,
        },
        countStintPos__afl_right_half_back: {
            name: 'Total Right Half Back Count',
            shortName: 'CRHB',
            visible: true,
        },
        timePos__afl_right_half_forward: {
            name: 'Total Right Half Forward Time',
            shortName: 'RHF',
            visible: true,
        },
        countStintPos__afl_right_half_forward: {
            name: 'Total Right Half Forward Count',
            shortName: 'CRHF',
            visible: true,
        },
        timePos__afl_right_wing: {
            name: 'Total Right Wing Time',
            shortName: 'RW',
            visible: true,
        },
        countStintPos__afl_right_wing: {
            name: 'Total Right Wing Count',
            shortName: 'CRW',
            visible: true,
        },
        timePos__afl_ruck: {
            name: 'Total Ruck Time',
            shortName: 'RU',
            visible: true,
        },
        countStintPos__afl_ruck: {
            name: 'Total Ruck Count',
            shortName: 'CRU',
            visible: true,
        },
    },
};

const EventPlayers = (props: Props) => {
    const {
        playerData,
        gameStatsData,
        timeStatsData,
        eventData,
        statsConfigData,
    } = props;

    // Refs
    const dataTable = useRef<DataTable>(null);
    const targetOverlay = useRef<OverlayPanel>(null);

    // States
    const [selectedPeriod, setSelectedPeriod] = useState<number>(0);
    const [reportType, setReportType] = useState<ReportType>(ReportType.basic);
    const [selectedCategory, setSelectedCategory] =
        useState<StatCategory>('time');

    const statsConfig = statsConfigData?.data?.data;
    const event = eventData?.data?.data;
    const players = playerData?.data?.data;
    const gameSettings = event?.game?.settings;

    let timeStats = timeStatsData?.data?.value[reportType];
    let gameStats = gameStatsData?.data?.data;

    const totalPeriods =
        gameSettings && gameSettings.periods && Number(gameSettings.periods) > 0
            ? gameSettings.periods
            : timeStats &&
              Math.max(...timeStats.map((o: TimeStat) => o.period));

    const defaultAlertMode = gameSettings?.alertMode || 'None';
    const defaultGameTimeTarget = gameSettings?.gameTimeTarget;

    const [alertMode, setAlertMode] = useState(defaultAlertMode);
    const [gameTimeTarget, setGameTimeTarget] = useState(
        defaultGameTimeTarget ? defaultGameTimeTarget / 100 : 0
    );

    /*
    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();
    };
    */

    const columns: ColumnProps[] = useMemo(() => {
        if (selectedCategory === 'game') {
            const availableStats = uniqBy(gameStats, 'statID').map(
                (stat) => stat.statID
            );

            return availableStats.map((stat) => {
                const statInfo = statsConfig?.find((s) => s.statID === stat);

                return {
                    field: `stats.${stat}`,
                    header: statInfo ? statInfo.statShortName : '',
                    headerTooltip: statInfo && statInfo.statName,
                    headerTooltipOptions: {
                        position: 'top',
                        showDelay: 500,
                    },
                    sortable: true,
                    style: { width: '120px' },
                    body: (row) => row.stats[stat] || 0,
                };
            });
        } else {
            // @ts-ignore
            let cols: ColumnProps[] = [];

            if (alertMode !== 'None' && !selectedPeriod) {
                cols.push({
                    field: `stats.alertTarget`,
                    header: 'Target',
                    sortable: true,
                    align: 'center',
                    alignHeader: 'center',
                    body: (row) => {
                        const target = gameTimeTarget;
                        const thisValue =
                            row.stats.PGT ||
                            (row.stats.timeOnField &&
                                row.stats.timeOnField /
                                    (row.stats.timeOnField +
                                        row.stats.timeOffField)) ||
                            0;
                        const checked = thisValue >= target;
                        return (
                            <Icon
                                fill
                                style={{
                                    color: checked ? '#11b411' : '#fb4747',
                                }}
                                title={
                                    'PGT ' + Math.round(thisValue * 100) + '%'
                                }
                                name={checked ? 'check_circle' : 'cancel'}
                            />
                        );
                    },
                });
            }

            Object.keys(timeStatsSchema[reportType]).forEach((statID) => {
                // @ts-ignore
                const stat = timeStatsSchema[reportType][statID];

                if (!stat.visible) return;

                cols.push({
                    field: `stats.${stat.shortName}`,
                    header: stat.shortName,
                    sortable: true,
                    headerTooltip: stat && stat.name,
                    headerTooltipOptions: {
                        position: 'top',
                        showDelay: 500,
                    },
                    align: 'right',
                    alignHeader: 'right',
                    body: (row) => {
                        const val = row.stats[statID];
                        const totalTime = row.stats['PT'];

                        if (statID.includes('count')) {
                            return val;
                        }

                        if (statID === 'PGT') {
                            return isNaN(val)
                                ? '-'
                                : `${Math.round(val * 100)}%`;
                        }

                        if (val && totalTime) {
                            return Math.round((val / totalTime) * 100) + '%';
                        } else {
                            return '-';
                        }
                    },
                });
            });

            return cols;
        }
    }, [
        alertMode,
        gameStats,
        gameTimeTarget,
        reportType,
        selectedCategory,
        selectedPeriod,
        statsConfig,
    ]);

    let calculatedStats = useMemo(() => {
        const getTimeStats = () => {
            const groupedTimeStats = groupBy(timeStats, 'playerID');

            return Object.keys(groupedTimeStats).map((playerID) => {
                const playerStats = groupedTimeStats[playerID];
                const player =
                    players && players.find((p) => p.playerID === playerID);
                let output: CalculatedStat = {
                    displayName: player
                        ? player.firstName + ' ' + player.lastName
                        : `Unknown Player (${playerID})`,
                    player: player,
                    stats: {},
                };

                playerStats.forEach((ps) => {
                    Object.keys(timeStatsSchema[reportType]).forEach(
                        (statID) => {
                            if (selectedPeriod) {
                                if (ps.period === selectedPeriod) {
                                    output.stats[statID] = ps[statID];
                                }
                            } else {
                                output.stats[statID] =
                                    output.stats[statID] || 0;
                                output.stats[statID] += ps[statID];
                            }
                        }
                    );
                });

                // If we're looking at "all periods", use the _average_ of PGT across all periods
                if (!selectedPeriod && output.stats['PGT']) {
                    // @ts-ignore
                    output.stats['PGT'] = output.stats['PGT'] / totalPeriods;
                }

                return output;
            });
        };

        const getGameStats = () => {
            return gameStats?.reduce(
                (
                    acc: CalculatedStats,
                    { statID, targetID, value, period, targetType }
                ) => {
                    // Exclude opponent stats
                    if (targetID.includes('opponent')) return acc;

                    // Use player id or mark as unassigned
                    const playerID =
                        targetType === 'Player' ? targetID : 'unassigned';

                    // Set defaults to be overwritten by player info if exists
                    acc[playerID] = acc[playerID] || {
                        displayName:
                            targetType === 'Player'
                                ? 'Unknown Player'
                                : 'Unassigned',
                        player: null,
                        stats: {},
                    };

                    // Add player details
                    players?.forEach((player) => {
                        acc[player.playerID] = {
                            displayName:
                                player.firstName + ' ' + player.lastName,
                            player,
                            stats: acc[player.playerID]
                                ? acc[player.playerID].stats
                                : {},
                        };
                    });

                    // Add or set stat values
                    if (selectedPeriod) {
                        if (selectedPeriod === Number(period)) {
                            acc[playerID]['stats'][statID] =
                                Number(acc[playerID]['stats'][statID]) +
                                    Number(value) || value;
                        }
                    } else {
                        acc[playerID]['stats'][statID] =
                            Number(acc[playerID]['stats'][statID]) +
                                Number(value) || value;
                    }

                    return acc;
                },
                {}
            );
        };

        return {
            game: getGameStats(),
            time: getTimeStats(),
        }[selectedCategory];
    }, [
        selectedCategory,
        gameStats,
        timeStats,
        players,
        reportType,
        selectedPeriod,
        totalPeriods,
    ]);

    const renderPlayerInfo = (row: CalculatedStat, i: ColumnBodyOptions) => {
        return (
            <div className="detail-cell">
                <Avatar
                    label={
                        row.player
                            ? getFirstChars(row.displayName, 2).toUpperCase()
                            : '?'
                    }
                    image={row.player && row.player.playerImageURL}
                    style={
                        row.player
                            ? { background: '#4A4A4D', color: '#ffffff' }
                            : {}
                    }
                    shape="circle"
                />
                <span>{row.displayName}</span>

                {row.stats.INJ && row.stats.INJ !== '0%' && (
                    <Button
                        style={{
                            width: '24px',
                            height: '24px',
                            color: '#999',
                        }}
                        tooltip={`${row.stats.INJ} time spent in injury.`}
                        tooltipOptions={{
                            event: 'focus',
                            style: { maxWidth: '200px' },
                        }}
                        className="p-button-icon-only p-button-secondary p-button-text"
                    >
                        <ButtonIcon size="small" iconName="personal_injury" />
                    </Button>
                )}
            </div>
        );
    };

    const renderGroup = (row: CalculatedStat) => {
        return (
            <Avatar
                style={{
                    background: row.player ? row.player.colour : 'transparent',
                    width: '24px',
                    height: '16px',
                    border: '1px solid #e5e5e5',
                }}
            />
        );
    };

    if (timeStatsData.error && gameStatsData.error) {
        return (
            <ErrorDisplay
                title="No data was returned"
                desc="We couldn't fulfil the request for you, try again or contact account manager."
                errorType={ERROR_TYPES.notFound}
            />
        );
    }

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    // empty array to populate rows for skeleton loading components
    const blankRows = Array.from({ length: 5 });
    const isLoading =
        (selectedCategory === 'game' && gameStatsData.isLoading) ||
        (selectedCategory === 'time' && timeStatsData.isLoading);
    const isFetching =
        (selectedCategory === 'game' && gameStatsData.isFetching) ||
        (selectedCategory === 'time' && timeStatsData.isFetching);

    const tableHeader = (
        <Toolbar
            left={
                <>
                    <div className="p-buttonset">
                        <ToggleButton
                            onChange={() => setSelectedCategory('time')}
                            checked={selectedCategory === 'time'}
                            onLabel="Time"
                            offLabel="Time"
                        />
                        <ToggleButton
                            onChange={() => setSelectedCategory('game')}
                            checked={selectedCategory === 'game'}
                            onLabel="Game"
                            offLabel="Game"
                        />
                    </div>

                    <div className="p-buttonset">
                        <ToggleButton
                            checked={!selectedPeriod}
                            onChange={() => setSelectedPeriod(0)}
                            onLabel="All"
                            offLabel="All"
                        />

                        {[...Array(totalPeriods)].map((u, index) => {
                            const period = index + 1;
                            const label = `${period}${periodSuffix(
                                Number(period)
                            )}`;
                            return (
                                <ToggleButton
                                    key={label}
                                    checked={selectedPeriod === period}
                                    onChange={() => setSelectedPeriod(period)}
                                    onLabel={label}
                                    offLabel={label}
                                />
                            );
                        })}
                    </div>
                </>
            }
            right={
                <>
                    {isLoading && (
                        <div className="datatable-loader">
                            <Loader accented weight="heavy" />
                            <div>
                                Loading report, please wait as we crunch some
                                numbers...
                            </div>
                        </div>
                    )}
                    <Dropdown
                        value={reportType}
                        onChange={(e) => setReportType(e.value)}
                        options={Object.keys(ReportType).map((label) => {
                            const value =
                                ReportType[label as keyof typeof ReportType];
                            return {
                                label: startCase(label),
                                value,
                            };
                        })}
                    />

                    {selectedCategory === 'time' && (
                        <Button
                            className="p-button-secondary"
                            type="button"
                            onClick={(e) =>
                                targetOverlay.current &&
                                targetOverlay.current.toggle(e)
                            }
                        >
                            <ButtonIcon iconName="track_changes" />
                            {alertMode === 'None' ? (
                                'No Target'
                            ) : (
                                <span>
                                    Target:{' '}
                                    <strong>
                                        {Math.round(gameTimeTarget * 100)}%
                                    </strong>
                                </span>
                            )}
                        </Button>
                    )}
                    {/*
                    <Button
                        type="button"
                        onClick={() => exportCSV()}
                        label="Export CSV"
                        className="p-button-secondary"
                    />
                    */}
                </>
            }
        />
    );

    const sortedStats =
        calculatedStats &&
        sortBy(
            Object.values(calculatedStats),
            (s: CalculatedStat) => s.player && Number(s.player.uniformNumber)
        );

    const getErrorTitle = (reason: ErrorType['reason']) => {
        switch (reason) {
            case 'NO_GAMEID':
                return 'No game found';
            case 'NO_DATA':
                return 'No data found';
            case 'INCOMPLETE_DATA':
                return 'Incomplete data';
            default:
                return 'Error';
        }
    };

    const renderEmptyMessage = (error: ErrorType) => (
        <ErrorDisplay
            alignment="middle"
            desc={
                error && error.message
                    ? error.message
                    : 'An error has occurred.'
            }
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={getErrorTitle(error && error.reason)}
        />
    );

    return (
        <>
            <DataTable
                ref={dataTable}
                responsiveLayout="scroll"
                resizableColumns
                columnResizeMode="fit"
                header={tableHeader}
                value={isLoading ? blankRows : sortedStats}
                loading={isFetching && !isLoading}
                footer={sortedStats && tableFooter}
                emptyMessage={
                    timeStatsData.isError && timeStatsData.error
                        ? // @ts-expect-error
                          renderEmptyMessage(timeStatsData.error.data)
                        : 'No data found'
                }
            >
                <Column
                    field="player.uniformNumber"
                    header="#"
                    sortable
                    style={{ width: '40px' }}
                    body={(row) =>
                        isLoading ? (
                            <Skeleton />
                        ) : row.player ? (
                            row.player.uniformNumber
                        ) : (
                            '-'
                        )
                    }
                />
                <Column
                    field="displayName"
                    header="Player"
                    sortable
                    body={isLoading ? <Skeleton /> : renderPlayerInfo}
                    style={{ width: '300px' }}
                />
                <Column
                    field="player.colour"
                    header="Group"
                    sortable
                    body={isLoading ? <Skeleton /> : renderGroup}
                    style={{ width: '100px' }}
                    align="center"
                    alignHeader="center"
                />
                {!isLoading &&
                    columns.map((col) => <Column key={col.field} {...col} />)}
            </DataTable>

            <OverlayPanel ref={targetOverlay} dismissable>
                <div style={{ width: '400px' }}>
                    <h4 style={{ marginBottom: '20px' }}>Set Target</h4>
                    <SelectButton
                        style={{ marginBottom: '20px' }}
                        value={alertMode}
                        options={[
                            { label: 'None', value: 'None' },
                            {
                                label: 'Minimum',
                                value: 'MinimumGameTime',
                            },
                        ]}
                        onChange={(e) => setAlertMode(e.value)}
                    />
                    {alertMode !== 'None' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <label style={{ flex: 'none', margin: '0 8px' }}>
                                20%
                            </label>
                            <Slider
                                style={{ flex: '1' }}
                                value={gameTimeTarget * 100}
                                onChange={(e) =>
                                    setGameTimeTarget(Number(e.value) / 100)
                                }
                                min={20}
                            />
                            <label style={{ flex: 'none', margin: '0 8px' }}>
                                100%
                            </label>
                        </div>
                    )}
                </div>
            </OverlayPanel>
        </>
    );
};

export default EventPlayers;
