import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    useDeleteTeamStaffMutation,
    useGetTeamStaffDetailsQuery,
    useLazyGetTeamStaffDetailsQuery,
} from '../../api/teams';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { UserDetails } from '../../types/user';

import {
    useDeleteOrganisationStaffMutation,
    useGetOrganisationStaffDetailsQuery,
    useLazyGetOrganisationStaffDetailsQuery,
} from '../../api/organisations';

import StaffActiveView from './StaffActiveView';

interface Props {
    roles: Roles;
    route: Route;
}

const StaffActiveContainer = (props: Props) => {
    const { teamID, organisationID } = useParams();
    const [, setSearchParams] = useSearchParams();

    const [focusedUser, setFocusedUser] = useState<UserDetails | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const useGetStaffQuery = organisationID
        ? useGetOrganisationStaffDetailsQuery
        : useGetTeamStaffDetailsQuery;

    const useLazyGetStaffQuery = organisationID
        ? useLazyGetOrganisationStaffDetailsQuery
        : useLazyGetTeamStaffDetailsQuery;

    const useDeleteStaffMutation = organisationID
        ? useDeleteOrganisationStaffMutation
        : useDeleteTeamStaffMutation;

    const [refetch] = useLazyGetStaffQuery();

    const { data, isLoading, isError, isFetching } = useGetStaffQuery(
        {
            // @ts-expect-error teamID may not exist in the url
            teamID,
            // @ts-expect-error teamID may not exist in the url
            organisationID,
        },
        {
            skip: !teamID && !organisationID,
        }
    );

    const [deleteUser] = useDeleteStaffMutation();

    const handleShowDelete = () => {
        setShowDeleteDialog(true);
    };

    const handleCloseDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteUser = (userID: string) => {
        if (focusedUser?.roles.some(role => role.roleName === 'Teams Owner' || 'Organisations Owner')) {
            //Cannot delete owner
            return ;
        } else {
            deleteUser({ userID, teamID: teamID ? teamID : '' })
                .then((response) => {
                    console.log('DELETE SUCCESS', response);
                })
                .catch((err) => {
                    console.log('DELETE ERROR', err);
                })
                .finally(() => {
                    setShowDeleteDialog(false);
                });
        }
    
    };

    const handleClickUser = (userID: string) => {
        setSearchParams((params) => {
            params.set('id', userID);
            return params;
        });
    };

    const handleViewUser = (userID: string) => {
        console.log('VIEW', userID);
    };

    const handleFocusUser = (user: UserDetails) => {
        setFocusedUser(user);
    };

    const handleLoadMore = () => {
        if (
            teamID &&
            data &&
            data.lastEvaluatedKey &&
            data.lastEvaluatedKey.cursor
        ) {
            refetch({
                teamID: teamID ? teamID : '',
                organisationID: organisationID ? organisationID : '',
                cursor: data.lastEvaluatedKey.cursor,
            });
        }
    };

    return (
        <StaffActiveView
            data={data ? data.data : []}
            isError={isError}
            isFetching={isFetching}
            isLoading={isLoading}
            showDeleteDialog={showDeleteDialog}
            focusedUser={focusedUser}
            onCloseDeleteDialog={handleCloseDelete}
            onClickUser={handleClickUser}
            onDeleteUser={handleDeleteUser}
            onViewUser={handleViewUser}
            onFocusUser={handleFocusUser}
            onShowDeleteDialog={handleShowDelete}
            roles={props.roles}
            onLoadMore={handleLoadMore}
            showPagination={!!data?.lastEvaluatedKey?.cursor}
        />
    );
};

export default StaffActiveContainer;
