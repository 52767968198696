//@ts-nocheck
import { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { upperFirst, isEmpty, groupBy } from 'lodash';
import { entityMap } from '../routes/routes';
import { getActiveEntity } from '../util/helper';

import ButtonIcon from './ButtonIcon';
import ErrorDisplay from './ErrorDisplay';
import ListItem from './ListItem';
import Icon from './Icon';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';

import { Role, Roles } from '../types/roles';
import { ERROR_TYPES, SEVERITY } from '../types/common.d';
import { useGetMeQuery, useGetRolesQuery } from '../api/roles';
import EntityAvatar from './EntityAvatar';
import { Me } from '../types/user';
import { EntityType } from '../../../services/utils/enums';

const ENTITY_LIMIT = 3;

interface Props {
    onEntityClick?(role: Role): any;
}

const defaultUser: Me = {
    admin: false,
    email: '',
    emailVerified: false,
    expiry: '',
    firstName: '',
    lastName: '',
    loginSource: '',
    picture: '',
    roles: [],
    userID: '',
};

const EntitySwitcher = ({ onEntityClick }: Props) => {
    // Route Helpers
    const { pathname } = useLocation();
    const navigate = useNavigate();

    // API
    const { data: dataUser } = useGetMeQuery();
    const user =
        dataUser && dataUser.data ? (dataUser.data as Me) : defaultUser;

    const { data: dataRoles } = useGetRolesQuery(
        {
            userID: user && user.userID,
        },
        { skip: !user || !user.userID }
    );

    const roles = dataRoles && dataRoles.data ? dataRoles.data : [];

    // Ref Hooks
    const entityPopoverRef = useRef<OverlayPanel>(null);

    // State Hooks
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    // Active Entity
    const active = getActiveEntity(roles, pathname);

    /**
     * @desc remap roles into a format prime menuModel recognises, pass in custom template for items.
     * @returns JSX
     */

    const grouped = groupBy(roles, 'entityType') as GroupedRoles;

    let menuItems: MenuItem[] = [];

    type GroupedRoles = Record<keyof Roles, Roles[keyof Roles][]>;

    Object.keys(grouped).forEach((key) => {
        if (grouped[key as keyof Roles].length > 0) {
            const allItems = grouped[key as keyof Roles] as Role[];

            let items = allItems.slice(0, ENTITY_LIMIT).map((role) => {
                const id = role.entityID;
                const title = role.entityPreview.entityName;
                const caption = upperFirst(role.roles[0].roleName);
                const entityRoute = entityMap[key].urlSegment;

                return {
                    label: upperFirst(title),
                    template: () => (
                        <Link
                            className="p-menuitem-link"
                            to={`/${entityRoute}/${id}`}
                            onClick={(e) => {
                                onEntityClick && onEntityClick(role);
                                entityPopoverRef.current &&
                                    entityPopoverRef.current.toggle(e);
                            }}
                        >
                            <ListItem
                                proportion="compact"
                                startElement={
                                    <EntityAvatar
                                        entityID={id}
                                        entityName={title}
                                        entityType={key as EntityType}
                                    />
                                }
                                title={upperFirst(title)}
                                caption={upperFirst(caption)}
                            />
                            {active && id === active.entityID ? (
                                <Icon name="check_circle" />
                            ) : (
                                <Icon name="login" className="is-transparent" />
                            )}
                        </Link>
                    ),
                };
            });

            if (allItems.length > ENTITY_LIMIT) {
                items.push({
                    label: `View all ${key}`,
                    template: () => (
                        <Link
                            className="p-menuitem-link entity-view-action"
                            to={`/u/${user.userID}/entities/${key}`}
                            onClick={(e) => {
                                entityPopoverRef.current &&
                                    entityPopoverRef.current.toggle(e);
                            }}
                        >
                            <Button
                                label={`View all ${key}`}
                                className="p-button-sm p-button-outlined p-button-secondary"
                            />
                        </Link>
                    ),
                });
            }

            menuItems.push({
                label: upperFirst(key),
                items: items,
            });
        }
    });

    // entity switchers active item rendered within ListItem
    const entitySwitcherListItem = active ? (
        <ListItem
            proportion="compact"
            startElement={
                <EntityAvatar
                    entityID={active.entityID}
                    entityName={active.entityPreview.entityName}
                    entityType={active.entityType}
                />
            }
            title={active.entityPreview.entityName}
            caption={active.roles ? active.roles[0].roleName : ''}
        />
    ) : (
        <ListItem proportion="compact" title="Select Entity..." />
    );

    // visually entity switcher state indicator
    const entitySwitcherListItemIndicator = !isMenuVisible ? (
        <Icon name="unfold_more" />
    ) : (
        <Icon name="unfold_less" />
    );

    const handleCreationRedirection = (entityType: string) => {
        navigate(`/u/${user.userID}/entities/${entityType}?form=create`);
        if (entityPopoverRef.current) {
            entityPopoverRef.current.toggle(null);
        }
    };

    const entitySwitcherFooter = (
        <div className="entity-popover_footer p-buttonset set-is-stacked">
            <Button
                onClick={() => handleCreationRedirection('organisations')}
                className={`p-button-sm p-button-${SEVERITY.secondary}`}
            >
                <ButtonIcon iconName="add_home" placement="left" />
                <span className="p-button-label">Create Organisation</span>
            </Button>
            <Button
                onClick={() => handleCreationRedirection('teams')}
                className={`p-button-sm p-button-${SEVERITY.secondary}`}
            >
                <ButtonIcon iconName="add_moderator" placement="left" />
                <span className="p-button-label">Create Team</span>
            </Button>
        </div>
    );

    return (
        <>
            <div
                className="entity-switcher"
                onClick={(e) =>
                    entityPopoverRef.current &&
                    entityPopoverRef.current.toggle(e)
                }
                aria-controls="entity-popover"
                aria-haspopup
            >
                {entitySwitcherListItem}
                {entitySwitcherListItemIndicator}
            </div>
            <OverlayPanel
                id="entity-popover"
                className="entity-popover"
                ref={entityPopoverRef}
                onShow={() => setIsMenuVisible(true)}
                onHide={() => setIsMenuVisible(false)}
            >
                {isEmpty(roles) ? (
                    <ErrorDisplay
                        desc="Select an option below."
                        errorType={ERROR_TYPES.fileError}
                        hasReturn={false}
                        proportion="compact"
                        title="No Roles Found"
                    />
                ) : (
                    <Menu id="entity-menu" model={menuItems} />
                )}
                {entitySwitcherFooter}
            </OverlayPanel>
        </>
    );
};

export default EntitySwitcher;
