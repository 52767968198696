// @ts-nocheck

const useGooglePlaceAutoComplete = () => {
    const initAutoComplete = async (input, callback) => {
        let autoComplete = new window.google.maps.places.Autocomplete(input, {
            // limit to North America for now
            componentRestrictions: { country: ['au'] },
            fields: [
                'formatted_address',
                'address_component',
                'geometry',
                'place_id',
                'name',
            ],
            types: ['address'],
        });
        autoComplete.addListener('place_changed', callback);

        return autoComplete;
    };

    const getFullAddress = async (autoComplete) => {
        const place = autoComplete.getPlace();

        let address,
            locality,
            adminArea1Short,
            adminArea1Long,
            countryShort,
            countryLong,
            postalCode = '',
            streetNumberShort,
            streetNumberLong,
            routeShort,
            routeLong,
            placeID = place && place.place_id,
            formattedAddress = place && place.formatted_address,
            latitude = place && place.geometry.location.lat(),
            longitude = place && place.geometry.location.lng(),
            name = place && place.name;

        // Get each component of the address from the place details,
        for (const component of place.address_components) {
            const componentType = component.types[0];

            if (componentType === 'street_number') {
                address = component.long_name;
            }
            if (componentType === 'route') {
                address = `${address} ${component.long_name}`;
            }
            if (componentType === 'locality') {
                locality = component.long_name;
            }
            if (componentType === 'administrative_area_level_1') {
                adminArea1Short = component.short_name;
                adminArea1Long = component.long_name;
            }
            if (componentType === 'postal_code') {
                postalCode = component.long_name;
            }
            if (componentType === 'postal_code_suffix') {
                postalCode = `${postalCode}-${component.long_name}`;
            }
            if (componentType === 'country') {
                countryShort = component.short_name;
                countryLong = component.long_name;
            }

            if (componentType === 'street_number') {
                streetNumberShort = component.short_name;
                streetNumberLong = component.long_name;
            }

            if (componentType === 'route') {
                routeShort = component.short_name;
                routeLong = component.long_name;
            }
        }

        let resAddress = {
            address,
            locality,
            adminArea1Short,
            adminArea1Long,
            postalCode,
            countryShort,
            countryLong,
            placeID,
            formattedAddress,
            latitude,
            longitude,
            name,
            streetNumberShort,
            streetNumberLong,
            routeShort,
            routeLong,
        };

        return resAddress;
    };

    return {
        initAutoComplete,
        getFullAddress,
    };
};

export default useGooglePlaceAutoComplete;
