import React from 'react';
import AccountForm from '../forms/AccountForm';
import ListItem from '../components/ListItem';
import DocumentHead from '../components/DocumentHead';

interface DashboardProps {
    user: any;
    isAuthenticated: boolean;
    logout: () => void;
    login: () => void;
}

const Dashboard: React.FC<DashboardProps> = (props) => {
    const { user, isAuthenticated, logout, login } = props;

    const dashboardActions = [
        {
            icon: 'logout',
            path: '/logout',
            text: "logout"
        },
    ]

    return (
        <>
            <DocumentHead 
                title="Dashboard - Rookie Me Hub"
                description="User dashboard page"
            />
            <div className="auth0-app auth0-lock auth0-lock">
                <ListItem
                    title="Rookie Me - Hub"
                    caption="Bang"
                    avatar={{
                        image: 'https://cdn.home.rookieme.com/login/images/logo.png',
                        label: 'Rookie Me - Hub',
                    }}
                    proportion="compact"
                    actions={dashboardActions}
                    carded
                />
                <div className="auth0-wrapper">
                    <div className="auth0-widget">
                        <div className="auth0-content-wrapper">
                            <div className="auth0-body" style={{paddingTop: "1rem"}}>
                                {user && <AccountForm {...props} />}
                                <div className="auth0-social-buttons-container">
                                    <button
                                        className="auth0-social-button"
                                        type="button"
                                        onClick={isAuthenticated ? logout : login}
                                    >
                                        <div className="auth0-social-button-text">
                                            {isAuthenticated ? 'Log Out' : 'Log In'}
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
