import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import {
    DataTable,
    DataTableFooterTemplateOptions,
} from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Skeleton } from 'primereact/skeleton';
import { Tag, TagSeverityType } from 'primereact/tag';
import { Toast } from 'primereact/toast';

import ButtonIcon from '../../components/ButtonIcon';
import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import ListItem from '../../components/ListItem';
import Modal from '../../components/Modal';

import { UserDetails } from '../../types/user';
import { Roles } from '../../types/roles';
import { ERROR_TYPES, SEVERITY } from '../../types/common.d';

interface Props {
    data: UserDetails[];
    focusedUser: UserDetails | null;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onClickUser: (userID: string) => void;
    onCloseDeleteDialog: () => void;
    onDeleteUser: (userID: string) => void;
    onFocusUser: (user: UserDetails) => void;
    onLoadMore?: () => void;
    onShowDeleteDialog: () => void;
    onViewUser: (userID: string) => void;
    roles: Roles;
    showDeleteDialog: boolean;
    showPagination: boolean;
}

const StaffActiveView = (props: Props) => {
    const { focusedUser, isError, isFetching, isLoading } = props;

    const rowMenu = useRef<Menu>(null);
    const rowContextMenu = useRef<ContextMenu>(null);
    const staffFormToast = useRef<Toast>(null);

    const navigate = useNavigate();

    const focusedUserID = focusedUser?.userID;

    const titleTemplate = (row: UserDetails) => {
        return (
            <ListItem
                className="pointer-events-none"
                title={row.userDetails.name}
                avatar={{
                    image: row.userDetails.picture,
                    label: row.userDetails.name,
                }}
                proportion="compact"
            />
        );
    };

    const actionTemplate = (row: UserDetails) => {
        return (
            <div className="p-buttonset">
                <Button
                    className="p-button p-button-secondary p-button-text td-action"
                    aria-label="More"
                    onClick={(e) => {
                        if (rowMenu.current) {
                            rowMenu.current.toggle(e);
                        }
                        props.onFocusUser(row);
                    }}
                >
                    <ButtonIcon
                        iconName="more_horiz"
                        placement="center"
                        size="small"
                    />
                </Button>
            </div>
        );
    };

    const statusTemplate = (row: UserDetails) => {
        const teamStatus = {
            Active: 'Active',
            Archived: 'Archived',
            Deleted: 'Deleted',
        };

        const severity = (teamStatus as { [index: string]: TagSeverityType })[
            row.entityStatus
        ];

        return <Tag severity={severity}>{row.entityStatus}</Tag>;
    };

    const columnSchema: ColumnProps[] = [
        {
            body: isLoading ? <Skeleton /> : titleTemplate,
            field: 'given_name',
            header: 'Name',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row: UserDetails) => row.roles[0].roleName
            ),
            field: 'role',
            header: 'Role',
        },
        {
            body: isLoading ? <Skeleton /> : statusTemplate,
            field: 'teamStatus',
            header: 'Status',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row) =>
                    format(new Date(row.userDetails.last_login), 'dd/MM/yyyy')
            ),
            field: 'last_login',
            header: 'Last Login',
        },
        {
            body: isLoading ? <Skeleton /> : actionTemplate,
            className: 'actions-td',
            field: 'action',
            header: '',
        },
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    // empty array to populate rows for skeleton loading components
    const blankRows = Array.from({ length: 5 });

    const tdMenuItems = [
        {
            label: 'Edit Staff',
            icon: <Icon name="edit" className="p-menuitem-icon" />,
            disabled: true,
        },
        // this item will only be included if the user is not a 'Teams Owner'
        ...(focusedUser?.roles.some(
            (role) =>
                role.roleName !== 'Teams Owner' &&
                role.roleName !== 'Organisations Owner'
        )
            ? [
                  {
                      label: 'Delete Staff',
                      icon: <Icon name="delete" className="p-menuitem-icon" />,
                      command: () => props.onShowDeleteDialog(),
                  },
              ]
            : []),
    ];

    const deleteModalContent = props.focusedUser && (
        <>
            <p className="delete-msg">
                Are you sure you want to remove this staff member?
            </p>
            <div className="list">
                <ListItem
                    avatar={{
                        label: props.focusedUser.userDetails.name,
                        image: props.focusedUser.userDetails.picture,
                    }}
                    caption={props.focusedUser.roles[0].roleName}
                    title={props.focusedUser.userDetails.name}
                />
            </div>
            <div className="form-actions">
                <Button
                    className="p-button-text p-button-sm p-button-secondary"
                    onClick={() => props.onCloseDeleteDialog()}
                    type="button"
                >
                    <ButtonIcon iconName="cancel" placement="left" />
                    <span className="p-button-label">Cancel</span>
                </Button>
                <Button
                    className={`p-button-${SEVERITY.danger}`}
                    type="submit"
                    onClick={() =>
                        focusedUserID && props.onDeleteUser(focusedUserID)
                    }
                >
                    <ButtonIcon iconName="delete" placement="left" />
                    <span className="p-button-label">Remove Staff</span>
                </Button>
            </div>
        </>
    );

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              command: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                              severity: SEVERITY.primary,
                          },
                      ]
                    : []
            }
            alignment="middle"
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No staff found`}
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : "To get started select 'Add Staff' in the top right area."
            }
        />
    );

    const footerTemplate = (data: DataTableFooterTemplateOptions) => {
        return (
            <Button
                onClick={props.onLoadMore}
                className={`p-button-${SEVERITY.secondary} p-button-sm`}
                label="Load more"
            >
                <ButtonIcon iconName="pending" placement="right" />
            </Button>
        );
    };

    return (
        <>
            <DataTable
                value={isLoading ? blankRows : props.data}
                loading={isFetching && !isLoading}
                footer={
                    !isFetching && props.showPagination ? footerTemplate : null
                }
                emptyMessage={tableEmptyMessage}
                responsiveLayout="scroll"
                selectionMode="single"
                onSelectionChange={(e) => {
                    props.onClickUser(e.value.userID);
                    props.onFocusUser(e.value);
                }}
                onContextMenuSelectionChange={(e) => props.onFocusUser(e.value)}
                onContextMenu={(e) => {
                    if (rowContextMenu.current) {
                        rowContextMenu.current.show(e.originalEvent);
                    }
                    if (rowMenu.current) {
                        rowMenu.current.hide(e.originalEvent);
                    }
                }}
            >
                {isLoading ? columns : props.data.length > 0 ? columns : null}
            </DataTable>

            <Modal
                className="member-form-modal delete-modal"
                //@ts-ignore
                content={deleteModalContent}
                hasFooter={false}
                maximizable={false}
                onHide={props.onCloseDeleteDialog}
                title="Delete Staff"
                visible={props.showDeleteDialog}
            />

            <Menu model={tdMenuItems} popup ref={rowMenu} />
            <ContextMenu model={tdMenuItems} ref={rowContextMenu} />
            <Toast ref={staffFormToast} />
        </>
    );
};

export default StaffActiveView;
