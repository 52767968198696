// @ts-nocheck
import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes } from 'react-router-dom';
import { useGetMeQuery, useGetRolesQuery } from './api/roles';
import { sec } from './util/security';
import { routes } from './routes/routes';
import './App.scss';

import AuthGatekeeper from './routes/AuthGatekeeper';
import PageWrapper from './components/page/PageWrapper';
import ErrorDisplay from './components/ErrorDisplay';
import ProtectedRoute from './routes/ProtectedRoute';

import { Route as RouteType } from './types/route';
import { ERROR_TYPES } from './types/common.d';

function App() {
    // Auth0 Handling
    const {
        isLoading: loadingAuth0,
        getAccessTokenSilently,
        isAuthenticated,
    } = useAuth0();

    // API Handling
    const {
        data: dataUser,
        error: errorUser,
        isLoading: loadingUser,
    } = useGetMeQuery();

    const {
        data: dataRoles,
        error: errorRoles,
        isLoading: loadingRoles,
        refetch: refreshRoles,
    } = useGetRolesQuery(
        {
            userID: dataUser && dataUser.data && dataUser.data.userID,
        },
        { skip: !dataUser || !dataUser.data || !dataUser.data.userID }
    );

    const roles = dataRoles && dataRoles.data ? dataRoles.data : {};

    // State Handling
    const [isLoading, setIsLoading] = useState(false);

    // Helpers
    const doIsLoading = () => setIsLoading(true);
    const undoIsLoading = () => setIsLoading(false);

    // Set getAccessTokenSilently function to access from outside a react component
    sec.setAccessTokenSilently(getAccessTokenSilently);

    if (loadingAuth0) {
        return <div>Auth is Loading...</div>;
    }

    if (!isAuthenticated) {
        return <AuthGatekeeper />;
    }

    if (isLoading || loadingUser || loadingRoles) {
        return <div>Loading...</div>;
    }

    if (errorUser || errorRoles) {
        return <div>Error...</div>;
    }

    const renderRoute = (route: RouteType): React.ReactNode => {
        const { component: Element, children } = route;

        return (
            <Route
                key={route.id}
                path={route.path}
                element={
                    <ProtectedRoute roles={roles} route={route}>
                        <Element
                            route={route}
                            roles={roles}
                            user={dataUser && dataUser.data && dataUser.data}
                            refreshRoles={refreshRoles}
                            doIsLoading={doIsLoading}
                            undoIsLoading={undoIsLoading}
                        />
                    </ProtectedRoute>
                }
            >
                {children &&
                    children.length > 0 &&
                    children.map((childRoute) => renderRoute(childRoute))}
            </Route>
        );
    };

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PageWrapper
                        user={dataUser && dataUser.data && dataUser.data}
                        roles={roles}
                    />
                }
            >
                {routes.map((route) => renderRoute(route))}
            </Route>
            <Route
                path="*"
                element={
                    <ErrorDisplay
                        proportion="fullscreen"
                        errorType={ERROR_TYPES.maintenance}
                        title="Page not found"
                        desc="Sorry we were unable to find that page."
                    />
                }
            />
        </Routes>
    );
}

export default App;
