import { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { isEmpty, omitBy } from 'lodash';

import {
    useDeleteTeamEventMutation,
    useLazyGetTeamEventsQuery,
} from '../../api/teams';

import { getRouteByID } from '../../routes/routes';
import { toISOStringWithTimezone } from '../../util/helper';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Icon from '../../components/Icon';
import EventsView from './EventsView';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { Event } from '../../types/event';

interface Props {
    roles: Roles;
    route: Route;
}

interface EventParams {
    cursor: string;
    status: string;
}

const TeamEventsContainer = (props: Props) => {
    const { teamID, tab } = useParams();
    const navigate = useNavigate();

    // State Hooks
    const [showEventDialog, setShowEventDialog] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [filters, setFilters] = useState<EventParams>({
        cursor: '',
        status: '',
    });

    // API Hooks
    const [fetch, result] = useLazyGetTeamEventsQuery();
    const [deleteEventMutation] = useDeleteTeamEventMutation();

    const { data, isError, isFetching, isLoading } = result;

    useEffect(() => {
        const allFilters = {
            ...filters,
            to: tab === 'past' ? toISOStringWithTimezone(new Date()) : '',
        };
        const cleanedFilters = omitBy(allFilters, isEmpty);
        const searchParams = new URLSearchParams(cleanedFilters).toString();

        fetch({
            teamID,
            searchParams,
        });
    }, [teamID, filters, fetch, tab]);

    const deleteEvent = (eventID: string) => {
        deleteEventMutation({ eventID, teamID })
            .then((response) => {
                console.log('DELETE EVENT SUCCESS', response);
            })
            .catch((err) => {
                console.warn('DELETE EVENT ERROR', err);
            });
    };

    const handleSelectEvent = (value: Event | null) => {
        setSelectedEvent(value);
    };

    const handleStatusFilter = (status: string) => {
        setFilters({
            ...filters,
            status: status && filters.status === status ? '' : status,
        });
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey && teamID) {
            const { lastEvaluatedKey } = data;
            if (lastEvaluatedKey && lastEvaluatedKey.cursor) {
                setFilters({
                    ...filters,
                    cursor: lastEvaluatedKey,
                });
            }
        }
    };

    const handleDeleteEvent = (event: Event) => {
        confirmDialog({
            message: `Are you sure you want to delete ${event.eventName}?`,
            header: 'Delete Event',
            icon: <Icon name="dangerous" />,
            accept: () => deleteEvent(event.rootID),
        });
    };

    const handleEditEvent = (event: Event) => {
        setSelectedEvent(event);
        setShowEventDialog(true);
    };

    const handleCreateEvent = () => {
        setSelectedEvent(null);
        setShowEventDialog(true);
    };

    const handleClickEvent = (event: Event) => {
        const url = getRouteByID('team-event');

        if (url) {
            navigate(generatePath(url.path, { teamID, eventID: event.rootID }));
        }
    };

    const handleCloseEventDialog = () => {
        setShowEventDialog(false);
    };

    useEffect(() => {
        // Handle redirection to "upcoming" for specific URLs with /events
        if (tab !== 'upcoming' && tab !== 'past') {
            const teamIDParam = teamID || '';
            navigate(
                generatePath('/t/:teamID/events/past', { teamID: teamIDParam }),
                { replace: true }
            );
        }
    }, [navigate, teamID, tab]);

    return (
        <>
            <EventsView
                data={data ? data.data : []}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                onClickEvent={handleClickEvent}
                onCloseEventDialog={handleCloseEventDialog}
                onCreateEvent={handleCreateEvent}
                onDeleteEvent={handleDeleteEvent}
                onEditEvent={handleEditEvent}
                onLoadMore={handleLoadMore}
                onSelectEvent={handleSelectEvent}
                onStatusFilter={handleStatusFilter}
                roles={props.roles}
                route={props.route}
                selectedEvent={selectedEvent}
                showEventDialog={showEventDialog}
                showPagination={!!data?.lastEvaluatedKey?.cursor}
                status={filters.status}
            />
            <ConfirmDialog />
        </>
    );
};

export default TeamEventsContainer;
